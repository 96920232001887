import React from "react";
import PropTypes from "prop-types";

import styles from "./status.module.css";
import Card from "../../components/card";


class Temperature extends React.Component {
    render() {
        const { value } = this.props;

        return (
            <Card header="Temperature" width="wide">
                {value}
            </Card>
        );
    }
}

Temperature.defaultProps = {
};

Temperature.propTypes = {
    value: PropTypes.any.isRequired,
};


export default Temperature;
