import React from "react";
import PropTypes from "prop-types";

import { combineClasses } from "utils";
import { Actions } from "actions";

import { CommandHandler } from "commands";
import styles from "./window.module.css";


class Window extends React.Component {
    constructor(props) {
        super(props);

        this.init = false;
        this.state = {
            initialPos: { x: undefined, y: undefined },
            deltaPos: { x: 0, y: 0 },
        };

        this.window = React.createRef();
        this.root = React.createRef();

        this.close = this.close.bind(this);

        this.onGrab = this.onGrab.bind(this);
        this.onMove = this.onMove.bind(this);
        this.onRelease = this.onRelease.bind(this);
    }

    componentDidMount() {
        document.addEventListener("mouseup", this.onRelease);
        document.addEventListener("mousemove", this.onMove);
    }

    componentWillUnmount() {
        document.removeEventListener("mouseup", this.onRelease);
        document.removeEventListener("mousemove", this.onMove);
    }

    render() {
        const {
            title, content, visible, blocking, header,
            contentStyle, children,
        } = this.props;
        const { initialPos, deltaPos } = this.state;

        if (initialPos.x === undefined && this.root.current && this.window.current) {
            const rootBounds = this.root.current.getBoundingClientRect();
            this.setState({
                initialPos: {
                    x: (this.root.current.offsetWidth - this.window.current.offsetWidth) / 2,
                    y: (this.root.current.offsetHeight - this.window.current.offsetHeight) / 2,
                },
            });
        }

        if (blocking && this.visible !== visible) {
            this.visible = visible;
            console.log("visible", visible)
            if (visible) {
                CommandHandler.disableCommandInput();
            } else {
                CommandHandler.enableCommandInput();
            }
        }

        return (
            <div style={{ visibility: visible ? "visible" : "hidden" }} className={combineClasses(styles.windowRoot, blocking && styles.overlay)} ref={this.root}>
                <div
                    className={combineClasses(styles.window)}
                    ref={this.window}
                    style={{ left: initialPos.x + deltaPos.x, top: initialPos.y + deltaPos.y }}
                >
                    {header && (
                        <div className={combineClasses(styles.header)}>
                            {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
                            <h3 className={combineClasses(styles.title)} onMouseDown={this.onGrab}>{title}</h3>
                            <div onClick={this.close}>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" className={combineClasses(styles.close)}>
                                    <g style={{ strokeWidth: 5 }}>
                                        <line
                                            strokeLinecap="round"
                                            y2="5"
                                            x2="45"
                                            y1="45"
                                            x1="5"
                                            stroke="#FFF"
                                        />
                                        <line
                                            strokeLinecap="round"
                                            y2="45"
                                            x2="45"
                                            y1="5"
                                            x1="5"
                                            stroke="#FFF"
                                        />
                                    </g>
                                </svg>
                            </div>
                        </div>
                    )}
                    <div className={combineClasses(styles.content, contentStyle)}>
                        {content || children}
                    </div>
                </div>
            </div>
        );
    }

    close() {
        const { onClose } = this.props;

        onClose();
    }

    onGrab(event) {
        event.preventDefault();
        event.stopPropagation();

        this.elementOffset = {
            x: event.clientX - this.window.current.offsetLeft,
            y: event.clientY - this.window.current.offsetTop,
        };

        this.setState({
            initialPos: { x: event.clientX, y: event.clientY },
            deltaPos: {
                x: -this.elementOffset.x,
                y: -this.elementOffset.y,
            },
        });


        this.isDragging = true;
    }

    onMove(event) {
        if (!this.isDragging) return;
        event.preventDefault();
        event.stopPropagation();

        const { initialPos, elementOffset } = this.state;

        this.setState({
            deltaPos: {
                x: event.clientX - initialPos.x - this.elementOffset.x,
                y: event.clientY - initialPos.y - this.elementOffset.y,
            },
        });
    }

    onRelease(event) {
        if (!this.isDragging) return;
        event.preventDefault();
        event.stopPropagation();

        const { initialPos, deltaPos } = this.state;
        this.setState({
            initialPos: { x: initialPos.x + deltaPos.x, y: initialPos.y + deltaPos.y },
            deltaPos: { x: 0, y: 0 },
        });
        this.isDragging = false;
    }
}


Window.defaultProps = {
    content: undefined,
    children: undefined,
    visible: true,
    blocking: true,
    header: true,
    contentStyle: undefined,
};

Window.propTypes = {
    title: PropTypes.string.isRequired,
    content: PropTypes.any,
    children: PropTypes.any,
    onClose: PropTypes.func.isRequired,
    visible: PropTypes.bool,
    blocking: PropTypes.bool,
    header: PropTypes.bool,
    contentStyle: PropTypes.string,
};

export default Window;
