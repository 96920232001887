import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ReactTooltip from "react-tooltip";
import chroma from "chroma-js";

import { combineClasses, copyToClipboard } from "utils";
import { Actions } from "actions";
import ColorPalette from "components/colorPalette";
import ColorPicker from "components/colorPicker";
import Card from "components/card";
import ColorDisplay from "./colorDisplay";

import styles from "./paletteTheming.module.css";


const name = "tmp-palette";

const TooltipId = "copyHarmonyColorTooltip";


class PaletteTheming extends React.Component {
    render() {
        const { theme } = this.props;
        const color = theme[name];

        return (
            <div className={combineClasses("page")}>
                <ReactTooltip
                    id={TooltipId}
                    place="top"
                    effect="solid"
                    className={styles.tooltip}
                    delayShow={250}
                />
                <div className={styles.content}>
                    <h3 className={styles.colorPickerHeader}>Color Picker</h3>
                    <Card hover={false} className={styles.colorPickerCard}>
                        <ColorPicker
                            name={name}
                            value={color.value}
                            category={color.category}
                            type={color.type}
                        />
                    </Card>

                    <h3 className={styles.harmoniesHeader}>Color Harmonies</h3>
                    {this.harmonies(color)}
                </div>
            </div>
        );
    }

    harmonies(color) {
        const size = 5;
        const step = 0.5;
        const angle = 50;

        const functionalColors = true;

        return Object.entries(harmonies).map((entry, index) => {
            const [harmonyName, func] = entry;

            const copyColor = (color) => {
                console.log(color);
                copyToClipboard(color);
            };

            return (
                <Card key={harmonyName} className={styles.harmonyCard} contentClass={styles.harmony} hover={!functionalColors}>
                    <div className={styles.paletteName}>
                        <span>{harmonyName}</span>
                    </div>
                    <ColorPalette
                        className={styles.paletteColors}
                        variable={name}
                        colors={func(color.value, size, step, angle)}
                        direction
                        hover={functionalColors}
                        functional={functionalColors}
                        onClick={copyColor}
                        dataTip={(color, clicked) => (clicked ? "Copied!" : `Copy '${color}'`)}
                        dataFor={TooltipId}
                    />
                </Card>
            );
        });
    }
}


const harmonies = {
    Analogous: analogous,
    Monochromatic: monochromatic,
    Complementary: complementary,
    "Split Complementary": splitComplementary,
    "DS Complementary": doubeSplitComplementary,
};

function analogous(color, size, step, angle) {
    const cColor = chroma.hex(color);

    return [
        cColor.set("hsv.h", `+${angle * 2}`),
        cColor.set("hsv.h", `+${angle * 1}`),
        cColor,
        cColor.set("hsv.h", `-${angle * 1}`),
        cColor.set("hsv.h", `-${angle * 2}`),
    ];
}

function monochromatic(color, size, step, angle) {
    const cColor = chroma.hex(color);

    return [
        cColor.brighten(step * 2),
        cColor.brighten(step * 1),
        cColor,
        cColor.darken(step * 1),
        cColor.darken(step * 2),
    ];
}

function complementary(color, size, step, angle) {
    const cColor = chroma.hex(color);
    const compColor = cColor.set("hsv.h", "+180");

    return [
        cColor.brighten(step),
        cColor.darken(step),
        cColor,
        compColor,
        compColor.darken(step),
    ];
}

function splitComplementary(color, size, step, angle) {
    const cColor = chroma.hex(color);
    const compColor1 = cColor.set("hsv.h", `+${180 - angle}`);
    const compColor2 = cColor.set("hsv.h", `+${180 + angle}`);

    return [
        compColor1.brighten(step * 1),
        compColor1,
        color,
        compColor2,
        compColor2.darken(step * 2),
    ];
}

function doubeSplitComplementary(color, size, step, angle) {
    const cColor = chroma.hex(color);
    const compColor1 = cColor.set("hsv.h", `+${180 - angle}`);
    const compColor2 = cColor.set("hsv.h", `+${180 + angle}`);
    const color1 = cColor.set("hsv.h", `-${angle}`);
    const color2 = cColor.set("hsv.h", `+${angle}`);

    return [
        color1,
        compColor1,
        color,
        compColor2,
        color2,
    ];
}


PaletteTheming.propTypes = {
    theme: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    theme: state.theme,
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(PaletteTheming);
