import { combineReducers } from "redux";

import { Types } from "actions";
import { Types as PersistenceTypes } from "actions/persistence";
import { CommandTypes } from "commands";
import { cloneDeep, merge } from "lodash";

const HISTORY_LENGTH = 25;
let redrawIndex = 0;

function editor(state = { }, action) {
    if (action.type === Types.LAYER_SET_ACTIVE) {
        const newState = cloneDeep(state);
        const { name, path } = action;
        const l = newState.layers;
        const layer = getLayer(l, name, path);

        const { name: oName, path: oPath } = state.activeLayer;
        getLayer(l, oName, oPath).data = newState.canvas;

        redrawIndex++;
        return {
            canvas: canvas(layer.data, action),
            layers: newState.layers,
            activeLayer: { name, path },
            contextMenu: contextMenu(newState.contextMenu, action),
            tool: tool(newState.tool, action),
            redrawIndex,
        };
    }
    if (action.type === Types.REDRAW) {
        redrawIndex++;
    }

    const newState = {
        canvas: canvas(state.canvas, action),
        layers: layers(state.layers, action),
        activeLayer: state.activeLayer,
        contextMenu: contextMenu(state.contextMenu, action),
        tool: tool(state.tool, action),
    };

    if (newState.activeLayer === undefined) {
        const firstLayer = newState.layers[0];
        newState.activeLayer = {
            name: firstLayer.name,
            path: firstLayer.path,
        };
    }

    if (action.type === PersistenceTypes.SET_PERSISTENT_VALUE) {
        if (action.path === "editor.activeLayer") {
            newState.activeLayer = action.value;
            newState.canvas = canvas(getLayer(newState.layers, newState.activeLayer.name, newState.activeLayer.path).data, action);
            redrawIndex++;
        }
    }
    newState.redrawIndex = redrawIndex;
    return newState;
}


export default { editor };

// /////////////////////////////

/*

canvas: {
    current: {},
    history: [{}, {}, {}],
    changed: false,
}

*/

function canvas(state = {
    past: [], current: null, future: [],
}, action) {
    switch (action.type) {
    case Types.SET_CANVAS_DATA:
        return setCanvasData(state, action);
    case PersistenceTypes.SET_PERSISTENT_VALUE:
        if (action.path === "editor.canvas") return action.value;
        return state;
    case Types.HISTORY_PUSH:
        return pushHistory(state);
    case Types.HISTORY_FORWARD:
        return moveForwardHistory(state);
    case Types.HISTORY_BACKWARD:
        return moveBackwardsHistory(state);
    case Types.HISTORY_RESET:
        return {
            past: [], current: state.current, future: [],
        };
    }
    return state;
}

function setCanvasData(state, action) {
    const { past, future } = state;
    const current = { data: action.data, width: action.width, height: action.height };

    redrawIndex++;
    return {
        past,
        current,
        future: [],
    };
}

function pushHistory(state) {
    const { past, current, future } = state;
    const start = Math.max(past.length - HISTORY_LENGTH, 0);

    redrawIndex++;
    return {
        past: [...past.slice(start, past.length), cloneDeep(current)],
        current,
        future: [],
    };
}

function moveForwardHistory(state) {
    const { past, current, future } = state;
    if (future.length === 0) return state;

    redrawIndex++;
    return {
        past: [...past, current],
        current: future[0],
        future: future.slice(1),
    };
}

function moveBackwardsHistory(state) {
    const { past, current, future } = state;
    if (past.length === 0) return state;

    redrawIndex++;
    return {
        changed: false,
        past: past.slice(0, past.length - 1),
        current: past[past.length - 1],
        future: [current, ...future],
    };
}

// /////////////////////////////

/*

layers: {
    "Layer1": {
        type: layer,
        data: {...}, <-- sorting index
        past, current, future
    },
    "Group": [
        type: group,
        data: {...},
        items: {
            "Layer1": {
                type: layer,
                data: {...},
                past, current, future
            },
            "Layer2": {
                type: layer,
                data: {...},
                past, current, future
            }
        }
    ],
}
*/
function createLayer(name, path = "") {
    return {
        type: "layer",
        name,
        path,
        visible: true,

        data: undefined,
    };
}

function getLayer(items, name, path) {
    const parts = (path ? `${path}.${name}` : name).split(".");
    let layer = items;
    parts.forEach((part) => {
        for (let i = 0; i < items.length; i++) {
            if (items[i].name !== part) continue;
            layer = items[i];
        }
        if (!layer.items) return;
        layer = layer.items;
    });

    return layer;
}


function layers(state = [createLayer("Layer 1")], action) {
    switch (action.type) {
    case PersistenceTypes.SET_PERSISTENT_VALUE:
        if (action.path === "editor.layers") return action.value;
        return state;
    case Types.LAYER_ADD:
        return addLayer(state, action);
    case Types.LAYER_REMOVE:
        break;
    case Types.LAYER_UPDATE_DATA:
        return updateLayerData(state, action);
    }

    return state;
}

function addLayer(state, { name, path }) {
    const newState = cloneDeep(state);
    if (newState[name]) return state;
    getLayer(newState, name, path).push(createLayer(name));
    return newState;
}

function updateLayerData(state, { name, path, data }) {
    const newState = cloneDeep(state);
    Object.assign(getLayer(newState, name, path), data);

    redrawIndex++;

    return newState;
}

// /////////////////////////////

function contextMenu(state, action) {
    const initialContextMenuState = {
        open: false,
        x: 0,
        y: 0,
        items: [],
    };
    if (state === undefined) return initialContextMenuState;

    switch (action.type) {
    case CommandTypes.OPEN_CONTEXT_MENU:
        return openContextMenu(action, state);
    case Types.CLOSE_CONTEXT_MENU:
        return closeContextMenu(action, state);
    case Types.SET_CONTEXT_MENU_ITEMS:
        return setContext(action, state);
    }
    return state;
}

/**
* @see actions/editor.js#openContextMenu
*/
function openContextMenu(action, state) {
    if (state.open) return state;
    const { x, y } = action;

    return merge(cloneDeep(state), { open: true, x, y });
}

/**
* @see actions/editor.js#closeContextMenu
*/
function closeContextMenu(_action, state) {
    const newState = cloneDeep(state);
    newState.open = false;
    return newState;
}

/**
* @see actions/editor.js#setContext
*/
function setContext(action, state) {
    const { items } = action;

    const newState = cloneDeep(state);
    newState.items = items;
    return newState;
}

// /////////////////////////////

function tool(state, action) {
    const initialToolState = {
        tool: {
            primary: undefined,
            secondary: undefined,
        },
        color: {
            primary: "#000000",
            secondary: "#FFFFFF",
            picker: undefined,
        },
        mirror: {
            x: false,
            y: false,
        },
    };
    if (state === undefined) return initialToolState;

    switch (action.type) {
    case Types.SET_COLOR:
        return setColor(state, action);
    case Types.SET_TOOL:
        return setTool(state, action);
    case Types.SET_MIRROR:
        return setMirror(state, action);
    case Types.SET_DITHERING:
        return setDithering(state, action);
    case PersistenceTypes.SET_PERSISTENT_VALUE:
        if (action.path === "editor.tool") {
            return merge(cloneDeep(state), action.value);
        }
        return state;
    }

    return state;
}

function setColor(state, action) {
    const newState = { ...state };

    const color = {
        primary: action.color.primary !== undefined ? action.color.primary : state.color.primary,
        secondary: action.color.secondary !== undefined ? action.color.secondary : state.color.secondary,
        picker: action.color.picker,
    };

    newState.color = color;
    return newState;
}

function setTool(state, action) {
    const newState = { ...state };

    const tool = {
        primary: action.tool.primary !== undefined ? { name: action.tool.primary.name, icon: action.tool.primary.icon } : state.tool.primary,
        secondary: action.tool.secondary !== undefined ? { name: action.tool.secondary.name, icon: action.tool.secondary.icon } : state.tool.secondary,
    };

    newState.tool = tool;
    return newState;
}

function setMirror(state, action) {
    const newState = { ...state };

    const mirror = {
        x: action.x !== undefined ? action.x : state.mirror.x,
        y: action.y !== undefined ? action.y : state.mirror.y,
    };

    newState.mirror = mirror;
    return newState;
}

function setDithering(state, action) {
    const newState = { ...state };

    const { dithering } = action;

    newState.dithering = dithering;
    return newState;
}
