import { setColor } from "actions/editor";
import chroma from "chroma-js";
import { Commands } from "commands";

import { drawPixel, getColor } from "./common";


const picker = {
    name: "Color Picker",
    icon: "picker",
    keybinding: "KEYF",
    command: Commands.picker,
    primary: (x, y, colors, data, modifiers, dispatch) => {
        const c = getColor(x, y, data.data, data.width);
        if (c[3] === 0) return;
        const pickedColor = chroma(c[0], c[1], c[2], c[3] / 255.0).hex();
        if (pickedColor === chroma(colors.secondary).hex()) {
            dispatch(setColor(colors.secondary, colors.primary));
        } else {
            dispatch(setColor(pickedColor));
        }
    },
    secondary: (x, y, colors, data, modifiers, dispatch) => {
        const c = getColor(x, y, data.data, data.width);
        if (c[3] === 0) return;
        const pickedColor = chroma(c[0], c[1], c[2], c[3] / 255.0).hex();
        if (pickedColor === chroma(colors.primary).hex()) {
            dispatch(setColor(colors.secondary, colors.primary));
        } else {
            dispatch(setColor(undefined, pickedColor));
        }
    },
    options: {
        mirror: false,
        color: true,
        extra: [],
    },
};

export default picker;
