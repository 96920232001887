export const callback = store => next => action => {

    if (action.callbackPreProcess) {
        action.callbackPreProcess();
    }
    
    let result = next(action);
    
    if (action.callbackPostProcess) {
        action.callbackPostProcess();
    }

    return result;
}
