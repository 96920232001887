import { cloneDeep, merge, clone } from "lodash";

import { Types } from "actions";
import { Types as PersistenceTypes } from "actions/persistence";

import defaultTheme from "theme/theme.json";

// const cookies = new Cookies();

export default { theme, themeEditor };

function themeEditor(state = { active: "" }, action) {
    let newState = state;

    switch (action.type) {
    case Types.SET_ACTIVE_VARIABLE:
        newState = clone(state);
        newState.active = action.name;
        newState.lastColorType = undefined;
        return newState;
    case Types.SET_THEME_VARIABLE:
        newState = clone(state);
        newState.lastColorType = action.colorType;
        return newState;
    }

    return state;
}

function theme(state = defaultTheme, action) {
    switch (action.type) {
    case Types.SET_THEME_VARIABLE:
        return setThemeVariable(state, action);
    case Types.SET_THEME:
        return setTheme(state, action);
    case PersistenceTypes.SET_PERSISTENT_VALUE:
        if (action.path === "theme") return merge(cloneDeep(state), action.value);
        break;
    }
    return state;
}

/**
* @see actions/theme.js#setThemeVariable
*/
function setThemeVariable(state, action) {
    const newState = clone(state);
    newState[action.key] = { ...(newState[action.key]), ...action.value };
    // cookies.set("theme", newState, { path: '/', sameSite: true });

    return newState;
}

/**
* @see actions/theme.js#setTheme
*/
function setTheme(state, action) {
    const newState = cloneDeep(action.theme);
    return newState;
}

/**
* @see actions/theme.js#setActiveVariable
*/
function setActiveVariable(state, action) {
    const newState = clone(state);
    newState.active = action.name;
    newState.lastColorType = undefined;
    return newState;
}

/**
* @see actions/theme.js#setLastColorType
*/
function setLastColorType(state, action) {
    const newState = clone(state);
    newState.lastColorType = action.colorType;
    return newState;
}
