import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { combineClasses } from "utils";
import { Commands } from "commands";

import CommandAlert from "components/commandAlert";
import CommandListener from "components/commandListener";
import Path from "./svg/path";

import styles from "./editor.module.css";
import ContextMenu, { ContextItem } from "./contextMenu";
import Pixel from "./pixel";


class Editor extends React.Component {
    render() {
        const { test, items } = this.props;

        return (
            <>
                <ContextMenu items={items} />
                <div className={combineClasses(styles.editor)}>
                    <Pixel />
                </div>
                <CommandAlert />
                <CommandListener />
            </>
        );
    }
}


Editor.defaultProps = {
};

Editor.propTypes = {
};

const mapStateToProps = (state) => ({
    items: state.editor.contextMenu.items,
});

const mapDispatchToProps = {
    test: Commands.test,
};

export default connect(mapStateToProps, mapDispatchToProps)(Editor);
