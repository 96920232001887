import React from "react";

function shades(colors, onClick, onMouseUp) {
    const [c1, c2, c3, c4, c5] = colors;
    return (
        <g>
            <path fill={c1} onClick={() => onClick(c1)} onMouseUp={() => onMouseUp(0)} d="M34.056 14.784A49 49 0 001.132 1.142v7a42.002 42.002 0 0127.984 11.592z" />
            <path fill={c2} onClick={() => onClick(c2)} onMouseUp={() => onMouseUp(1)} d="M1.134 9.143A41.002 41.002 0 0128.41 20.44l-4.95 4.95a34.004 34.004 0 00-22.327-9.248z" />
            <path fill={c3} onClick={() => onClick(c3)} onMouseUp={() => onMouseUp(2)} d="M1.132 24.143a26.008 26.008 0 0116.67 6.905l4.95-4.95a33.004 33.004 0 00-21.62-8.955z" />
            <path fill={c4} onClick={() => onClick(c4)} onMouseUp={() => onMouseUp(3)} d="M17.095 31.756l-4.95 4.95a18.016 18.016 0 00-11.013-4.562v-7a25.008 25.008 0 0115.963 6.612z" />
            <path fill={c5} onClick={() => onClick(c5)} onMouseUp={() => onMouseUp(4)} d="M1.132 40.182a10 10 0 015.329 2.208l4.977-4.977a17.018 17.018 0 00-10.306-4.269z" />
        </g>
    );
}

export default shades;
