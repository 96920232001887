import { Types } from "actions";
import { Types as PersistenceTypes } from "actions/persistence";
import { combineReducers } from "redux";

import { cloneDeep, merge } from "lodash";


const gym = combineReducers({
    exercises,
});

export default { gym };

function exercises(state = [], action) {
    switch (action.type) {
    case Types.ADD_EXERCISE:
        return addExcercise(state, action);
    case Types.MODIFY_EXCERCISE:
        return modifyExcercise(state, action);
    case Types.REMOVE_EXERCISE:
        return removeExcercise(state, action);
    case PersistenceTypes.SET_PERSISTENT_VALUE:
        if (action.path === "gym.exercises") { return action.value; }
    }
    return state;
}

function addExcercise(state = [], action) {
    const newState = cloneDeep(state);
    const { path, item } = action;

    if (path === undefined || path === "") {
        newState.push(item);
        return newState;
    }

    let items = newState;
    const hasItems = action.path.split(".").every((part) => {
        const item = items.find((i) => i.name === part);
        if (item === undefined || item.items === undefined) {
            return false;
        }
        items = item.items;
        return true;
    });
    if (!hasItems) return state;

    if (items.find((i) => i.name === item.name)) return state;

    items.push(item);
    return newState;
}

function modifyExcercise(state = [], action) {
    return state;
}

function removeExcercise(state = [], action) {
    const newState = [...state];
    newState.splice(action.index, 1);
    return newState;
}
