import Cookies from "universal-cookie";
import { setPersistentValue, Types as PersistanceTypes } from "actions/persistence";
import { Types } from "actions";

const DB_INIT_ERROR_MESSAGE = "This browser doesn't support IndexedBD, which is required for persistent storage of data on this website. "
    + "Images in the editor cannot be stored in the web browser because of this.";

const persistentPaths = [
    "theme",
    "editor.canvas",
    "editor.layers",
    "editor.tool",
    "editor.activeLayer",
    "todo.items",
];


const DB_NAME = "PersistenceDB";
const DB_VERSION = 1;
const PERSISTENCE_STORE_NAME = "persistence";

const debug = false;

let persistanceEnabled = true;
if (!window.indexedDB || !window.IDBTransaction || !window.IDBKeyRange) {
    alert(DB_INIT_ERROR_MESSAGE);
    persistanceEnabled = false;
}
let database;

const StatusTypes = {
    UNINITIALIZED: "UNINITIALIZED",
    READY: "READY",
    ERROR: "ERROR",
};

let moduleStatus = StatusTypes.UNINITIALIZED;

function initializeDB(store) {
    const request = window.indexedDB.open(DB_NAME, DB_VERSION);
    request.onerror = (event) => {
        alert(DB_INIT_ERROR_MESSAGE);
        persistanceEnabled = false;
        moduleStatus = StatusTypes.ERROR;
    };
    request.onsuccess = (event) => {
        database = event.target.result;

        persistentPaths.forEach((p) => {
            // const value = JSON.parse(localStorage.getItem(p));
            database.transaction([PERSISTENCE_STORE_NAME]).objectStore(PERSISTENCE_STORE_NAME).get(p).onsuccess = (event) => {
                if (event.target.result === undefined) {
                    const persistenceStore = database.transaction([PERSISTENCE_STORE_NAME], "readwrite").objectStore(PERSISTENCE_STORE_NAME);
                    persistenceStore.add({ path: p, data: undefined });
                    return;
                }
                const { data } = event.target.result;
                if (debug) console.log("[Persistence]", p, ">>", data);
                if (data === undefined || data === null) return;
                store.dispatch((setPersistentValue(p, data)));
            };
        });
        moduleStatus = StatusTypes.READY;
    };
    request.onupgradeneeded = (event) => {
        const db = event.target.result;
        const persistenceStore = db.createObjectStore("persistence", { keyPath: "path" });
        persistenceStore.createIndex("data", "data", { unique: false });
        console.log("Upgrade DB");
        // TODO
    };
}

function getDeep(obj, path) {
    const p = path.split(".");
    for (let i = 0; i < p.length; i++) {
        if (obj === undefined) return undefined;
        // eslint-disable-next-line no-param-reassign
        obj = obj[p[i]];
    }
    return obj;
}


export const persistence = (store) => (next) => (action) => {
    if (!persistanceEnabled) return next(action);

    let result;
    if (action.type === Types.INITIALIZE) {
        result = next(action);
        initializeDB(store);
    } else if (action.type === PersistanceTypes.SET_PERSISTENT_VALUE) {
        result = next(action);
    } else {
        const pre = persistentPaths.map((p) => getDeep(store.getState(), p));

        result = next(action);

        if (moduleStatus === StatusTypes.READY) {
            persistentPaths.forEach((p, index) => {
                const state = getDeep(store.getState(), p);
                if (state === pre[index]) return;
                queuePersistenceUpdate(p, state, action.type);
            });
        }
    }

    return result;
};


const updateDelay = 50;
const queueTimeouts = {};

function queuePersistenceUpdate(p, value, actionType) {
    clearTimeout(queueTimeouts[p]);
    queueTimeouts[p] = setTimeout(() => {
        // localStorage.setItem(p, JSON.stringify(value));
        const persistenceStore = database.transaction([PERSISTENCE_STORE_NAME], "readwrite").objectStore(PERSISTENCE_STORE_NAME);
        const request = persistenceStore.put({ path: p, data: value });
        request.onsuccess = (event) => {
            if (debug) console.log("[Persistence]", p, "<<", value, `(${actionType})`);
        };
        request.onerror = (event) => {
            console.log("[Persistence] Error setting path ", p, `(${actionType})`, event);
        };
    }, updateDelay);
}

export default persistence;
