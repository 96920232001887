import React from "react";
import PropTypes from "prop-types";

import { combineClasses } from "utils";

import styles from "./card.module.css";
import Separator from "./separator";


class Card extends React.Component {
    render() {
        const {
            width, header, children, className, contentClass, hover,
            onClick,
        } = this.props;
        return (
            <card
                className={combineClasses(
                    styles.card,
                    width === "wide" ? styles.wide : undefined,
                    width === "full" ? styles.full : undefined,
                    hover ? styles.hoverCard : undefined,
                    className,
                )}
                onClick={onClick}
            >
                {header && (
                    <>
                        <span><b>{header}</b></span>
                        <Separator height={2} />
                    </>
                )}
                <div className={combineClasses(styles.content, contentClass)}>
                    {children}
                </div>
            </card>
        );
    }
}

Card.defaultProps = {
    width: undefined,
    header: undefined,
    children: undefined,
    className: undefined,
    contentClass: undefined,
    hover: true,
    onClick: undefined,
};

Card.propTypes = {
    width: PropTypes.string,
    header: PropTypes.string,
    children: PropTypes.any,
    className: PropTypes.string,
    contentClass: PropTypes.string,
    hover: PropTypes.bool,
    onClick: PropTypes.func,
};

export default Card;
