import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { RouteComponentProps } from "react-router";

import { combineClasses } from "utils";
import { Actions } from "actions";

import { addExercise, removeExercise } from "actions/gym";
import FolderBrowser, { File, Folder } from "components/folderBrowser";
import Collection from "./collection";

import styles from "./gym.module.css";


class Gym extends React.Component {
    constructor(props) {
        super(props);

        this.addCollection = this.addCollection.bind(this);
        this.addExercise = this.addExercise.bind(this);
        this.clear = this.clear.bind(this);
    }

    render() {
        const { items, location } = this.props;

        const folderItems = [File("item1", {}), File("item2", {}), Folder("folder1", {}, [File("item1.1", {})]), Folder("folder2", {}, []), Folder("folder3", {}, [])];


        return (
            <>
                <div className="frontPage">
                    <h1>Gym</h1>
                </div>
                <div className="page">
                    <div className={combineClasses(styles.gym)}>
                        <FolderBrowser items={items} />

                        {/* <Collection items={items} /> */}
                        <div className={combineClasses(styles.buttons)}>
                            <div role="button" onClick={this.addExercise} className={combineClasses(styles.addExercise)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22"><path fill="#FFF" d="M22 10h-10v-10h-2v10h-10v2h10v10h2v-10h10z" /></svg>
                            </div>
                            <div role="button" onClick={this.addCollection} className={combineClasses(styles.addExercise)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22"><path fill="#FFF" d="M22 10h-10v-10h-2v10h-10v2h10v10h2v-10h10z" /></svg>
                            </div>
                            {/* <button type="button" onClick={this.addExercise} className={combineClasses(styles.addExercise)}>Add Exercise</button>
                        <button type="button" onClick={this.addCollection} className={combineClasses(styles.addExercise)}>Add Collection</button>
                        <button type="button" onClick={this.clear} className={combineClasses(styles.addExercise)}>Clear</button> */}
                        </div>
                    </div>
                </div>
            </>
        );
    }

    addCollection() {
        const { addExercise, items, location } = this.props;
        const path = location.hash.replace("#", "").replaceAll("/", ".");

        addExercise(Folder(`Test${items.length}`, undefined, []), path);
    }

    addExercise() {
        const { addExercise, items, location } = this.props;
        const path = location.hash.replace("#", "").replaceAll("/", ".");

        addExercise(File(`Test${items.length}`), path);
    }

    clear() {
        const { removeExercise, items } = this.props;
        items.forEach((item) => {
            removeExercise(0);
        });
    }
}

Gym.defaultProps = {

};

Gym.propTypes = {
    // React router
    location: PropTypes.any.isRequired,
    history: PropTypes.any.isRequired,

    // State
    items: PropTypes.any.isRequired,

    // Dispatch
    addExercise: PropTypes.func.isRequired,
    removeExercise: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    items: state.gym.exercises,
});

const mapDispatchToProps = {
    addExercise,
    removeExercise,

};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Gym));

export const ItemTypes = {
    COLLECTION: "COLLECTION",
    ITEM: "ITEM",
};
