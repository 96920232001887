export const Types = {
    SET_THEME_VARIABLE: "SET_THEME_VARIABLE",
    SET_THEME: "SET_THEME",

    SET_ACTIVE_VARIABLE: "SET_ACTIVE_VARIABLE",
    SET_LAST_COLOR_TYPE: "SET_LAST_COLOR_TYPE",
};

export const Actions = {
    setThemeVariable,
    setTheme,
    setActiveVariable,
};


/**
 * Set a theme variable to given value.
 */
export function setThemeVariable(key, value, colorType) {
    return {
        type: Types.SET_THEME_VARIABLE,
        key,
        value,
        colorType,
    };
}

/**
 * Set theme from variable.
 */
export function setTheme(theme) {
    return {
        type: Types.SET_THEME,
        theme,
    };
}

/**
 * Set variable to edit.
 */
export function setActiveVariable(name) {
    return {
        type: Types.SET_ACTIVE_VARIABLE,
        name,
    };
}