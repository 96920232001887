import React from "react";
import PropTypes from "prop-types";
import ContentEditable from "react-contenteditable";

import { combineClasses } from "utils";

import styles from "./hexField.module.css";


const hexRegex = /^[a-fA-F0-9]*$/;

class HexField extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            html: props.value,
        };

        this.lastValue = props.value;

        this.contentRef = React.createRef();

        this.handleChange = this.handleChange.bind(this);
        this.filter = this.filter.bind(this);
        this.pasteFilter = this.pasteFilter.bind(this);
    }

    render() {
        const { value, border, className } = this.props;
        const { html } = this.state;

        if (value !== this.lastValue) {
            this.lastValue = value;
            this.handleChange({ target: { value } }, true);
        }

        if (this.contentRef.current) {
            this.contentRef.current.setAttribute("spellcheck", "false");
        }

        return (
            <div className={combineClasses(border ? styles.border : undefined, className)}>
                <div className={styles.hexField}>
                    <span>#</span>
                    <div className={styles.textContainer}>
                        <ContentEditable
                            className={styles.hex}
                            html={html}
                            disabled={false}
                            onChange={this.handleChange}
                            onKeyPress={this.filter}
                            onPaste={this.pasteFilter}
                            innerRef={this.contentRef}
                        />
                        <div className={combineClasses(styles.red, styles.color)} />
                        <div className={combineClasses(styles.green, styles.color)} />
                        <div className={combineClasses(styles.blue, styles.color)} />
                    </div>

                </div>
            </div>
        );
    }

    handleChange(event, skip) {
        let text = event.target.value;
        const { onChange } = this.props;
        if (text.length >= 5) {
            text = text.replace("<br>", "");
        }

        this.setState({ html: text });
        if (!skip && onChange && hexRegex.test(text) && text.length === 6) onChange(text.toLowerCase());
    }

    filter(event) {
        const { html } = this.state;
        const lengthCheck = html.length + 1 <= 6 || window.getSelection().toString().length > 0;

        if (!hexRegex.test(event.key) || !lengthCheck) {
            event.preventDefault();
        }
    }

    pasteFilter(event) {
        event.stopPropagation();
        event.preventDefault();

        const clipboardData = event.clipboardData || window.clipboardData;
        let text = clipboardData.getData("text").trim();
        text = text.replace("#", "");
        text = text.replace("<br>", "");
        if (text.length !== 6 || !hexRegex.test(text)) {
            text = "000000";
        }
        this.handleChange({ target: { value: text } });
    }
}

HexField.defaultProps = {
    value: "",
    border: true,
    className: undefined,
    onChange: undefined,
};

HexField.propTypes = {
    value: PropTypes.string,
    border: PropTypes.bool,
    className: PropTypes.string,
    onChange: PropTypes.func,
};

export default HexField;
