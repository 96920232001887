export const Commands = {
    COMMAND_TOOL_BUCKET: "COMMAND_TOOL_BUCKET",
    COMMAND_TOOL_PENCIL: "COMMAND_TOOL_PENCIL",
    COMMAND_TOOL_ERASER: "COMMAND_TOOL_ERASER",
    COMMAND_TOOL_PICKER: "COMMAND_TOOL_PICKER",
    COMMAND_TOOL_MODIFY: "COMMAND_TOOL_MODIFY",

    COMMAND_SWAP_COLORS: "COMMAND_SWAP_COLORS",

    COMMAND_UNDO: "COMMAND_UNDO",
    COMMAND_REDO: "COMMAND_REDO",

    COMMAND_PAN: "COMMAND_PAN",
    COMMAND_SHOW_GRID: "COMMAND_SHOW_GRID",
};

export const Actions = {
    pencil,
    eraser,
    bucket,
    picker,
    modify,

    swapColors,

    undo,
    redo,

    pan,
    showGrid,
};

function pencil() {
    return {
        type: Commands.COMMAND_TOOL_PENCIL,
        title: "Pencil",
    };
}

function eraser() {
    return {
        type: Commands.COMMAND_TOOL_ERASER,
        title: "Eraser",
    };
}

function bucket() {
    return {
        type: Commands.COMMAND_TOOL_BUCKET,
        title: "Bucket",
    };
}

function picker() {
    return {
        type: Commands.COMMAND_TOOL_PICKER,
        title: "Color Picker",
    };
}

function modify() {
    return {
        type: Commands.COMMAND_TOOL_MODIFY,
        title: "Modify",
    };
}

function swapColors() {
    return {
        type: Commands.COMMAND_SWAP_COLORS,
        title: "Swap Colors",
    };
}

function undo() {
    return {
        type: Commands.COMMAND_UNDO,
        title: "Undo",
    };
}

function redo() {
    return {
        type: Commands.COMMAND_REDO,
        title: "Redo",
    };
}

function pan() {
    return {
        type: Commands.COMMAND_PAN,
        title: "Pan",
    };
}

function showGrid() {
    return {
        type: Commands.COMMAND_SHOW_GRID,
        title: "Show Grid",
    };
}
