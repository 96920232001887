import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    NavLink,
} from "react-router-dom";

import styles from "./common.module.css";


class Header extends React.Component {
    render() {
        return (
            <header>
                <nav className={styles.navigation}>
                    <ul>
                        <li>
                            <NavLink activeClassName={styles.active} to="/" exact>Home</NavLink>
                        </li>
                        <li>
                            <NavLink activeClassName={styles.active} to="/status">Status</NavLink>
                        </li>
                        <li>
                            <NavLink activeClassName={styles.active} to="/theme">Theme</NavLink>
                        </li>
                        {/* <li>
                            <NavLink activeClassName={styles.active} to="/plot">Plot</NavLink>
                        </li> */}
                        <li>
                            <a href="editor">Editor</a>
                        </li>
                        {/* <li>
                            <NavLink activeClassName={styles.active} to="/editor">Editor</NavLink>
                        </li> */}
                        <li>
                            <a href="https://wtg.ceyper.com">WTG</a>
                        </li>
                        <li>
                            <a href="https://tools.ceyper.com/spritepacker"><span style={{ padding: "0em 0.5em" }}>Sprite packer</span></a>
                        </li>
                        <li>
                            <a href="login">Login</a>
                        </li>
                    </ul>
                </nav>
                <div className={styles.navigationPlaceholder} />
            </header>
        );
    }
}

export default Header;
