import { Actions as ComActions } from "./communication";

export const Types = {
    LOGIN: "LOGIN",
    LOGIN_SUCCESS: "LOGIN_SUCCESS",
    LOGIN_ERROR: "LOGIN_ERROR",

    REGISTER: "REGISTER",
    REGISTER_SUCCESS: "REGISTER_SUCCESS",
    REGISTER_ERROR: "REGISTER_ERROR",
};

export const Actions = {
    login,
    register,
};


export function login(username, password) {
    return ComActions.axiosPost("auth/login", { username, password }, {},
        Types.LOGIN, Types.LOGIN_SUCCESS, Types.LOGIN_ERROR);
}

export function register(username, password, discord) {
    return ComActions.axiosPost("auth/register", { username, password, discord }, {},
        Types.REGISTER, Types.REGISTER_SUCCESS, Types.REGISTER_ERROR);
}
