import React from "react";
import PropTypes from "prop-types";

import { combineClasses } from "utils";
import { Actions } from "actions";

import Button from "components/button";
import Window from "components/window";

import styles from "./io.module.css";


class Import extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            visible: false,
            width: undefined,
            height: undefined,
        };

        this.canvas = React.createRef();

        this.close = this.close.bind(this);
        this.overwrite = this.overwrite.bind(this);
        this.addToLayer = this.addToLayer.bind(this);

        this.imageFromFile = this.imageFromFile.bind(this);
        this.onImageChanged = this.onImageChanged.bind(this);
    }


    render() {
        const { visible, width, height } = this.state;

        return (
            <>
                <Button
                    text="Import"
                    onClick={() => {
                        this.setState({ visible: true });
                    }}
                />
                <Window
                    title="Import"
                    content={(
                        <div className={combineClasses(styles.import)}>
                            <Button text="File..." onClick={this.imageFromFile} />
                            <span>{width && height && `${width} x ${height}`}</span>
                            <canvas className={combineClasses(styles.preview)} ref={this.canvas} />
                            <Button text="Overwrite" onClick={this.overwrite} />
                            <Button text="Add layer" onClick={this.addToLayer} />
                        </div>
                    )}
                    onClose={this.close}
                    visible={visible}
                />
            </>
        );
    }

    close() {
        this.setState({
            visible: false,
            width: undefined,
            height: undefined,
        });
    }

    overwrite() {
        const { dispatch } = this.props;

        const canvas = this.canvas.current;
        const imageData = canvas.getContext("2d").getImageData(0, 0, canvas.width, canvas.height);
        dispatch(Actions.setCanvasData(imageData));

        this.close();
    }

    addToLayer() {
        this.close();
    }

    imageFromFile() {
        const input = document.createElement("input");

        input.type = "file";

        input.addEventListener("change", this.onImageChanged);

        input.click();
    }

    onImageChanged(event) {
        if (event.srcElement.files && event.srcElement.files[0]) {
            const canvas = this.canvas.current;
            const ctx = canvas.getContext("2d");
            const img = new Image();
            img.onload = () => {
                canvas.width = img.width;
                canvas.height = img.height;
                ctx.drawImage(img, 0, 0);
                this.setState({
                    width: img.width,
                    height: img.height,
                });
            };
            img.src = URL.createObjectURL(event.srcElement.files[0]);
        }
    }
}


Import.defaultProps = {
};

Import.propTypes = {
    dispatch: PropTypes.any.isRequired,
};

export default Import;
