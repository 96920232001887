import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { combineClasses, clamp } from "utils";
import { Actions, Types } from "actions";
import { Commands, CommandTypes, CommandHandler, CommandBindings } from "commands";

import Card from "components/card";

import styles from "./contextMenu.module.css";

const interactDelay = 50;


class ContextMenu extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            current: undefined,
            svgPath: "M 10 80 C 40 10, 65 10, 95 80 S 150 150, 180 80",
            circles: [],
            svgWidth: window.innerWidth,
            svgHeight: window.innerHeight,
        };

        this.open = this.open.bind(this);
        this.close = this.close.bind(this);
        this.onMouseUp = this.onMouseUp.bind(this);
        this.onKeyUp = this.onKeyUp.bind(this);
        this.onMouseUpCenter = this.onMouseUpCenter.bind(this);
        this.onMouseEnterCenter = this.onMouseEnterCenter.bind(this);
        this.onMouseLeaveCenter = this.onMouseLeaveCenter.bind(this);

        this.onMouseMove = this.onMouseMove.bind(this);

        this.ref = React.createRef();
        this.submenuRef = React.createRef();
    }

    componentWillMount() {
        let { isRoot } = this.props;

        if (isRoot) {
            document.addEventListener("mousemove", this.onMouseMove);
            CommandHandler.registerCommandHandler(CommandTypes.OPEN_CONTEXT_MENU, this.open);
            CommandHandler.registerCommandExecution(CommandTypes.OPEN_CONTEXT_MENU, (state) => !state.editor.contextMenu.open);
            CommandHandler.registerCommandBinding(Actions.ContextMenu.open, "Space", [CommandHandler.CommandModifiers.CTRL]);
        }

        //document.addEventListener("mouseup", this.onMouseUp);
        document.addEventListener("keyup", this.onKeyUp);

    }

    componentWillUnmount() {
        let { isRoot } = this.props;

        if (isRoot) {
            document.removeEventListener("mousemove", this.onMouseMove);
            CommandHandler.unregisterCommandHandler(CommandTypes.OPEN_CONTEXT_MENU, this.open);
        }

        //document.removeEventListener("mouseup", this.onMouseUp);
        document.removeEventListener("keyup", this.onKeyUp);

    }

    render() {
        let { items, radius, range, x, y, isRoot, open } = this.props;
        let { svgPath, svgWidth, svgHeight, circles } = this.state;

        let totalRange = range.max - range.min;
        let count = items.length - (totalRange === 1 ? 0 : 1);

        let { x: x1, y: y1 } = this.getPosition(0 / count, radius);
        let { x: x2, y: y2 } = this.getPosition(1 / count, radius);

        let a = x1 - x2;
        let b = y1 - y2;

        let itemRadius = clamp(Math.sqrt(a * a + b * b) / 2,
            radius / 3,
            2 / 3 * radius);

        return <div style={{ left: x, top: y }} className={combineClasses(styles.contextMenu, open && styles.open)} ref={this.ref}>
            {isRoot && <>
                <svg className={combineClasses(styles.svg)} width={svgWidth} height={svgHeight} xmlns="http://www.w3.org/2000/svg">
                    <circle cx={svgWidth / 2} cy={svgHeight / 2} r="6" />
                    {/* <circle cx={svgWidth / 2 + this.dx} cy={svgHeight / 2 + this.dy} r="4"/> */}
                    {circles.map((c) => {
                        return <circle cx={svgWidth / 2 + c.x} cy={svgHeight / 2 + c.y} r="4" />
                    })}
                    <path d={svgPath} stroke="black" stroke="black" fill="transparent" />
                </svg>
                <div className={combineClasses(styles.center)} onMouseEnter={this.onMouseEnterCenter} onMouseLeave={this.onMouseLeaveCenter} />
            </>}
            {items.map((item, index) => this.createMenuItem(item, index, count, itemRadius))}
        </div>;
    }

    createMenuItem(item, index, count, itemRadius) {
        let { menuState, radius } = this.props;
        let { x, y } = this.getPosition(index / count);

        let { current } = this.state;

        let offset = this.toRange(index / count) + 0.5;
        let delta = clamp((count) * 0.05, 0, 0.3);

        let hidden = current !== undefined && current !== item && current.items !== undefined && current.items.length !== 0;
        let hover = current === item;
        let subOpen = hover&& item.items !== undefined && item.items.length > 0;

        //item.action
        return <>
            <div style={{ left: x, top: y, "--radius": `${itemRadius}px` }}
                onMouseUp={() => this.onMouseUp(item)}
                onMouseEnter={() => this.onMouseEnter(item)}
                onMouseLeave={() => this.onMouseLeave(item)}
                className={combineClasses(styles.menuItem, hidden && styles.hidden)} key={item.title}>
                <Card className={combineClasses(styles.menuItemCard, subOpen && styles.submenuOpen)} contentClass={styles.itemContent} hover={true}>
                    {item.image && <img src={item.image}/>}
                    {item.title}
                </Card>
                {item.description &&
                    <Card className={combineClasses(styles.hoverInfo, hover && styles.hover)} contentClass={styles.itemContent} hover={false}>
                        {item.description}
                    </Card>
                }
            </div>
            {subOpen &&
                <ContextMenu x={x} y={y} items={item.items} radius={radius}
                    range={{ min: offset + 0.2, max: offset + 0.8 }}
                    isRoot={false} open={true} ref={this.submenuRef} menuState={menuState}
                />}
        </>

    }

    getPosition(precentage) {
        let { radius } = this.props;
        precentage = this.toRange(precentage) + 0.5;
        return {
            x: Math.cos(precentage * Math.PI * 2) * radius * 1.3,
            y: Math.sin(precentage * Math.PI * 2) * radius
        };
    }

    hasLeft = false;
    isToggled = false;

    open() {
        let { isRoot } = this.props;
        if (!isRoot) return;

        this.hasLeft = false;
        this.isToggled = false;
        this.dx = 0;
        this.dy = 0;

        this.setState({
            svgPath: ""
        });
    }

    close() {
        let { closeContext, isRoot } = this.props;

        if (!isRoot) return;

        setTimeout(() => {
            closeContext();
            this.setState({
                current: undefined,
            });
            this.hasLeft = false;
            this.isToggled = false;
        }, 1);
    }

    dx = 0;
    dy = 0;
    onMouseMove(event) {
        this.dx += event.movementX;
        this.dy += event.movementY;

        this.updatePath();
    }

    updatePath() {
        let { svgWidth, svgHeight } = this.state;

        let path = `M ${svgWidth / 2} ${svgHeight / 2}`;
        let current = { x: 0, y: 0 };
        let circles = [];

        let menu = this;

        while (menu.submenuRef !== undefined && menu.submenuRef.current !== null) {
            let x = menu.submenuRef.current.props.x;
            let y = menu.submenuRef.current.props.y;

            path += ` l ${x} ${y}`;
            current.x += x;
            current.y += y;
            circles.push({x: current.x, y: current.y});

            menu = menu.submenuRef.current;
        }

        path += ` l ${this.dx - current.x} ${this.dy - current.y}`;

        this.setState({
            svgPath: path,
            circles
            //svgPath: `M ${svgWidth / 2} ${svgHeight / 2}${points.map(p => ` l ${p.x} ${p.y}`).join("")}`
        });
    }

    onMouseUp(item) {
        item.action();
        this.close();
    }

    onKeyUp(event) {
        if (!CommandBindings.Bindings[CommandTypes.OPEN_CONTEXT_MENU]) return;
        let bindings = CommandBindings.Bindings[CommandTypes.OPEN_CONTEXT_MENU].bindings;
        if (bindings.length === 0) return;

        if (this.state.hover !== undefined && this.state.hover.action !== undefined)
            this.state.hover.action();
        this.close();
    }

    onMouseLeaveCenter(event) {
        this.hasLeft = true;
        clearTimeout(this.menuTimeout);
    }

    onMouseEnterCenter(event) {
        this.menuTimeout = setTimeout(() => {
            this.setState({ current: undefined });
            this.updatePath();
        }, interactDelay);
    }

    onMouseUpCenter(event) {
        if (!this.hasLeft) this.isToggled = true;
    }


    menuTimeout = undefined;

    onMouseEnter(item) {
        let { current } = this.state;
        if (current === item) {
            this.menuTimeout = setTimeout(() => {
                if (current === undefined || this.submenuRef.current === null) return;
                this.submenuRef.current.setState({ current: undefined });
                this.setState({ hover: item });
                this.updatePath();
            }, interactDelay);
        } else {
            this.menuTimeout = setTimeout(() => {
                this.setState({ current: item, hover: item });
                this.updatePath();
            }, interactDelay);
        }
    }

    onMouseLeave(item) {
        clearTimeout(this.menuTimeout);
        this.setState({ hover: undefined });
    }

    toRange(number) {
        let { min, max } = this.props.range;
        return (max - min) * number + min;
    }

}


ContextMenu.defaultProps = {
    items: [],
    radius: 80,
    range: { min: 0, max: 1 },
    isRoot: true,
    openContext: undefined,
    closeContext: undefined,
};

ContextMenu.propTypes = {
    items: PropTypes.any,
    radius: PropTypes.number,
    range: PropTypes.any,

    isRoot: PropTypes.bool,

    open: PropTypes.bool.isRequired,
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,

    openContext: PropTypes.func,
    closeContext: PropTypes.func,
};

const mapStateToProps = state => ({
    open: state.editor.contextMenu.open,
    x: state.editor.contextMenu.x,
    y: state.editor.contextMenu.y,
});

const mapDispatchToProps = {
    openContext: Actions.ContextMenu.open,
    closeContext: Actions.ContextMenu.close,
};

export default connect(mapStateToProps, mapDispatchToProps)(ContextMenu);

export function contextItem(title, description, action, items = undefined, image = undefined) {
    let cItem = {
        title,
        description,
        action,
        items: items || [],
        image,
    };

    cItem.addItem = (item) => {
        cItem.items.push(item);
        return cItem;
    }

    return cItem;
}
