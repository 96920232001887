import React from "react";
import PropTypes from "prop-types";

import { combineClasses } from "utils";
import { Actions } from "actions";

import Button from "components/button";
import Window from "components/window";

import TextInput from "components/textInput";
import styles from "./io.module.css";


class Export extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            visible: false,
            name: "image",
            scale: 1,
        };

        this.download = this.download.bind(this);
        this.downloadData = this.downloadData.bind(this);
    }


    render() {
        const { visible, name, scale } = this.state;

        return (
            <>
                <Button
                    text="Export"
                    onClick={() => {
                        this.setState({ visible: true });
                    }}
                />
                {visible && (
                    <Window
                        title="Export"
                        content={(
                            <div className={styles.export}>
                                <span>Name</span>
                                <TextInput className={styles.imageName} text={name} onChange={(value) => this.setState({ name: value })} type="any" max={32} />
                                <span>Scale</span>
                                <TextInput className={styles.imageScale} text={scale} onChange={(value) => this.setState({ scale: value })} type="number" min={1} max={1024} />
                                <Button className={styles.download} onClick={this.download} text="Download" />
                            </div>
                        )}
                        onClose={() => {
                            this.setState({ visible: false });
                        }}
                    />
                )}
            </>
        );
    }

    download() {
        const { canvasData } = this.props;
        const { scale } = this.state;

        const canvas = document.createElement("canvas");
        canvas.height = canvasData.height * scale;
        canvas.width = canvasData.width * scale;

        const ctx = canvas.getContext("2d");
        const imageData = canvas.getContext("2d").getImageData(0, 0, canvasData.width * scale, canvasData.height * scale);

        for (let y = 0; y < canvasData.height; ++y) {
            for (let x = 0; x < canvasData.width; ++x) {
                const i = (x + y * canvasData.width) * 4;

                for (let sy = 0; sy < scale; ++sy) {
                    for (let sx = 0; sx < scale; ++sx) {
                        const si = ((x + y * canvasData.width * scale) * scale + sx + sy * canvasData.width * scale) * 4;
                        imageData.data[si + 0] = canvasData.data[i + 0];
                        imageData.data[si + 1] = canvasData.data[i + 1];
                        imageData.data[si + 2] = canvasData.data[i + 2];
                        imageData.data[si + 3] = canvasData.data[i + 3];
                    }
                }
            }
        }

        ctx.putImageData(imageData, 0, 0);

        this.downloadData(canvas);
    }

    downloadData(canvas) {
        const { name } = this.state;

        const link = document.createElement("a");
        const imageType = "image/png";
        let dataLink = "";

        dataLink = canvas.toDataURL(imageType).replace(imageType, "image/octet-stream");

        link.href = dataLink;
        link.download = `${name}.png`;
        link.click();
        link.blur();
    }
}


Export.defaultProps = {
    canvasData: undefined,
};

Export.propTypes = {
    canvasData: PropTypes.any,
};

export default Export;
