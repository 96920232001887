import { Actions } from "actions";
import { Commands, CommandHandler } from "commands";
import { ContextItem } from "routes/editor/contextMenu";
import { fill, clear } from "./fill";
import mirror from "./mirror";
import flip from "./flip";
import offset from "./offset";


function contextItems(dispatch, pixel) {
    return [
        ContextItem("Edit", undefined, undefined)
            .addItem(ContextItem("Undo", "Key: CTRL + Z", () => dispatch(Commands.undo())))
            .addItem(ContextItem("Redo", "Key: CTRL + SHIFT + Z", () => dispatch(Commands.redo()))),

        ContextItem("Image", undefined, undefined)
            .addItem(fill(dispatch, pixel))
            .addItem(clear(dispatch, pixel))
            .addItem(mirror(dispatch, pixel))
            .addItem(flip(dispatch, pixel))
            .addItem(offset(dispatch, pixel)),
    ];
}

function registerCommands(dispatch) {
    CommandHandler.registerCommand(Commands.undo, () => {
        dispatch(Actions.History.backward());
    }, "KEYZ", [CommandHandler.CommandModifiers.CTRL]);
    CommandHandler.registerCommand(Commands.redo, () => {
        dispatch(Actions.History.forward());
    }, "KEYZ", [CommandHandler.CommandModifiers.CTRL, CommandHandler.CommandModifiers.SHIFT]);
}


const Functions = {
    contextItems,
    registerCommands,
};

export default Functions;
