import React from "react";
import ReactDOM from "react-dom";
import {
    BrowserRouter as Router,
} from "react-router-dom";
import { createStore, compose } from "redux";
import { Provider } from "react-redux";


import ScrollToTop from "common/scrollToTop";

import Header from "common/header";
import Footer from "common/footer";
import Theme from "theme";
import Routes from "routes";
import reducers from "reducers";
import Middleware from "middleware";

import "./index.css";
import { Actions } from "actions";
import * as serviceWorker from "./serviceWorker";


const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducers,
    composeEnhancers(
        Middleware,
    ));
store.dispatch(Actions.initialize());

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <Router>
                <ScrollToTop />
                <Theme>
                    <div className="App">
                        <Routes />
                    </div>
                </Theme>
            </Router>
        </Provider>
    </React.StrictMode>,
    document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
