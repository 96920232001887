import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ReactTooltip from "react-tooltip";

import { combineClasses } from "utils";
import { Actions } from "actions";

import styles from "./colorPalette.module.css";


const PALETTE = "PALETTE";

class ColorPalette extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            clicked: false,
        };

        this.color = this.color.bind(this);
        this.changeColor = this.changeColor.bind(this);
        this.resetClicked = this.resetClicked.bind(this);
    }

    componentDidUpdate() {
        // ReactTooltip.rebuild(); TODO Fix
    }

    render() {
        const {
            colors, direction, innerRef, className, style
        } = this.props;

        return (
            <div style={style} className={combineClasses(styles.palette, direction && styles.column, className)} ref={innerRef}>
                {colors.map(this.color)}
            </div>
        );
    }

    color(color, index) {
        const {
            hover, dataTip, dataFor, colorClass,
        } = this.props;

        const { clicked } = this.state;

        return (
            <div
                key={color + index}
                className={combineClasses(styles.color, hover && styles.hover, colorClass)}
                style={{ background: color }}
                onClick={() => this.changeColor(color)}
                data-tip={dataTip && dataTip(color, clicked === color)}
                data-for={dataFor}
            />
        );
    }

    changeColor(color) {
        const {
            functional, onClick, variable, setThemeVariable,
        } = this.props;

        this.setState({ clicked: color });

        clearTimeout(this.resetClickedInst);
        this.resetClickedInst = window.setTimeout(this.resetClicked, 2500);


        if (onClick) {
            console.log(color);
            onClick(color);
            return;
        }

        if (!functional) return;

        setThemeVariable(variable, { value: color }, PALETTE);
    }

    resetClicked() {
        this.setState({ clicked: undefined });
        console.log("reset");
    }
}

ColorPalette.defaultProps = {
    direction: false,
    hover: true,
    functional: true,
    onClick: undefined,
    innerRef: undefined,
    className: undefined,
    colorClass: undefined,
    style: undefined,
    dataTip: undefined,
    dataFor: undefined,
};

ColorPalette.propTypes = {
    colors: PropTypes.array.isRequired,
    variable: PropTypes.string.isRequired,
    direction: PropTypes.bool,
    hover: PropTypes.bool,
    functional: PropTypes.bool,
    innerRef: PropTypes.object,
    className: PropTypes.string,
    colorClass: PropTypes.string,
    style: PropTypes.string,

    dataTip: PropTypes.func,
    dataFor: PropTypes.string,

    onClick: PropTypes.func,
    setThemeVariable: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = {
    setThemeVariable: Actions.setThemeVariable,
};

export default connect(mapStateToProps, mapDispatchToProps)(ColorPalette);
