import React from "react";
import PropTypes from "prop-types";
import { cloneDeep } from 'lodash';

import { combineClasses, clamp } from "utils";
import { Actions } from "actions";

import styles from "./path.module.css";

const data = "M 10 10 C 20 20, 40 20, 50 10";
const radius = 3;

const pathColor = "chartreuse";
const controlColor = "cornflowerblue";

const PathPoint = Object.freeze({
    NONE: undefined,
    FROM: "FROM",
    TO: "TO",
    FROM_CONTROL: "FROM_CONTROL",
    TO_CONTROL: "TO_CONTROL"
});

class Path extends React.Component {

    constructor(props) {
        super(props);

        const defPath = {
            from: { x: 50, y: 50 },
            to: { x: 150, y: 50 },
            fromC: { x: 20, y: 20 },
            toC: { x: 40, y: 20 }
        };


        this.state = {
            path: defPath,
            lastPath: cloneDeep(defPath)
        }

        this.svg = React.createRef();

        this.onMouseDownFromC = this.onMouseDownFromC.bind(this);
        this.onMouseDownToC = this.onMouseDownToC.bind(this);
        this.onMouseDownFrom = this.onMouseDownFrom.bind(this);
        this.onMouseDownTo = this.onMouseDownTo.bind(this);
        this.onMouseMove = this.onMouseMove.bind(this);
        this.onMouseUp = this.onMouseUp.bind(this);
    }

    componentWillMount() {
        window.addEventListener("mouseup", this.onMouseUp);
        window.addEventListener("mousemove", this.onMouseMove);
    }

    componentWillUnmount() {
        window.removeEventListener("mouseup", this.onMouseUp);
        window.removeEventListener("mousemove", this.onMouseMove);
    }

    render() {
        let { width, height, selected } = this.props;

        return <svg ref={this.svg} width="100%" height="100%" viewBox={`0 0 ${width} ${height}`} xmlns="http://www.w3.org/2000/svg" className={combineClasses(selected && styles.selected)}>
            {this.createPath(this.state.lastPath)}
            {this.editPath(data)}
        </svg>;
    }

    editPath(data) {
        let { from, to, fromC, toC } = this.state.path;


        return <>
            {this.createPath(data)}

            <line x1={from.x} y1={from.y} x2={fromC.x} y2={fromC.y} stroke={controlColor} />
            <line x1={to.x} y1={to.y} x2={toC.x} y2={toC.y} stroke={controlColor} />

            <circle cx={from.x} cy={from.y} r={radius} stroke={pathColor} fill={pathColor} onMouseDown={this.onMouseDownFrom} />
            <circle cx={to.x} cy={to.y} r={radius} stroke={pathColor} fill={pathColor} onMouseDown={this.onMouseDownTo} />

            <circle cx={fromC.x} cy={fromC.y} r={radius} stroke={controlColor} fill="transparent" onMouseDown={this.onMouseDownFromC} />
            <circle cx={toC.x} cy={toC.y} r={radius} stroke={controlColor} fill="transparent" onMouseDown={this.onMouseDownToC} />
        </>;
    }

    createPath(data) {
        let { from, to, fromC, toC } = this.state.path;

        return <path d={`M ${from.x} ${from.y} C ${fromC.x} ${fromC.y}, ${toC.x} ${toC.y}, ${to.x} ${to.y}`}
            stroke="black" strokeWidth="3" fill="transparent" className={combineClasses(styles.selected)}
        />
    }




    currentPoint = PathPoint.NONE;

    onMouseDownFromC(event) {
        this.currentPoint = PathPoint.FROM_CONTROL;
    }

    onMouseDownToC(event) {
        this.currentPoint = PathPoint.TO_CONTROL;
    }

    onMouseDownFrom(event) {
        this.currentPoint = PathPoint.FROM;
    }

    onMouseDownTo(event) {
        this.currentPoint = PathPoint.TO;
    }

    onMouseMove(event) {
        if (!this.currentPoint) return;

        let { width, height } = this.props;

        let pt = this.svg.current.createSVGPoint()
        pt.x = event.clientX;
        pt.y = event.clientY;
        pt = pt.matrixTransform(this.svg.current.getScreenCTM().inverse());
        pt.x = clamp(pt.x, 0 + radius, width - radius);
        pt.y = clamp(pt.y, 0 + radius, height - radius);

        let path = cloneDeep(this.state.path);

        switch (this.currentPoint) {
            case PathPoint.FROM:
                this.movePathNode(path.from, path.fromC, pt);
                break;

            case PathPoint.TO:
                this.movePathNode(path.to, path.toC, pt);
                break;

            case PathPoint.FROM_CONTROL:
                this.moveControlNode(path.fromC, pt);
                break;

            case PathPoint.TO_CONTROL:
                this.moveControlNode(path.toC, pt);
                break;
        }


        this.setState({ path });
    }

    movePathNode(path, control, pt) {
        let dx = pt.x - path.x;
        let dy = pt.y - path.y;

        path.x = pt.x;
        path.y = pt.y;

        control.x += dx;
        control.y += dy;
    }

    moveControlNode(control, pt) {
        control.x = pt.x;
        control.y = pt.y;
    }

    onMouseUp(event) {
        if (this.currentPoint) this.setState({ lastPath: cloneDeep(this.state.path) });
        this.currentPoint = PathPoint.NONE;
    }

}


Path.defaultProps = {
    selected: true
};

Path.propTypes = {
    selected: PropTypes.bool,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
};

export default Path;