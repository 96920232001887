import React from "react";
import PropTypes from "prop-types";

import { combineClasses } from "utils";
import { Actions } from "actions";

import Checkbox from "components/checkbox";
import { Commands } from "commands";
import styles from "./tools.module.css";
import Tools from ".";


class Toolbar extends React.Component {
    constructor(props) {
        super(props);

        this.createTool = this.createTool.bind(this);
    }

    render() {
        return (
            <>
                <div className={combineClasses(styles.toolbar)}>
                    {Tools.map(this.createTool)}
                </div>
            </>
        );
    }

    createTool(tool, index) {
        const { dispatch, active } = this.props;

        return (
            // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
            <img
                onDragStart={(e) => { e.preventDefault(); return false; }}
                src={`/cursor/pixel/${tool.icon}.png`}
                className={combineClasses(styles.tool, active && active.name === tool.name && styles.active)}
                onClick={() => dispatch(tool.command())}
            />
        );
    }
}


Toolbar.defaultProps = {
};

Toolbar.propTypes = {
    dispatch: PropTypes.func.isRequired,
    active: PropTypes.any.isRequired,
};

export default Toolbar;
