import React, { memo } from "react";
import PropTypes from "prop-types";

import styles from "./status.module.css";
import Card from "../../components/card";
import Bar from "../../components/bar";


class Memory extends React.Component {
    render() {
        const {
            memory,
        } = this.props;

        let total = 0;
        let free = 0;
        let available = 0;
        let used = 0;
        let buffers = 0;
        let cached = 0;
        let shared = 0;

        if (memory) {
            total = parseInt(memory.memtotal.replace("kB", ""));
            free = parseInt(memory.memfree.replace("kB", ""));
            buffers = parseInt(memory.buffers.replace("kB", ""));
            cached = parseInt(memory.cached.replace("kB", "")) - buffers;
            shared = parseInt(memory.shmem.replace("kB", ""));
            available = parseInt(memory.memavailable.replace("kB", "")) - free;

            used = total - free - buffers - cached - shared;
        }

        const blocks = [
            { size: used, title: "Used", color: "var(--mem-used)" },
            { size: buffers, title: "Buffers", color: "var(--mem-buffers)" },
            { size: cached, title: "Cached", color: "var(--mem-cached)" },
            { size: shared, title: "Shared", color: "var(--mem-shared)" },
            { size: free, title: "Free", color: "var(--mem-free)" },
        ];

        return (
            <Card header="Memory" width="wide">
                <Bar
                    total={total}
                    legend
                    display={(piece, total) => `(${kbToReadable(piece.size)} / ${kbToReadable(total)})`}
                >
                    {blocks}
                </Bar>
            </Card>
        );
    }
}

function kbToReadable(size) {
    const gb = size / 1048576;
    const mb = size / 1024;
    if (gb > 1) return `${gb.toFixed(2)}GB`;
    if (mb > 1) return `${mb.toFixed(0)}MB`;
    return `${size.toFixed(0)}kB`;
}

Memory.defaultProps = {

};

Memory.propTypes = {
    total: PropTypes.number.isRequired,
    used: PropTypes.number.isRequired,
    buffers: PropTypes.number.isRequired,
    cache: PropTypes.number.isRequired,
};

export default Memory;
