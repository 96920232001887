import chroma from "chroma-js";
import { Commands } from "commands";
import { getColor, setColor, toArrColor } from "./common";

const modify = {
    name: "Modify",
    icon: "modify",
    keybinding: "KEYM",
    command: Commands.modify,
    primary: (x, y, colors, data, modifiers) => {
        drawModify(x, y, 1, data, modifiers.mirror);
    },
    secondary: (x, y, colors, data, modifiers) => {
        drawModify(x, y, -1, data, modifiers.mirror);
    },
    options: {
        mirror: true,
        extra: [
            {
                name: "Mirror",
                dropdown: [
                    { name: "Top to Bottom", action: (dispatch) => dispatch(Commands.mirror("Y-+")) },
                    { name: "Bottom to Top", action: (dispatch) => dispatch(Commands.mirror("Y+-")) },
                    { name: "Left to Right", action: (dispatch) => dispatch(Commands.mirror("X-+")) },
                    { name: "Right to Left", action: (dispatch) => dispatch(Commands.mirror("X+-")) },
                ],
            },
        ],
    },
};


function drawModify(x, y, strength, data, mirror) {
    modifyPixel(x, y, strength, data);

    if (mirror.x) modifyPixel(data.width - x - 1, y, strength, data);
    if (mirror.y) modifyPixel(x, data.height - y - 1, strength, data);
    if (mirror.x && mirror.y) modifyPixel(data.width - x - 1, data.height - y - 1, strength, data);
}

function modifyPixel(x, y, strength, imageData) {
    const { data, width, height } = imageData;

    const inColor = getColor(x, y, data, width);
    if (inColor[3] <= 0) return;

    let modColor = chroma(inColor[0], inColor[1], inColor[2]);
    modColor = modColor.brighten(strength * 0.5);
    const color = toArrColor(modColor);
    setColor(x, y, data, width, color);
}

export default modify;
