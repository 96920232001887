import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { ReactTinyLink } from "react-tiny-link";

import { combineClasses } from "utils";
import { Actions } from "actions";

import FolderBrowser, { Folder, File } from "components/folderBrowser";
import Card from "components/card";
import Button from "components/button";
import styles from "./todo.module.css";


class Todo extends React.Component {
    constructor(props) {
        super(props);

        this.renderFolder = this.renderFolder.bind(this);
        this.addTask = this.addTask.bind(this);
        this.clearTasks = this.clearTasks.bind(this);
    }

    render() {
        const { items } = this.props;
        /*
        const items = [
            File("test1", "test"),
            File("test2", "test"),
            File("test2", "test"),
            File("test2", "test"),
            Folder("folder1", "data", [
                File("test1.1", "data1"),
                File("test1.2", "data1"),
            ]),
        ];
        */

        return (
            <div className="page">
                <div className={combineClasses(styles.list)}>
                    <h3>Items</h3>
                    <FolderBrowser
                        showPath={false}
                        items={items}
                        renderFile={this.renderFile}
                        renderFolder={this.renderFolder}
                    />
                </div>
                <div className={combineClasses(styles.buttons)}>
                    <Button className={combineClasses(styles.button)} onClick={this.addTask} text="Add" />
                    <Button className={combineClasses(styles.button)} onClick={this.clearTasks} text="Clear" />
                </div>
            </div>
        );
    }

    addTask() {
        const { addItem } = this.props;

        addItem(File("test", { text: "This is some sample text", links: ["https://oldschool.runescape.wiki/w/Trailblazer_League/Tasks#Morytania"] }));
    }

    clearTasks() {
        const { setCollection } = this.props;

        setCollection([]);
    }

    /* Renders */

    renderFolder(name, data, items, open) {
        return (
            <div className={combineClasses(styles.folder)}>
                <Card
                    contentClass={combineClasses(styles.item)}
                    onClick={() => {
                        data.open = true;
                    }}
                >
                    <span>{`${name} (Folder)`}</span>
                    <div className={combineClasses(styles.icon)} />
                </Card>
                {data.open && items.filter((item) => item.type === "FILE").map((item) => this.renderFile(item.name, item.data))}
            </div>
        );
    }

    renderFile(name, data) {
        return (
            <Card contentClass={combineClasses(styles.item)}>
                <span>{`${name} (Item)`}</span>
                <div className={combineClasses(styles.icon)} />
                {data.text && <p>{data.text}</p>}
                <div className={combineClasses(styles.links)}>
                    {
                        data.links && data.links.length > 0 && data.links.map((link) => (
                            <ReactTinyLink
                                cardSize="small"
                                showGraphic
                                maxLine={3}
                                minLine={3}
                                url={link}
                            />
                        ))
                    }
                </div>
            </Card>
        );
    }
}


Todo.defaultProps = {
};

Todo.propTypes = {
    items: PropTypes.array.isRequired,

    addItem: PropTypes.func.isRequired,
    removeItem: PropTypes.func.isRequired,
    modifyItem: PropTypes.func.isRequired,
    setCollection: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    items: state.todo.items,
});

const mapDispatchToProps = {
    addItem: Actions.addItem,
    removeItem: Actions.removeItem,
    modifyItem: Actions.modifyItem,
    setCollection: Actions.setCollection,
};

export default connect(mapStateToProps, mapDispatchToProps)(Todo);
