import React from "react";
import PropTypes from "prop-types";

import { combineClasses } from "utils";
import { Actions } from "actions";
import Checkbox from "components/checkbox";
import ColorInput from "components/colorInput";
import Window from "components/window";
import Button from "components/button";
import { ToolMapper } from "./index";
import ColorPicker from "../colorPicker";
import color from "../popup/color";

import styles from "./tools.module.css";


const TOOL_OPTIONS_ID = "tool_options";


class ToolOptions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: undefined,
        };

        this.toolOptions = React.createRef();

        this.checkClose = this.checkClose.bind(this);
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.checkClose);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.checkClose);
    }

    render() {
        const {
            mirror, dithering, dispatch, active, colors,
        } = this.props;

        return (
            <div className={combineClasses(styles.toolOptions)} ref={this.toolOptions} id={TOOL_OPTIONS_ID}>
                <span>Options</span>
                {this.optColor(dispatch, colors)}
                <Window visible={false}>
                    <ColorPicker />
                </Window>
                {active && active.options.mirror && this.optMirror(dispatch, mirror)}
                {active && active.options.dithering && this.optDithering(dispatch, dithering)}
                {active && active.options.extra.map((option) => this.optExtra(dispatch, option))}

                <Button className={combineClasses(styles.save)} text="Save" onClick={() => {}} />
            </div>
        );
    }

    checkClose(e) {
        if (!this.toolOptions.current) return;
        const opts = this.toolOptions.current;
        let current = e.target;

        while (current !== null) {
            if (current === opts) {
                return;
            }
            current = current.parentElement;
        }
        this.setState({ open: undefined });
    }

    optMirror(dispatch, mirror) {
        return (
            <>
                <separator />
                <div className={styles.mirror}>
                    <Checkbox name="Mirror X" checked={mirror.x} onClick={() => dispatch(Actions.Tool.setMirror(!mirror.x, undefined))} />
                    <Checkbox name="Mirror Y" checked={mirror.y} onClick={() => dispatch(Actions.Tool.setMirror(undefined, !mirror.y))} />
                </div>
            </>
        );
    }

    optDithering(dispatch, dithering) {
        return (
            <>
                <separator />
                <div className={styles.dithering}>
                    <Checkbox name="Dithering" checked={dithering} onClick={() => dispatch(Actions.Tool.setDithering(!dithering))} />
                </div>
            </>
        );
    }

    optColor(dispatch, colors) {
        const { open } = this.state;
        const name = "color";

        return (
            <>
                <separator />
                <div
                    className={combineClasses(styles.option, name === open && styles.active)}
                    onClick={() => {
                        this.setState({ open: name });
                    }}
                >
                    <div className={combineClasses(styles.activeColors)}>
                        <div><div className={combineClasses(styles.colorPrimary)} style={{ background: colors.primary }} /></div>
                        <div className={combineClasses(styles.colorSecondary)} style={{ background: colors.secondary }} />
                    </div>
                    <>
                        <div className={combineClasses(styles.dropdownArrow)} />
                        <div className={combineClasses(styles.dropdown)}>
                            <ColorPicker color={colors.picker || colors.primary} onChange={(color) => dispatch(Actions.Tool.setColor(color.hex, undefined, color))} />
                        </div>
                    </>
                </div>
            </>
        );
    }


    optExtra(dispatch, option) {
        const { open } = this.state;
        const { name, dropdown, action } = option;
        return (
            <>
                <separator />
                <div
                    className={combineClasses(styles.option, name === open && styles.active)}
                    onClick={() => {
                        this.setState({ open: open === name ? undefined : name });
                        if (!dropdown) action(dispatch);
                    }}
                >
                    <span>{name}</span>
                    {dropdown && (
                        <>
                            <div className={combineClasses(styles.dropdownArrow)} />
                            <div className={combineClasses(styles.dropdown)}>
                                {dropdown.map((item) => (
                                    <div
                                        className={combineClasses(styles.dropdownItem)}
                                        onClick={() => {
                                            item.action(dispatch);
                                            this.setState({ open: undefined });
                                        }}
                                    >
                                        {item.name}
                                    </div>
                                ))}
                            </div>
                        </>
                    )}
                </div>
            </>
        );
    }
}

ToolOptions.defaultProps = {
};

ToolOptions.propTypes = {
    dispatch: PropTypes.func.isRequired,
    mirror: PropTypes.any.isRequired,
    dithering: PropTypes.any.isRequired,
    active: PropTypes.any.isRequired,
    colors: PropTypes.object.isRequired,
};


export default ToolOptions;
