export const Types = {
    ADD_ITEM: "ADD_ITEM",
    REMOVE_ITEM: "REMOVE_ITEM",
    MODIFY_ITEM: "MODIFY_ITEM",
    SET_COLLECTION: "SET_COLLECTION",
};

export const Actions = {
    addItem,
    modifyItem,
    removeItem,
    setCollection,
};


export function addItem(item, path = undefined) {
    return {
        type: Types.ADD_ITEM,
        item,
        path,
    };
}

export function modifyItem(key) {
    return {
        type: Types.MODIFY_Item,
        key,
    };
}

export function removeItem(key) {
    return {
        type: Types.REMOVE_Item,
        key,
    };
}


export function setCollection(collection) {
    return {
        type: Types.SET_COLLECTION,
        collection,
    };
}
