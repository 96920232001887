import { Commands, CommandTypes } from "commands";
import { registerCommandHandler } from "middleware/command";
import { ContextItem } from "routes/editor/contextMenu";
import { getColor, setColor } from "../tools/common";

function mirrorX(pixel, center, flip) {
    const {
        colors, canvasData, setCanvasData, pushHistory, dispatch,
    } = pixel.props;
    const { data, width, height } = canvasData;

    pushHistory();

    if (center === undefined) center = width / 2;

    for (let y = 0; y < height; y++) {
        let left = center - 1;
        let right = center;
        while (left >= 0 && right < width) {
            if (flip) {
                setColor(left, y, data, width, getColor(right, y, data, width));
            } else {
                setColor(right, y, data, width, getColor(left, y, data, width));
            }
            ++right;
            --left;
        }
    }
    setCanvasData(canvasData);
}

function mirrorY(pixel, center, flip) {
    const {
        colors, canvasData, setCanvasData, pushHistory, dispatch,
    } = pixel.props;
    const { data, width, height } = canvasData;

    pushHistory();

    if (center === undefined) center = height / 2;

    for (let x = 0; x < width; x++) {
        let bottom = center - 1;
        let top = center;
        while (bottom >= 0 && top < height) {
            if (flip) {
                setColor(x, bottom, data, width, getColor(x, top, data, width));
            } else {
                setColor(x, top, data, width, getColor(x, bottom, data, width));
            }
            ++top;
            --bottom;
        }
    }
    setCanvasData(canvasData);
}

function mirror(dispatch, pixel) {
    registerCommandHandler(CommandTypes.COMMAND_FUNC_MIRROR, (action) => {
        switch (action.direction) {
        case "Y+-":
            mirrorY(pixel, undefined, true);
            return;
        case "Y-+":
            mirrorY(pixel);
            return;
        case "X+-":
            mirrorX(pixel, undefined, true);
            return;
        case "X-+": default:
            mirrorX(pixel);
        }
    });


    return ContextItem("Mirror", undefined, undefined)
        .addItem(ContextItem("Left to Right", "-X to +X", () => dispatch(Commands.mirror("X-+"))))
        .addItem(ContextItem("Top to Bottom", "-Y to +Y", () => dispatch(Commands.mirror("Y-+"))));
}

export default mirror;
