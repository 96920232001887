import React from "react";

import PatternBackground from "components/patternBackground";
import styles from "./home.module.css";


class Home extends React.Component {
    render() {
        return (
            <div style={{ width: "100%", height: "100vh" }}>
                <PatternBackground />
            </div>
            // <div className="page">
            //     <PatternBackground/>
            //     <h1>Home</h1>
            // </div>
        );
    }
}

export default Home;
