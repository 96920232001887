import React from "react";
import PropTypes from "prop-types";

import ColorPicker from "components/colorPicker";

import styles from "./theme.module.css";

function card({
    name, value, category, type,
}) {
    return (
        <div className={styles.colorCard}>
            <span>{name}</span>
            <div className={styles.colorContainer}>
                <div className={styles.color} style={{ backgroundColor: value }}>
                    <span>{value}</span>
                </div>
            </div>
        </div>
    );
}

card.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
};

export default {
    card,
    visualizer: ColorPicker,
};
