import React from "react";

function split(colors, onClick, onMouseUp) {
    const [c1, c2] = colors;
    return (
        <g>
            <path fill={c1} onClick={() => onClick(c1)} onMouseUp={() => onMouseUp(0)} d="M23.46 25.391a34.004 34.004 0 00-22.328-9.248v-15a49 49 0 0132.934 13.641z" />
            <path fill={c2} onClick={() => onClick(c2)} onMouseUp={() => onMouseUp(1)} d="M6.46 42.39l15.585-15.584a32.005 32.005 0 00-20.913-8.662v22.039a10 10 0 015.329 2.207z" />
        </g>
    );
}

export default split;
