import React from "react";
import PropTypes from "prop-types";

import { combineClasses } from "utils";

import styles from "./selector.module.css";


class Selector extends React.Component {
    constructor(props) {
        super(props);

        const def = props.default || props.options[0];
        this.state = {
            selected: def.label,
        };

        this.renderOption = this.renderOption.bind(this);
        this.select = this.select.bind(this);

        this.select(def, false);
    }

    render() {
        const { options, display } = this.props;
        const { selected } = this.state;
        const displayFunction = display || this.renderOption;

        return (
            <div className={styles.selector}>
                {options.map((option, index) => displayFunction(
                    option,
                    selected === option.label,
                    () => this.select(option),
                    index === 0,
                    index === (options.length - 1),
                ))}
            </div>
        );
    }

    renderOption(option, selected, onSelect, first, last) {
        const { label } = option;

        return (
            <div role="button"
                key={label}
                className={combineClasses(
                    styles.option,
                    selected && styles.selected,
                    first && styles.first,
                    last && styles.last,
                )}
                onClick={onSelect}
            >
                <span>{label}</span>
            </div>
        );
    }

    select(option, setState = true) {
        const { onSelect } = this.props;
        if (setState) {
            this.setState({
                selected: option.label,
            });
        }
        if (onSelect) onSelect(option);
    }
}

Selector.defaultProps = {
    default: undefined,
    display: undefined,
    onSelect: undefined,
};

Selector.propTypes = {
    options: PropTypes.array.isRequired,
    default: PropTypes.object,
    display: PropTypes.func,
    onSelect: PropTypes.func,
};

export default Selector;
