export const Types = {
    SET_PERSISTENT_VALUE: "SET_PERSISTENT_VALUE",
};

export const Actions = {
    setPersistentValue,
};


export function setPersistentValue(path, value) {
    return {
        type: Types.SET_PERSISTENT_VALUE,
        path,
        value,
    };
}
