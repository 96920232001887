/* eslint-disable prefer-destructuring */
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import chroma from "chroma-js";

import { clamp, combineClasses, iniToJson } from "utils";
import { Actions } from "actions";

import ColorPalette from "components/colorPalette";
import { CommandHandler, Commands, CommandTypes } from "commands";
import { isCommandActive } from "components/commandListener";
import { redraw, setCanvasData } from "actions/editor";
import preload from "middleware/preload";
import ColorInput from "components/colorInput";
import TextInput from "components/textInput";
import Button from "components/button";
import Window from "components/window";
import { ContextItem } from "../contextMenu";
import { toArrColor } from "./tools/common";

import Tools, { ToolMapper } from "./tools";
import Functions from "./functions";
import Resize from "./resize";
import Palette from "./palette";
import Layers from "./layers";
import Toolbar from "./tools/toolbar";
import ToolOptions from "./tools/toolOptions";
import Export from "./popup/export";
import Import from "./popup/import";
import PalettePopup from "./popup/palette";

import styles from "./pixel.module.css";
import Color from "./popup/color";
import Separator from "./separator";


class Pixel extends React.Component {
    constructor(props) {
        super(props);

        this.lastDataIndex = -1;

        this.canvas = React.createRef();
        this.previewCanvas = React.createRef();

        this.onMouseDown = this.onMouseDown.bind(this);
        this.onMouseMove = this.onMouseMove.bind(this);
        this.onMouseUp = this.onMouseUp.bind(this);
        this.onContextMenu = this.onContextMenu.bind(this);
        this.onScrollWheel = this.onScrollWheel.bind(this);

        this.resizeImage = this.resizeImage.bind(this);

        this.state = {
            name: "image",
            zoom: 75,
            pan: {
                x: 0,
                y: 0,
            },
            grid: false,
        };
    }

    componentDidMount() {
        document.addEventListener("mousemove", this.onMouseMove);
        document.addEventListener("mouseup", this.onMouseUp);
        document.addEventListener("contextmenu", this.onContextMenu, false);
        document.addEventListener("wheel", this.onScrollWheel);

        this.init();

        CommandHandler.enableCommandInput();
    }

    componentWillUnmount() {
        document.removeEventListener("mousemove", this.onMouseMove);
        document.removeEventListener("mouseup", this.onMouseUp);
        document.removeEventListener("contextmenu", this.onContextMenu);
        document.removeEventListener("wheel", this.onScrollWheel);

        CommandHandler.disableCommandInput();
    }

    render() {
        const {
            primaryColor, secondaryColor, colors,
            mirror, dithering,
            canvasData,
            redrawIndex,
            layers,
            activeLayer,
            setColor,
            setCanvasData,
            redraw,
            dispatch,
        } = this.props;
        const { name, pan, grid } = this.state;
        // eslint-disable-next-line react/destructuring-assignment
        const primaryTool = ToolMapper(this.props.primaryTool);
        // eslint-disable-next-line react/destructuring-assignment
        const zoom = this.state.zoom / 100.0;

        const width = canvasData ? canvasData.width : 32;
        const height = canvasData ? canvasData.height : 32;
        const aspectRatio = width / height;

        const canvas = this.canvas.current;
        const canvasWrapperBounds = canvas ? canvas.parentElement.parentElement.getBoundingClientRect() : { width: 0, height: 0 };
        const canvasBounds = { height: canvasWrapperBounds.height * zoom };
        const gridSize = 1;

        // TODO Only when updated.

        if (canvasData === null) {
            if (canvas) {
                const context = canvas.getContext("2d");
                context.clearRect(0, 0, canvas.width, canvas.height);
                const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
                setCanvasData(imageData);
            }
        } else if (true || redrawIndex !== this.lastDataIndex) {
            console.log("redraw");
            this.lastDataIndex = redrawIndex;
            const context = canvas.getContext("2d");
            // TODO Blend all layers.
            context.clearRect(0, 0, canvas.width, canvas.height);
            const cImgData = context.getImageData(0, 0, canvas.width, canvas.height);
            if (cImgData) {
                let blendData = cImgData;
                layers.forEach((l) => {
                    if (l.data === undefined || l.data.current === null || !l.visible) return;
                    blendData = blendCanvasData(blendData, blendData, l.data.current);
                });

                drawOnCanvas(canvas, blendData, ["ddd", "999"], gridSize);
                drawOnCanvas(this.previewCanvas.current, blendData, ["ddd3", "9993"], gridSize * 4);
            }
        }


        let cursor = "default";
        const cursorStyle = "pixel";
        if (primaryTool) {
            cursor = primaryTool.icon;
        }

        const preview = {
            width: Math.min(width / height, 1) * 100,
            height: Math.min(height / width, 1) * 100,
        };

        return (
            <>
                <div className={styles.pixel} style={{ cursor: `url(cursor/${cursorStyle}/${cursor}.png) 0 32, auto` }}>
                    <ToolOptions dispatch={dispatch} mirror={mirror} dithering={dithering} active={primaryTool} colors={colors} />
                    <panel className={combineClasses(styles.panelLeft)}>
                        {/* <ColorPalette colors={colors} onClick={setColor} colorClass={styles.paletteColor} hover={false} /> */}
                        <Toolbar dispatch={dispatch} active={primaryTool} />
                    </panel>
                    <div className={combineClasses(styles.canvasContainer)}>
                        <div
                            className={combineClasses(styles.canvasWrapper)}
                            style={{
                                marginTop: pan.y + (canvasWrapperBounds.height - canvasBounds.height) / 2,
                                marginLeft: pan.x + (canvasWrapperBounds.width - canvasBounds.height * aspectRatio) / 2,
                            }}
                        >
                            <canvas
                                className={combineClasses(styles.canvas)}
                                width={width}
                                height={height}
                                style={{
                                    height: canvasBounds.height,
                                }}
                                onMouseDown={this.onMouseDown}
                                ref={this.canvas}
                            />
                            {primaryTool && primaryTool.options.mirror && mirror.x && <div className={combineClasses(styles.mirrorX)} />}
                            {primaryTool && primaryTool.options.mirror && mirror.y && <div className={combineClasses(styles.mirrorY)} />}
                            {grid && <div className={combineClasses(styles.gridOverlay)} />}
                        </div>
                        {/* <PalettePopup /> */}
                    </div>
                    <panel className={combineClasses(styles.panelRight)}>
                        <div className={combineClasses(styles.previewCanvasWrapper)}>
                            <canvas
                                className={combineClasses(styles.previewCanvas)}
                                width={width}
                                height={height}
                                style={{ width: `${preview.width}%`, height: `${preview.height}%` }}
                                ref={this.previewCanvas}
                            />
                        </div>
                        <Palette />
                        <Layers />
                        <div className={combineClasses(styles.grid2x2)}>
                            <Separator header="file" />
                            <Separator header="resize" />
                            <div className={combineClasses(styles.file)}>
                                <Import dispatch={dispatch} />
                                <Export canvasData={canvasData} />
                            </div>
                            <Resize resize={this.resizeImage} width={width} height={height} />
                        </div>
                    </panel>
                </div>
            </>
        );
    }

    init() {
        this.registerCommands();
    }

    registerCommands() {
        const {
            setItems, setColor, setTool, dispatch,
        } = this.props;

        const cursorStyle = "pixel";

        CommandHandler.registerCommandBinding(Commands.pan, "SPACE");

        const toolItem = ContextItem("Tool", undefined, undefined);
        Tools.forEach((item) => {
            toolItem.addItem(ContextItem(item.name, `Key: ${item.keybinding}`, () => {
                dispatch(item.command());
            }, undefined, `/cursor/${cursorStyle}/${item.icon}.png`));
            preload(`cursor/${cursorStyle}/${item.icon}`);
            CommandHandler.registerCommand(item.command, () => {
                setTool(item);
            }, item.keybinding);
        });

        const items = [
            ContextItem("Color", undefined, undefined)
                // eslint-disable-next-line react/destructuring-assignment
                .addItem(ContextItem("Swap", "Swap primary and secondary colors", () => dispatch(Commands.swapColors())))
                .addItem(ContextItem("-", undefined, undefined)),
            // ContextItem("Edit", undefined, undefined)
            //     .addItem(ContextItem("Undo", "Key: CTRL + Z", () => dispatch(Commands.undo())))
            //     .addItem(ContextItem("Redo", "Key: CTRL + SHIFT + Z", () => dispatch(Commands.redo()))),
            ...Functions.contextItems(dispatch, this),
            toolItem,
        ];


        CommandHandler.registerCommand(Commands.showGrid, () => {
            // eslint-disable-next-line react/destructuring-assignment
            const { grid } = this.state;
            this.setState({ grid: !grid });
        }, "TAB");

        CommandHandler.registerCommand(Commands.swapColors, () => {
            // eslint-disable-next-line react/destructuring-assignment
            setColor(this.props.secondaryColor, this.props.primaryColor);
        }, "KEYX");
        Functions.registerCommands(dispatch);

        // const { undo, redo } = this.props;
        // CommandHandler.registerCommand(Commands.undo, () => {
        //     undo();
        // }, "KEYZ", [CommandHandler.CommandModifiers.CTRL]);
        // CommandHandler.registerCommand(Commands.redo, () => {
        //     redo();
        // }, "KEYZ", [CommandHandler.CommandModifiers.CTRL, CommandHandler.CommandModifiers.SHIFT]);

        setItems(items);
        setTool(Tools[0]);
    }

    /*
    TODO
    color wheel on keybinding
    */

    // Image Operations //
    fillImage(color) {
        const { canvasData, setCanvasData, pushHistory } = this.props;
        pushHistory();

        const ac = toArrColor(color);
        const length = canvasData.width * canvasData.height * 4;

        for (let i = 0; i < length; i += 4) {
            canvasData.data[i + 0] = ac[0];
            canvasData.data[i + 1] = ac[1];
            canvasData.data[i + 2] = ac[2];
            canvasData.data[i + 3] = ac[3];
        }

        setCanvasData(canvasData);
    }

    resizeImage(width, height) {
        const { setCanvasData, resetHistory } = this.props;

        const canvas = this.canvas.current;
        canvas.width = width;
        canvas.height = height;
        const imageData = canvas.getContext("2d").createImageData(width, height);

        setCanvasData(imageData);
        resetHistory();
        setTimeout(() => {
            this.fillImage("#0000");
        }, 0);
    }

    // Events //
    // lastPixel = undefined; //{ x: undefined, y: undefined };
    onMouseDown(event) {
        if (!CommandHandler.isCommandInputEnabled()) return;

        if (!(event.buttons === undefined
            ? event.which === 1
            : event.buttons === 1)) return;

        const { x, y } = this.getMousePos(event);
        // eslint-disable-next-line no-restricted-globals
        if (x === undefined || y === undefined || isNaN(x) || isNaN(y)) return;

        const { pushHistory } = this.props;
        pushHistory();

        const {
            colors, primaryTool, mirror, dithering, canvasData, dispatch,
        } = this.props;

        ToolMapper(primaryTool).primary(x, y, colors, canvasData, { mirror, dithering }, dispatch);
        this.lastPixel = { x, y };
    }

    onContextMenu(event) {
        if (!CommandHandler.isCommandInputEnabled()) return;

        const { x, y } = this.getMousePos(event);
        // eslint-disable-next-line no-restricted-globals
        if (x === undefined || y === undefined || isNaN(x) || isNaN(y)) return;

        const { pushHistory } = this.props;
        pushHistory();

        const {
            colors, primaryTool, mirror, dithering, canvasData, dispatch,
        } = this.props;

        ToolMapper(primaryTool).secondary(x, y, colors, canvasData, { mirror, dithering }, dispatch);
        event.preventDefault();
        return false;
    }

    onMouseMove(event) {
        if (!CommandHandler.isCommandInputEnabled()) return;
        // TODO Check if r-click
        const {
            pan,
        } = this.state;


        if (isCommandActive(CommandTypes.COMMAND_PAN)) {
            const canvas = this.canvas.current;
            const canvasWrapperBounds = canvas ? canvas.parentElement.parentElement.getBoundingClientRect() : { width: 0, height: 0 };
            const canvasBounds = canvas ? canvas.getBoundingClientRect() : { width: 0, height: 0 };

            const limX = (canvasWrapperBounds.width + canvasBounds.width) / 2 - 50;
            const limY = (canvasWrapperBounds.height + canvasBounds.height) / 2 - 50;

            this.setState({
                pan: {
                    x: clamp(pan.x + event.movementX, -limX, limX),
                    y: clamp(pan.y + event.movementY, -limY, limY),
                },
            });
            return;
        }

        if (!(event.buttons === undefined
            ? event.which === 1
            : event.buttons === 1)) return;

        const {
            colors, primaryTool, canvasData, mirror, dithering, dispatch, redraw,
        } = this.props;

        const { x, y, oob } = this.getMousePos(event, true);

        if (oob && this.lastPixel === undefined) return;

        // eslint-disable-next-line no-restricted-globals
        if (x === undefined || y === undefined || isNaN(x) || isNaN(y)) {
            this.lastPixel = undefined;
            return;
        }

        const { width, height } = canvasData;

        const ptool = ToolMapper(primaryTool);
        if (!oob) ptool.primary(x, y, colors, canvasData, { mirror, dithering }, dispatch);
        if (this.lastPixel !== undefined) {
            drawLine(this.lastPixel.x, this.lastPixel.y, x, y, (x, y) => {
                // eslint-disable-next-line no-restricted-globals
                if (x === undefined || y === undefined || isNaN(x) || isNaN(y)) return;
                if (x < 0 || x >= width || y < 0 || y >= height) return;
                ptool.primary(x, y, colors, canvasData, { mirror, dithering }, dispatch);
                redraw();
            });
        }

        // setCanvasData(canvasData, false);
        // ctx.putImageData(imageData, 0, 0);
        if (oob) {
            this.lastPixel = undefined;
        } else {
            this.lastPixel = { x, y };
        }
    }

    onMouseUp(event) {
        if (!CommandHandler.isCommandInputEnabled()) return;

        this.lastPixel = undefined;
    }

    onScrollWheel(event) {
        // TODO Check why fucked in chrome...
        if (!CommandHandler.isCommandInputEnabled()) return;

        const min = 5;
        const max = 200;
        const sensitivity = 0.75;


        const { zoom, pan } = this.state;

        let scroll = event.deltaY;

        if (scroll > 0) {
            scroll = 1;
        } else {
            scroll = -1;
        }

        let multiplier = scroll * sensitivity;
        if (multiplier < 0) multiplier = -1 / multiplier;

        const canvas = this.canvas.current;
        const canvasWrapperBounds = canvas ? canvas.parentElement.parentElement.getBoundingClientRect() : { width: 0, height: 0 };
        const canvasBounds = canvas ? canvas.getBoundingClientRect() : { width: 0, height: 0 };

        const limX = (canvasWrapperBounds.width + canvasBounds.width) / 2 - 50;
        const limY = (canvasWrapperBounds.height + canvasBounds.height) / 2 - 50;
        // TODO FIX not paning into view.
        this.setState({
            zoom: Math.min(Math.max(min, zoom * multiplier), max),
            pan: {
                x: clamp(pan.x, -limX, limX),
                y: clamp(pan.y, -limY, limY),
            },
        });

        event.preventDefault(true);
        return false;
    }

    getMousePos(event, oob = false) {
        const canvas = this.canvas.current;
        const rect = canvas.getBoundingClientRect(); // abs. size of element
        const scaleX = canvas.width / rect.width; // relationship bitmap vs. element for X
        const scaleY = canvas.height / rect.height; // relationship bitmap vs. element for Y

        const result = {
            x: Math.floor((event.clientX - rect.left) * scaleX), // scale mouse coordinates after they have
            y: Math.floor((event.clientY - rect.top) * scaleY), // been adjusted to be relative to element
        };

        if (result.x < 0 || result.x >= canvas.width || result.y < 0 || result.y >= canvas.height) {
            if (oob) return { ...result, oob: true };
            return {};
        }

        return result;
    }
}


Pixel.defaultProps = {
    color: "#880000",
    width: 32,
    height: 32,
};

Pixel.propTypes = {
    color: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,

    // State
    colors: PropTypes.object.isRequired,
    primaryColor: PropTypes.string.isRequired,
    secondaryColor: PropTypes.string.isRequired,
    primaryTool: PropTypes.any.isRequired,
    secondaryTool: PropTypes.any.isRequired,
    mirror: PropTypes.any.isRequired,
    dithering: PropTypes.any.isRequired,
    canvasData: PropTypes.any.isRequired,
    redrawIndex: PropTypes.number.isRequired,
    layers: PropTypes.any.isRequired,
    activeLayer: PropTypes.any.isRequired,

    // Dispatch
    setItems: PropTypes.func.isRequired,
    setColor: PropTypes.func.isRequired,
    setTool: PropTypes.func.isRequired,
    setCanvasData: PropTypes.func.isRequired,
    pushHistory: PropTypes.func.isRequired,
    undo: PropTypes.func.isRequired,
    redo: PropTypes.func.isRequired,
    resetHistory: PropTypes.func.isRequired,
    redraw: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    colors: state.editor.tool.color,
    primaryColor: state.editor.tool.color.primary,
    secondaryColor: state.editor.tool.color.secondary,

    primaryTool: state.editor.tool.tool.primary,
    secondaryTool: state.editor.tool.tool.secondary,

    mirror: state.editor.tool.mirror,
    dithering: state.editor.tool.dithering,

    canvasData: state.editor.canvas.current,
    redrawIndex: state.editor.redrawIndex,
    layers: state.editor.layers,
    activeLayer: state.editor.activeLayer,
    canUndo: state.editor.canvas.past.length !== 0,
    canRedo: state.editor.canvas.future.length !== 0,
});

const mapDispatchToProps = {
    setItems: Actions.ContextMenu.setItems,
    setColor: Actions.Tool.setColor,
    setTool: Actions.Tool.setTool,
    setCanvasData: Actions.setCanvasData,
    pushHistory: Actions.History.push,
    undo: Actions.History.backward,
    redo: Actions.History.forward,
    resetHistory: Actions.History.reset,
    redraw: Actions.redraw,
    dispatch: (action) => action,
};

export default connect(mapStateToProps, mapDispatchToProps)(Pixel);


function drawLine(x0, y0, x1, y1, plot) {
    if (Math.abs(y1 - y0) < Math.abs(x1 - x0)) {
        if (x0 > x1) plotLineLow(x1, y1, x0, y0, plot);
        else plotLineLow(x0, y0, x1, y1, plot);
    } else if (y0 > y1) plotLineHigh(x1, y1, x0, y0, plot);
    else plotLineHigh(x0, y0, x1, y1, plot);
}

function plotLineLow(x0, y0, x1, y1, plot) {
    const dx = x1 - x0;
    let dy = y1 - y0;
    let yi = 1;

    if (dy < 0) {
        yi = -1;
        dy = -dy;
    }

    let D = 2 * dy - dx;
    let y = y0;

    for (let x = x0; x < x1; ++x) {
        plot(x, y);
        if (D > 0) {
            y += yi;
            D -= 2 * dx;
        }
        D += 2 * dy;
    }
}

function plotLineHigh(x0, y0, x1, y1, plot) {
    let dx = x1 - x0;
    const dy = y1 - y0;
    let xi = 1;

    if (dx < 0) {
        xi = -1;
        dx = -dx;
    }

    let D = 2 * dx - dy;
    let x = x0;

    for (let y = y0; y < y1; ++y) {
        plot(x, y);
        if (D > 0) {
            x += xi;
            D -= 2 * dy;
        }
        D += 2 * dx;
    }
}

function drawOnCanvas(canvas, canvasData, gridColors, gridSize) {
    if (!canvas) return;

    const width = canvasData ? canvasData.width : 32;
    const height = canvasData ? canvasData.height : 32;

    const ctx = canvas.getContext("2d");
    const imageData = canvas.getContext("2d").getImageData(0, 0, width, height);

    const firstBgColor = toArrColor(gridColors[0]);
    const secondBgColor = toArrColor(gridColors[1]);
    const firstBgChroma = chroma(gridColors[0]);
    const secondBgChroma = chroma(gridColors[1]);

    for (let i = 0; i < imageData.data.length; i += 4) {
        const x = Math.floor(((i / 4) % width) / gridSize);
        const y = Math.floor(((i / 4) / width) / gridSize);

        const bgColor = y % 2 === x % 2 ? firstBgColor : secondBgColor;
        const bgChroma = y % 2 === x % 2 ? firstBgChroma : secondBgChroma;

        if (canvasData.data[i + 3] === 255) {
            imageData.data[i + 0] = canvasData.data[i + 0];
            imageData.data[i + 1] = canvasData.data[i + 1];
            imageData.data[i + 2] = canvasData.data[i + 2];
            imageData.data[i + 3] = canvasData.data[i + 3];
        } else if (canvasData.data[i + 3] === 0) {
            imageData.data[i + 0] = bgColor[0];
            imageData.data[i + 1] = bgColor[1];
            imageData.data[i + 2] = bgColor[2];
            imageData.data[i + 3] = bgColor[3];
        } else { // TODO blend with background.
            const blended = chroma.mix(
                chroma(canvasData.data[i + 0], canvasData.data[i + 1], canvasData.data[i + 2]),
                bgChroma,
                canvasData.data[i + 3] / 255.0,
            ).rgba();

            imageData.data[i + 0] = blended[0];
            imageData.data[i + 1] = blended[1];
            imageData.data[i + 2] = blended[2];
            imageData.data[i + 3] = 255;
        }
    }
    ctx.putImageData(imageData, 0, 0);
}

function blendCanvasData(result, dataBG, dataFG) { // TODO mb support different sizes?
    for (let i = 0; i < result.data.length; i += 4) {
        if (dataFG.data[i + 3] === 255) {
            result.data[i + 0] = dataFG.data[i + 0];
            result.data[i + 1] = dataFG.data[i + 1];
            result.data[i + 2] = dataFG.data[i + 2];
            result.data[i + 3] = dataFG.data[i + 3];
        } else if (dataFG.data[i + 3] === 0) {
            result.data[i + 0] = dataBG.data[i + 0];
            result.data[i + 1] = dataBG.data[i + 1];
            result.data[i + 2] = dataBG.data[i + 2];
            result.data[i + 3] = dataBG.data[i + 3];
        } else { // TODO blend with background.
            const blended = chroma.mix(
                chroma(dataFG.data[i + 0], dataFG.data[i + 1], dataFG.data[i + 2]),
                chroma(dataBG.data[i + 0], dataBG.data[i + 1], dataBG.data[i + 2]),
                dataFG.data[i + 3] / 255.0,
            ).rgba();

            result.data[i + 0] = blended[0];
            result.data[i + 1] = blended[1];
            result.data[i + 2] = blended[2];
            result.data[i + 3] = 255;
        }
    }
    return result;
}
