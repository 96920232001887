import { cloneDeep } from "lodash";

import { Commands, CommandTypes } from "commands";
import { registerCommandHandler } from "middleware/command";
import { ContextItem } from "routes/editor/contextMenu";
import { getColor, setColor } from "../tools/common";

function offsetFn(pixel, dx, dy) {
    const {
        colors, canvasData, setCanvasData, pushHistory, dispatch,
    } = pixel.props;
    const { data, width, height } = canvasData;

    pushHistory();

    const oldData = cloneDeep(data);


    for (let y = 0; y < height; y++) {
        for (let x = 0; x < width; x++) {
            const color = getColor((x + dx) % width, (y + dy) % height, oldData, width);
            setColor(x, y, data, width, color);
        }
    }

    setCanvasData(canvasData);
}

function offset(dispatch, pixel) {
    registerCommandHandler(CommandTypes.COMMAND_FUNC_OFFSET, (action) => {
        offsetFn(pixel, action.dx, action.dy);
    });

    return ContextItem("Offset", undefined, undefined)
        .addItem(ContextItem("Horizontal", undefined, () => dispatch(Commands.offset(pixel.props.canvasData.width / 2, 0))))
        .addItem(ContextItem("Vertical", undefined, () => dispatch(Commands.offset(0, pixel.props.canvasData.height / 2))));
}

export default offset;
