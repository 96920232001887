import React from "react";
import PropTypes from "prop-types";
import chroma from "chroma-js";

import ColorSliders from "./colorSliders";

const RGB = "RGB";

class Rgb extends React.Component {
    render() {
        let { variable } = this.props;

        return (
            <ColorSliders
                variable={variable}
                data={[{
                    name: "R", class: "red", min: 0, max: 255, channel: undefined,
                }, {
                    name: "G", class: "green", min: 0, max: 255, channel: undefined,
                }, {
                    name: "B", class: "blue", min: 0, max: 255, channel: undefined,
                }]}
                toColor={chroma.rgb}
                fromColor={(color) => color.rgb()}
                colorMode={RGB}
                prefix="rgb."
            />
        );
    }
}


Rgb.propTypes = {
    variable: PropTypes.string.isRequired
}

export default Rgb;
