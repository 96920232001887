import React from "react";
import PropTypes from "prop-types";
import ReactTooltip from "react-tooltip";

import { combineClasses } from "utils";

import styles from "./bar.module.css";


const TooltipId = "barTooltip";
const Tooltip = <ReactTooltip
    id={TooltipId}
    place="top"
    effect="solid"
    className={styles.tooltip}
    delayShow={250}
/>;


class Bar extends React.Component {
    render() {
        let { total, children, legend, overlay } = this.props;

        let size = children.map(c => c.size).reduce((prev, current) => {
            return prev + current;
        });

        return (
            <>
                <div className={styles.barWrapper}>
                    <div className={combineClasses(styles.bar, styles.hover)}>
                        {children.map((piece) => this.barPiece(piece, total))}
                        {this.barPiece({ title: "", size: total - size }, total)}
                    </div>
                    {overlay &&
                        <div className={styles.overlay}>
                            <span>{overlay}</span>
                        </div>
                    }
                </div>
                {legend && <Legend children={children} />}
            </>
        );
    }

    barPiece(piece, total) {
        let { display } = this.props;
        let minWidth = 5;
        if (piece.size <= 0) minWidth = 0;

        return (
            <div
                key={piece.title}
                className={styles.barPiece}
                style={{
                    flexGrow: `${(piece.size / total) * 100}`,
                    backgroundColor: `${piece.color}`,
                    minWidth: minWidth
                }}
                data-tip={`${piece.title} ${display(piece, total)}`}
                data-for={TooltipId}
            />
        );
    }
}


class Legend extends React.Component {
    render() {
        let { children } = this.props;

        return (
            <div className={styles.legend}>
                {children.map((piece) => this.entry(piece))}
            </div>
        );
    }

    entry(piece) {
        return (
            <div className={styles.entry}>
                <div className={styles.entryColor}
                    style={{ background: piece.color }} />
                {piece.title}
            </div>
        )
    }
}


Bar.defaultProps = {
    display: (piece, total) => `(${piece.size / total * 100}%)`,
    legend: false
};

Bar.propTypes = {
    total: PropTypes.number.isRequired,
    children: PropTypes.array.isRequired,
    display: PropTypes.func,
    legend: PropTypes.bool,
};

export { Tooltip };
export default Bar;
