import { cloneDeep } from "lodash";

export const Types = {
    CLOSE_CONTEXT_MENU: "CLOSE_CONTEXT_MENU",
    SET_CONTEXT_MENU_ITEMS: "SET_CONTEXT_MENU_ITEMS",
    SET_COLOR: "SET_COLOR",
    SET_TOOL: "SET_TOOL",
    SET_MIRROR: "SET_MIRROR",
    SET_DITHERING: "SET_DITHERING",
    SET_CANVAS_DATA: "SET_CANVAS_DATA",

    HISTORY_PUSH: "HISTORY_PUSH",
    HISTORY_BACKWARD: "HISTORY_BACKWARD",
    HISTORY_FORWARD: "HISTORY_FORWARD",
    HISTORY_RESET: "HISTORY_RESET",

    LAYER_ADD: "LAYER_ADD",
    LAYER_REMOVE: "LAYER_REMOVE",
    LAYER_UPDATE_DATA: "LAYER_UPDATE_DATA",
    LAYER_SET_ACTIVE: "LAYER_SET_ACTIVE",

    REDRAW: "REDRAW",
};

export const Commands = {
    OPEN_CONTEXT_MENU: "OPEN_CONTEXT_MENU",
};

export const Actions = {
    ContextMenu: {
        open: openContextMenu,
        close: closeContextMenu,
        setItems: setContextMenuItems,
    },
    Tool: {
        setColor,
        setTool,
        setMirror,
        setDithering,
    },
    History: {
        push,
        backward,
        forward,
        reset,
    },
    Layers: {
        addLayer,
        removeLayer,
        updateLayerData,
        setActiveLayer,
    },
    setCanvasData,
    redraw,
};

export function openContextMenu(x = "MOUSE_X", y = "MOUSE_Y") {
    return {
        type: Commands.OPEN_CONTEXT_MENU,
        title: "Open context menu",
        x,
        y,
    };
}


export function closeContextMenu() {
    return {
        type: Types.CLOSE_CONTEXT_MENU,
    };
}

export function setContextMenuItems(items) {
    return {
        type: Types.SET_CONTEXT_MENU_ITEMS,
        items,
    };
}

export function setCanvasData(data, pushHistory = true) {
    return {
        type: Types.SET_CANVAS_DATA,
        data: data.data,
        width: data.width,
        height: data.height,
    };
}

export function setColor(primary, secondary, picker = undefined) {
    return {
        type: Types.SET_COLOR,
        color: {
            primary,
            secondary,
            picker,
        },
    };
}

export function setTool(primary, secondary) {
    return {
        type: Types.SET_TOOL,
        tool: {
            primary,
            secondary,
        },
    };
}

export function setMirror(x, y) {
    return {
        type: Types.SET_MIRROR,
        x,
        y,
    };
}

export function setDithering(dithering) {
    return {
        type: Types.SET_DITHERING,
        dithering,
    };
}

// History
export function push() {
    return { type: Types.HISTORY_PUSH };
}

export function backward() {
    return { type: Types.HISTORY_BACKWARD };
}

export function forward() {
    return { type: Types.HISTORY_FORWARD };
}

export function reset() {
    return { type: Types.HISTORY_RESET };
}

// Layers

export function addLayer(name, path = "", type = "layer") {
    return {
        type: Types.LAYER_ADD,
        name,
        path,
        layerType: type,
    };
}

export function removeLayer(name, path = "") {
    return {
        type: Types.LAYER_REMOVE,
        name,
        path,
    };
}

export function updateLayerData(name, path, data) {
    return {
        type: Types.LAYER_UPDATE_DATA,
        name,
        path,
        data,
    };
}

export function setActiveLayer(name, path = "") {
    return {
        type: Types.LAYER_SET_ACTIVE,
        name,
        path,
    };
}


export function redraw() {
    return {
        type: Types.REDRAW,
    };
}
