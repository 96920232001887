import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import queryString from "query-string";

import { combineClasses } from "utils";
import { Actions } from "actions";

import Button from "components/button";
import Separator from "components/separator";
import textInputStyles from "components/textInput.module.css";

import styles from "./login.module.css";


class Login extends React.Component {
    constructor(props) {
        super(props);

        this.onLoginSubmit = this.onLoginSubmit.bind(this);
        this.onRegisterSubmit = this.onRegisterSubmit.bind(this);
    }


    render() {
        const { auth, location } = this.props;

        if (auth.login.success || auth.register.success) {
            const query = queryString.parse(location.search);
            const callback = query.callback || "/";
            window.location.href = callback;
            return null;
        }

        return (
            <>
                <div className="page">
                    <div className={combineClasses(styles.page)}>
                        <div className={combineClasses(styles.login)}>
                            <form className={combineClasses(styles.loginForm)} onSubmit={this.onLoginSubmit}>
                                <h1>Login</h1>
                                <input name="username" type="username" className={textInputStyles.textInput} placeholder="Username" />
                                <input name="password" type="password" className={textInputStyles.textInput} placeholder="Password" />
                                <Button type="submit" text="Login" />
                            </form>
                            <Separator />
                            <form className={combineClasses(styles.registerForm)} onSubmit={this.onRegisterSubmit}>
                                <h1>Register</h1>
                                <input name="username" type="username" className={textInputStyles.textInput} placeholder="Username" />
                                <input name="password" type="password" className={textInputStyles.textInput} placeholder="Password" />
                                <input name="discord" type="discord" className={textInputStyles.textInput} placeholder="discord" />
                                <Button type="submit" text="Register" />
                            </form>

                        </div>
                        <div className={styles.errors}>
                            <div className={combineClasses(styles.error, auth.login.error && styles.active)}>
                                {auth.login.error && auth.login.data.error}
                            </div>
                            <div className={combineClasses(styles.error, auth.register.error && styles.active)}>
                                {auth.register.error && auth.register.data.error}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    onLoginSubmit(event) {
        const { login } = this.props;

        const username = event.target.username.value;
        const password = event.target.password.value;

        login(username, password);

        event.preventDefault();
        return false;
    }

    onRegisterSubmit(event) {
        const { register } = this.props;

        const username = event.target.username.value;
        const password = event.target.password.value;
        const discord = event.target.discord.value;

        register(username, password, discord);

        event.preventDefault();
        return false;
    }
}


Login.defaultProps = {
};

Login.propTypes = {
    auth: PropTypes.any.isRequired,

    history: PropTypes.any.isRequired,
    location: PropTypes.any.isRequired,

    login: PropTypes.func.isRequired,
    register: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});

const mapDispatchToProps = {
    login: Actions.login,
    register: Actions.register,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
