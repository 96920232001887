import React from "react";

import styles from "./common.module.css";


class Footer extends React.Component {
    render() {
        return (
            <footer className={styles.footer}>
                footer
            </footer>
        );
    }
}

export default Footer;
