import chroma from "chroma-js";

export function getColor(x, y, data, width) {
    const start = (width * y + x) * 4;
    return [data[start], data[start + 1], data[start + 2], data[start + 3]];
}

export function setColor(x, y, data, width, color) {
    const start = (width * y + x) * 4;
    const [r, g, b, a] = color;

    data[start] = r;
    data[start + 1] = g;
    data[start + 2] = b;
    data[start + 3] = a;
}

export function equalColor(a, b) {
    return a[0] === b[0] && a[1] === b[1] && a[2] === b[2] && a[3] === b[3];
}

export function toArrColor(color) {
    const c = chroma(color).rgba();
    c[3] = Math.floor(c[3] * 255);
    return c;
}

export function drawPixel(x, y, color, imageData) {
    const arrC = toArrColor(color);
    const { data, width, height } = imageData;

    setColor(x, y, data, width, arrC);
}
