import React from "react";
import PropTypes from "prop-types";

import { combineClasses } from "utils";
import { Actions } from "actions";

import TextInput from "components/textInput";
import Button from "components/button";
import styles from "./pixel.module.css";


class Resize extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            width: props.width,
            height: props.height,
        };

        this.setWidth = this.setWidth.bind(this);
        this.setHeight = this.setHeight.bind(this);
    }

    render() {
        const { width, height } = this.state;
        const { resize } = this.props;

        return (
            <div className={combineClasses(styles.canvasSize)}>
                <TextInput text={width} type="number" onChange={this.setWidth} />
                <TextInput text={height} type="number" onChange={this.setHeight} />
                <Button
                    text="Resize"
                    className={combineClasses(styles.resizeButton)}
                    onClick={() => {
                        if (width >= 4 && height >= 4) resize(width, height);
                    }}
                />
            </div>
        );
    }

    setWidth(value) {
        this.setState({ width: value });
    }

    setHeight(value) {
        this.setState({ height: value });
    }
}


Resize.defaultProps = {
};

Resize.propTypes = {
    resize: PropTypes.func.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
};

export default Resize;
