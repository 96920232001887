import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { combineClasses } from "utils";
import { Actions } from "actions";

import { CommandHandler } from "commands";
import Button from "components/button";
import styles from "./palette.module.css";

import split from "./slices/split";
import threeSlice from "./slices/threeSlice";
import fourSlice from "./slices/fourSlice";
import shades from "./slices/shades";

import Configuration from "./configuration";
import ConfigButton from "./configButton";

class Palette extends React.Component {
    constructor(props) {
        super(props);

        this.onClick = this.onClick.bind(this);
        this.toSvg = (slice) => toSvg(slice, this.onClick);

        this.state = {
            config: false,
        };
    }


    render() {
        const { config } = this.state;

        setTimeout(() => {
            CommandHandler.disableCommandInput();
        }, 100);
        const slices = [
            { colors: ["#FFF", "#000"], type: "split" },
            { colors: ["#999", "#777", "#555"], type: "threeSlice" },
            { colors: ["#444", "#333", "#111", "#222"], type: "fourSlice" },
            { colors: ["#fe9a9a", "#fd5757", "#fc3636", "#860202", "#650101"], type: "shades" },
            { colors: ["#650154", "#ad03c9", "#570165"], type: "threeSlice" },
            { colors: ["#41aa00", "#096600", "#016512"], type: "threeSlice" },
            { colors: ["#FFF", "#DDD", "#BBB"], type: "threeSlice" },
            { colors: ["#FFF", "#DDD", "#BBB"], type: "threeSlice" },
        ];

        return (
            <>
                {!config && (
                    <div className={styles.palette}>
                        <div className={styles.center}>

                            <svg viewBox="-51 0 102 102">
                                {slices.map(this.toSvg)}
                            </svg>
                        </div>
                        <ConfigButton onClick={() => this.setState({ config: true })} />
                    </div>
                )}
                {config && <Configuration />}
            </>
        );
    }

    onClick(color) {
        const { setColor } = this.props;
        setColor(color);
    }
}

function toSvg(slice, onClick, onMouseUp = () => {}) {
    const { colors } = slice;

    switch (slice.type) {
    case "threeSlice":
        return threeSlice(colors, onClick, onMouseUp);
    case "fourSlice":
        return fourSlice(colors, onClick, onMouseUp);
    case "split":
        return split(colors, onClick, onMouseUp);
    case "shades":
        return shades(colors, onClick, onMouseUp);
    default:
        return "";
    }
}

Palette.defaultProps = {
};

Palette.propTypes = {
    setColor: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = {
    setColor: Actions.Tool.setColor,
};

export { toSvg };

export default connect(mapStateToProps, mapDispatchToProps)(Palette);
