import { Commands } from "commands";
import { drawPixel } from "./common";

const pencil = {
    name: "Pencil",
    icon: "pencil",
    keybinding: "KEYQ",
    command: Commands.pencil,
    primary: (x, y, colors, data, modifiers) => {
        if (modifiers.dithering) {
            if ((x + y) % 2 === 0) {
                drawPencil(x, y, colors.primary, data, modifiers.mirror);
            } else {
                drawPencil(x, y, colors.secondary, data, modifiers.mirror);
            }
        } else {
            drawPencil(x, y, colors.primary, data, modifiers.mirror);
        }
    },
    secondary: (x, y, colors, data, modifiers) => {
        if (modifiers.dithering) {
            if ((x + y) % 2 === 0) {
                drawPencil(x, y, colors.secondary, data, modifiers.mirror);
            } else {
                drawPencil(x, y, colors.primary, data, modifiers.mirror);
            }
        } else {
            drawPencil(x, y, colors.secondary, data, modifiers.mirror);
        }
    },
    options: {
        mirror: true,
        color: true,
        dithering: true,
        extra: [
            {
                name: "Mirror",
                dropdown: [
                    { name: "Top to Bottom", action: (dispatch) => dispatch(Commands.mirror("Y-+")) },
                    { name: "Bottom to Top", action: (dispatch) => dispatch(Commands.mirror("Y+-")) },
                    { name: "Left to Right", action: (dispatch) => dispatch(Commands.mirror("X-+")) },
                    { name: "Right to Left", action: (dispatch) => dispatch(Commands.mirror("X+-")) },
                ],
            },
        ],
    },
};


function drawPencil(x, y, color, data, mirror) {
    drawPixel(x, y, color, data);

    if (mirror.x) drawPixel(data.width - x - 1, y, color, data);
    if (mirror.y) drawPixel(x, data.height - y - 1, color, data);
    if (mirror.x && mirror.y) drawPixel(data.width - x - 1, data.height - y - 1, color, data);
}


export default pencil;
