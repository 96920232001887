import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import chroma from "chroma-js";
import _ from "lodash";

import { combineClasses } from "utils";
import { Actions } from "actions";

import styles from "./colorSliders.module.css";


class ColorSliders extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            values: [],
        };
    }

    render() {
        let { values } = this.state;
        const {
            data, colorMode, toColor, fromColor,
        } = this.props;
        const {
            variable, theme, lastColorType, setThemeVariable,
        } = this.props;

        const size = data.length;
        if (values.length !== size) {
            for (let i = 0; i < size; ++i) {
                values.push(0);
            }
        }

        if (lastColorType !== colorMode && theme[variable]) {
            const channels = fromColor(chroma.hex(theme[variable].value));
            values = channels;
            values.map((v) => (Number.isNaN(v) ? 0 : v));
        }

        return (
            <>
                {this.sliders(
                    data, values,
                    (colorValues) => {
                        setThemeVariable(variable, {
                            value: toColor(...colorValues).hex(),
                        }, colorMode);
                        this.setState({ values: colorValues });
                    },
                )}
            </>
        );
    }

    sliders(data, values, setValue) {
        return (
            <div className={styles.sliderCollection}>
                {
                    data.map((colorData, index) => this.getSlider(colorData, values[index], (v) => {
                        values[index] = v;
                        setValue(values);
                    }))
                }
            </div>
        );
    }

    getSlider(data, value, setValue) {
        const { values } = this.state;
        const { prefix, toColor } = this.props;
        const style = {};
        if (data.channel) {
            const maxColor = toColor(...values).set(prefix + data.channel, data.max).hex();
            const minColor = toColor(...values).set(prefix + data.channel, data.min).hex();

            style.background = `linear-gradient(to right, ${minColor} 0%, ${maxColor} 100%)`;
        }

        let normalize = (data.max - data.min);
        const resolution = 1 / 100;

        return (
            <React.Fragment key={`f-${prefix}${data.channel ? data.channel : data.class}`}>
                <span>{data.name}</span>
                <input
                    type="range"
                    step={resolution}
                    min={0 + resolution}
                    max={1 - resolution}
                    value={value / normalize}
                    className={combineClasses(styles.colorSlider, styles[data.class])}
                    onChange={() => {}}
                    onInput={(event) => setValue(event.target.value * normalize)}
                    style={style}
                />
            </React.Fragment>
        );
    }
}

ColorSliders.defaultProps = {
    lastColorType: undefined
}

ColorSliders.propTypes = {
    data: PropTypes.array.isRequired,
    prefix: PropTypes.string.isRequired,
    colorMode: PropTypes.string.isRequired,
    toColor: PropTypes.func.isRequired,
    fromColor: PropTypes.func.isRequired,

    theme: PropTypes.object.isRequired,
    lastColorType: PropTypes.string,
    
    setThemeVariable: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    theme: state.theme,
    lastColorType: state.themeEditor.lastColorType,
});

const mapDispatchToProps = {
    setThemeVariable: Actions.setThemeVariable,
};

export default connect(mapStateToProps, mapDispatchToProps)(ColorSliders);
