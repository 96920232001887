import React from "react";
import PropTypes from "prop-types";

import { combineClasses } from "utils";
import { Actions } from "actions";

import styles from "./button.module.css";


class Button extends React.Component {
    render() {
        const {
            text, type, className, onClick,
        } = this.props;

        return (
            <div className={combineClasses(className)}>
                <input type={type} className={combineClasses(styles.button)} value={text} onClick={onClick} />
            </div>
        );
    }
}


Button.defaultProps = {
    text: "",
    type: "button",
    className: undefined,
    onClick: undefined,
};

Button.propTypes = {
    text: PropTypes.string,
    type: PropTypes.string,
    className: PropTypes.string,
    onClick: PropTypes.func,
};

export default Button;
