export const Types = {
    ADD_EXERCISE: "ADD_EXERCISE",
    REMOVE_EXERCISE: "REMOVE_EXERCISE",
    MODIFY_EXCERCISE: "MODIFY_EXCERCISE",
    SET_COLLECTION: "SET_COLLECTION",
};

export const Actions = {
    addExercise,
    modifyExcercise,
    removeExercise,
    setCollection,
};


export function addExercise(item, path = undefined) {
    return {
        type: Types.ADD_EXERCISE,
        item,
        path,
    };
}

export function modifyExcercise(key) {
    return {
        type: Types.MODIFY_EXCERCISE,
        key,
    };
}

export function removeExercise(key) {
    return {
        type: Types.REMOVE_EXERCISE,
        key,
    };
}


export function setCollection(path) {
    return {
        type: Types.SET_COLLECTION,
        path,
    };
}
