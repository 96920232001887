import { Commands } from "commands";
import { drawPixel } from "./common";


const eraser = {
    name: "Eraser",
    icon: "eraser",
    keybinding: "KEYE",
    command: Commands.eraser,
    primary: (x, y, colors, data, modifiers) => {
        drawPixel(x, y, "#0000", data, modifiers);

        if (modifiers.mirror.x) drawPixel(data.width - x - 1, y, "#0000", data, modifiers);
        if (modifiers.mirror.y) drawPixel(x, data.height - y - 1, "#0000", data, modifiers);
        if (modifiers.mirror.x && modifiers.mirror.y) drawPixel(data.width - x - 1, data.height - y - 1, "#0000", data, modifiers);
    },
    secondary: (x, y, colors, data, modifiers) => {
        drawPixel(x, y, "#0000", data, modifiers);

        if (modifiers.mirror.x) drawPixel(data.width - x - 1, y, "#0000", data, modifiers);
        if (modifiers.mirror.y) drawPixel(x, data.height - y - 1, "#0000", data, modifiers);
        if (modifiers.mirror.x && modifiers.mirror.y) drawPixel(data.width - x - 1, data.height - y - 1, "#0000", data, modifiers);
    },
    options: {
        mirror: true,
        color: false,
        extra: [
            { name: "Clear", action: (dispatch) => dispatch(Commands.clear()) },
        ],
    },
};

export default eraser;
