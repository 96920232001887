import React from "react";
import PropTypes from "prop-types";

import styles from "./separator.module.css";


class Separator extends React.Component {
    render() {
        const { height } = this.props;
        return <div className={styles.separator} style={{ height }} />;
    }
}

Separator.defaultProps = {
    height: undefined,
};

Separator.propTypes = {
    height: PropTypes.number,
};

export default Separator;
