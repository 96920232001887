import command from 'reducers/command';
import { applyMiddleware } from 'redux';

import { axios } from "./axios";
import { callback } from "./callback";
import { command as commandHandler } from "./command";
import mousePosition from "./mousePosition";
import persistence from "./persistence";

/**
 * Combine all middleware
 */
export default applyMiddleware(callback, axios, persistence, mousePosition, commandHandler);
