import React, { createRef } from "react";


import PropTypes from "prop-types";

import { combineClasses } from "utils";
import { Actions } from "actions";

import styles from "./palette.module.css";


class ConfigButton extends React.Component {
    render() {
        const { onClick } = this.props;

        return (
            <svg className={styles.configButton} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50.265 50.265" onClick={onClick}>
                <path d="M47.585 19.06a4.806 4.806 0 010 6.797 5.074 5.074 0 00-1.114 5.494 50 50 0 013.661 18.781h-50v-50a50 50 0 0118.782 3.662 5.074 5.074 0 005.494-1.115 4.806 4.806 0 016.796 0l8.976 8.976z" />
                <path id="a" fill="none" stroke="none" d="M24.408 2.679l23.177 23.178" />
                <text stoke="none" fill="#BBB" fontSize="8.5" fontWeight="400" letterSpacing="0" transform="translate(21 7) scale(.45384)" wordSpacing="0">
                    <textPath xlinkHref="#a">
                        <tspan>config</tspan>
                    </textPath>
                </text>
            </svg>
        );
    }
}


ConfigButton.defaultProps = {
    onClick: undefined,
};

ConfigButton.propTypes = {
    onClick: PropTypes.func,
};

export default ConfigButton;
