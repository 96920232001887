let x = 0;
let y = 0;

function onMouseMove(event) {
    x = event.clientX;
    y = event.clientY;
}

document.addEventListener("mousemove", onMouseMove);

export const mousePosition = (store) => (next) => (action) => {
    // eslint-disable-next-line no-param-reassign
    if (action.x === "MOUSE_X") { action.x = x; }

    // eslint-disable-next-line no-param-reassign
    if (action.y === "MOUSE_Y") { action.y = y; }

    return next(action);
};

export default mousePosition;
