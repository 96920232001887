import { Commands, CommandTypes } from "commands";
import { registerCommandHandler } from "middleware/command";
import { ContextItem } from "routes/editor/contextMenu";

function fill(dispatch, pixel) {
    registerCommandHandler(CommandTypes.COMMAND_FUNC_FILL, (action) => pixel.fillImage(pixel.props.primaryColor));

    return ContextItem("Fill", "Fill image with primary color", () => dispatch(Commands.fill()));
}

function clear(dispatch, pixel) {
    registerCommandHandler(CommandTypes.COMMAND_FUNC_CLEAR, (action) => pixel.fillImage("#0000"));

    return ContextItem("Clear", "Clear image", () => dispatch(Commands.clear()));
}

export { fill, clear };
