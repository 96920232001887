import React from "react";
import PropTypes from "prop-types";

import { combineClasses } from "utils";
import { Actions } from "actions";

import styles from "./colorInput.module.css";


class ColorInput extends React.Component {
    constructor(props) {
        super(props);

        this.colorInput = React.createRef();
    }


    render() {
        const { color, onChange, className } = this.props;

        return (
            <>
                <input type="color" onChange={onChange} className={combineClasses(styles.hiddenInput)} tabIndex="-1" ref={this.colorInput} />
                <div
                    onClick={() => {
                        this.colorInput.current.focus();
                        this.colorInput.current.click();
                    }}
                    style={{ background: color }}
                    className={combineClasses(className, styles.color)}
                />
            </>
        );
    }
}


ColorInput.defaultProps = {
    className: undefined,
};

ColorInput.propTypes = {
    color: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    className: PropTypes.string,
};

export default ColorInput;
