import React from "react";
import PropTypes from "prop-types";
import { cloneDeep } from "lodash";

import { combineClasses } from "utils";
import { Actions } from "actions";

import Card from "components/card";

import { withRouter } from "react-router-dom";
import { ItemTypes } from "./gym";


import styles from "./item.module.css";


class Item extends React.Component {
    constructor(props) {
        super(props);
        this.onClick = this.onClick.bind(this);
    }

    render() {
        const { name, type } = this.props.item;


        return (
            <div className={combineClasses(styles.item)}>
                <Card onClick={this.onClick}>
                    {name}
                </Card>
            </div>
        );
    }

    onClick() {
        const { type } = this.props.item;
        if (type === ItemTypes.ITEM) { this.viewExercise(); } else if (type === ItemTypes.COLLECTION) { this.viewCollection(); }
    }

    viewExercise() {

    }

    viewCollection() {
        const { history, location } = this.props;
        const { name } = this.props.item;

        const newloc = cloneDeep(location);
        newloc.hash += (newloc.hash.length > 0 ? "/" : "") + name;

        history.push(newloc);
    }
}


Item.defaultProps = {
};

Item.propTypes = {
    item: PropTypes.any.isRequired,

    // React router
    location: PropTypes.any.isRequired,
    history: PropTypes.any.isRequired,
};

export default withRouter(Item);
