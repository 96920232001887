import { Types as ThemeTypes, Actions as ThemeActions } from "./theme";
import { Types as ComTypes, Actions as ComActions } from "./communication";
import { Types as StatTypes, Actions as StatActions } from "./stats";
import { Types as EditorTypes, Actions as EditorActions } from "./editor";
import { Types as TodoTypes, Actions as TodoActions } from "./todo";
import { Types as AuthTypes, Actions as AuthActions } from "./auth";


export const Types = {
    ...StatTypes,
    ...ThemeTypes,
    ...ComTypes,
    ...EditorTypes,
    ...TodoTypes,
    ...AuthTypes,
    INITIALIZE: "INITIALIZE",
};

export const Actions = {
    ...StatActions,
    ...ThemeActions,
    ...ComActions,
    ...EditorActions,
    ...TodoActions,
    ...AuthActions,
    initialize: () => ({ type: Types.INITIALIZE }),
};
