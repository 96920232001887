import React from "react";

function threeSlice(colors, onClick, onMouseUp) {
    const [c1, c2, c3] = colors;
    return (
        <g>
            <path fill={c1} onClick={() => onClick(c1)} onMouseUp={() => onMouseUp(0)} d="M1.132 18.99H11.95L17.956 4.49A49 49 0 001.132 1.142z" />
            <path fill={c2} onClick={() => onClick(c2)} onMouseUp={() => onMouseUp(1)} d="M19.804 5.254l-6.007 14.501 7.649 7.65 12.62-12.62a49 49 0 00-14.262-9.53z" />
            <path fill={c3} onClick={() => onClick(c3)} onMouseUp={() => onMouseUp(2)} d="M20.032 28.819L6.46 42.389a10 10 0 00-5.329-2.207V20.99h11.071z" />
        </g>
    );
}

export default threeSlice;
