
export const Types = {
    COMMAND_TEST: "COMMAND_TEST",
};

export const Actions = {
    test,
};


function test() {
    return {
        type: Types.COMMAND_TEST,
        title: "Test",
    };
}
