function card({
    name, value, category, type,
}) {
    return name;
}

function visualizer() {
    return "";
}

export default {
    card,
    visualizer,
};
