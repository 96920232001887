import React from "react";
import PropTypes from "prop-types";

import styles from "./pixel.module.css";


class Separator extends React.Component {
    render() {
        const { header } = this.props;

        return (
            <div className={styles.separatorHeader}>
                <separator />
                {header && <span>{header}</span>}
                {header && <separator />}
            </div>
        );
    }
}


Separator.defaultProps = {
    header: undefined,
};

Separator.propTypes = {
    header: PropTypes.string,
};

export default Separator;
