import React from "react";
import PropTypes from "prop-types";
import { CustomPicker, PhotoshopPicker } from "react-color";
import { Saturation, Hue } from "react-color/lib/components/common";

import { combineClasses } from "utils";
import { Actions } from "actions";
import Window from "components/window";

import styles from "./color.module.css";
import ColorPicker from "../colorPicker";


class Color extends React.Component {
    state = {
        background: '#fff',
    };
    
    handleChangeComplete = (color) => {
        this.setState({ background: color.hex });
    };

    render() {
        return (
            <>
                <Window title="Color" contentStyle={styles.window} blocking={false}>
                    <ColorPicker />
                </Window>
            </>
        );
    }
}


Color.defaultProps = {
};

Color.propTypes = {
};

export default CustomPicker(Color);
