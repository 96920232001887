import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { combineClasses } from "utils";
import { Actions, Types } from "actions";

import { CommandTypes, Commands, CommandBindings } from "commands";

import { CommandHandler } from "commands/commandHandler";
import styles from "./commandListener.module.css";

const pressedKeys = {

};

let pressedModifiers = [];

class CommandListener extends React.Component {
    constructor(props) {
        super(props);

        this.onKeyDown = this.onKeyDown.bind(this);
        this.onKeyUp = this.onKeyUp.bind(this);
    }

    componentDidMount() {
        document.addEventListener("keydown", this.onKeyDown);
        document.addEventListener("keyup", this.onKeyUp);
        // CommandHandler.registerCommandHandler(CommandTypes.COMMAND_TEST, () => console.log("test"));
        // CommandHandler.registerCommandBinding(Commands.test, "KEYB");
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.onKeyDown);
        document.removeEventListener("keyup", this.onKeyUp);
    }

    onKeyDown(event) {
        if (!CommandHandler.isCommandInputEnabled()) return;

        const key = event.code.toUpperCase();
        if (pressedKeys[key]) {
            event.preventDefault();
            event.stopPropagation();
            return;
        }
        pressedKeys[key] = true;

        const modifiers = [];
        if (event.altKey) { modifiers.push(CommandHandler.CommandModifiers.ALT); }
        if (event.ctrlKey) { modifiers.push(CommandHandler.CommandModifiers.CTRL); }
        if (event.shiftKey) { modifiers.push(CommandHandler.CommandModifiers.SHIFT); }
        pressedModifiers = modifiers;

        const command = CommandBindings.fromKey(key, modifiers);

        if (!command) return;

        const { state, dispatch } = this.props;
        if (CommandHandler.canExecute(command().type, state)) { dispatch(command()); }

        event.preventDefault();
        event.stopPropagation();
    }

    onKeyUp(event) {
        const key = event.code.toUpperCase();
        pressedKeys[key] = false;

        const modifiers = [];
        if (event.altKey) { modifiers.push(CommandHandler.CommandModifiers.ALT); }
        if (event.ctrlKey) { modifiers.push(CommandHandler.CommandModifiers.CTRL); }
        if (event.shiftKey) { modifiers.push(CommandHandler.CommandModifiers.SHIFT); }
        pressedModifiers = modifiers;
    }

    render() {
        return (
            <>

            </>
        );
    }
}


CommandListener.defaultProps = {
};

CommandListener.propTypes = {
};

const mapStateToProps = (state) => ({
    state,
});

const mapDispatchToProps = (dispatch) => ({
    dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(CommandListener);

export const isCommandActive = (commandType) => {
    const { bindings } = CommandBindings.Bindings[commandType];

    const result = bindings.some((binding) => {
        if (pressedModifiers.length !== binding.modifiers.length) return false;
        if (pressedKeys[binding.key] !== true) return false;

        return binding.modifiers.every((m, i) => m === pressedModifiers[i]);
    });

    return result;
};
