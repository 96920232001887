import { Types } from "actions";
import _ from "lodash";

import { iniToJson } from "utils";

export default { status };

function status(state = {}, action) {
    switch (action.type) {
    case Types.FETCH_RPI_STATS_SUCCESS:
        return setRpiStatus(state, action);
    }
    return state;
}

function setRpiStatus(state, action) {
    let json = _.merge(_.clone(state), iniToJson(action.data.data));
    return json;
}