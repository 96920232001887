import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import chroma from "chroma-js";
import _ from "lodash";
import ReactResizeDetector from "react-resize-detector";

import { combineClasses } from "utils";
import Card from "components/card";
import { Actions } from "actions";
import Separator from "components/separator";
import HexField from "components/hexField";
import ColorPalette from "components/colorPalette";

import Selector from "components/selector";
import ColorSliders from "./colorSliders";

import styles from "./colorPicker.module.css";


const HEX = "HEX";
const optionsHeight = 2;

class ColorPicker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            optionsWidth: 0,
            colorSlider: undefined,
        };

        this.paletteRef = React.createRef();

        this.onHexUpdate = this.onHexUpdate.bind(this);
        this.updatePaletteOptions = this.updatePaletteOptions.bind(this);
    }

    componentDidMount() {
        this.updatePaletteOptions();
    }

    render() {
        const { name, theme, setThemeVariable } = this.props;
        const { optionsWidth, colorSlider } = this.state;

        let variants = [];
        let color = "#000000";
        if (theme[name]) {
            color = chroma.hex(theme[name].value);
            variants = this.getVariantsHSL(optionsWidth, optionsHeight, color);
        }

        const colorSliderOptions = [
            { label: "RGB", content: <ColorSliders.Rgb variable={name} /> },
            { label: "HSV", content: <ColorSliders.Hsv variable={name} /> },
            { label: "CMYK", content: <ColorSliders.Cmyk variable={name} /> },
            { label: "HCL", content: <ColorSliders.Hcl variable={name} /> },
            /* { label: "HSL", content: <ColorSliders.Hsl /> } Not rly working... */
        ];

        return (
            <>
                <div className={styles.colorPreview}>
                    <HexField
                        border={false}
                        onChange={this.onHexUpdate}
                        value={theme[name] ? theme[name].value.toString().replace("#", "") : ""}
                        className={styles.hexField}
                    />
                    <div className={combineClasses(styles.preview, styles.color)} style={{ background: color }} />
                    <ColorPalette variable={name} colors={variants} innerRef={this.paletteRef} />
                    <ReactResizeDetector handleWidth handleHeight onResize={this.updatePaletteOptions} />
                </div>
                <Separator />
                <div className={styles.colorSliders}>
                    <Selector
                        options={colorSliderOptions}
                        default={colorSliderOptions[0]}
                        onSelect={(slider) => {
                            this.setState({ colorSlider: slider });
                            setThemeVariable(name, color, undefined);
                        }}
                    />
                    {colorSlider && colorSlider.content}
                </div>
            </>
        );
    }

    onHexUpdate(hex) {
        const { name, setThemeVariable } = this.props;
        console.log("@colorDisplay", name);
        setThemeVariable(name, { value: `#${hex}` }, HEX);
    }

    updatePaletteOptions() {
        const { options } = this.state;
        const optionsWidth = Math.floor((this.paletteRef.current.offsetWidth) / 58);
        if (optionsWidth !== options) {
            this.setState({ optionsWidth });
        }
    }

    getVariantsHSL(width, height, color) {
        if (!color || width <= 0 || height <= 0) return [];
        const variants = [];
        for (let row = 0; row < height; ++row) {
            const reverse = (row + 1) % 2;

            const start = reverse ? ((row + 1) * width) - 1 : row * width;
            const sign = reverse ? -1 : 1;

            for (let col = 0; col < width; ++col) {
                variants.push(color.set("hsl.l", (start + sign * col) / (width * height - 1)).hex());
            }
        }
        return variants;
    }

    getVariantsHSV(width, height, color) {
        if (!color || width <= 0) return [];
        height = 2;
        const variants = [];

        for (let value = 0; value < width; ++value) {
            variants.push(color.set("hsv.v", value / (width - 1)).hex());
        }

        for (let value = width - 1; value >= 0; --value) {
            variants.push(color.set("hsv.s", value / (width - 1)).hex());
        }
        return variants;
    }
}

ColorPicker.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,

    theme: PropTypes.object.isRequired,

    setThemeVariable: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    theme: state.theme,
});

const mapDispatchToProps = {
    setThemeVariable: Actions.setThemeVariable,
};

export default connect(mapStateToProps, mapDispatchToProps)(ColorPicker);