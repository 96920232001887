export default function(ini) {
    let lines = ini && ini.split && ini.split("\n") || [];
    let json = {};
    let field = undefined;
    let headerPattern = /^\[([a-zA-Z][a-zA-Z0-9]*)\]$/;
    let valuePattern = /([a-zA-Z][a-zA-Z0-9]*)=(.*)/;


    lines.forEach((line) => {
        let header = headerPattern.exec(line);
        if (header && header[1]) {
            field = header[1].toLowerCase();
            json[field] = {};
            return;
        }
        let value = valuePattern.exec(line);
        if (value && value[1] && value[2]) {
            if (field) json[field][value[1].toLowerCase()] = value[2];
            else json[value[1].toLowerCase()] = value[2];
        }
    });

    return json;
}