import React from "react";

function fourSlice(colors, onClick, onMouseUp) {
    const [c1, c2, c3, c4] = colors;
    return (
        <g>
            <path fill={c1} onClick={() => onClick(c1)} onMouseUp={() => onMouseUp(0)} d="M29.116 19.734l4.95-4.95A49 49 0 001.132 1.142v7a42.002 42.002 0 0127.984 11.592z" />
            <path fill={c2} onClick={() => onClick(c2)} onMouseUp={() => onMouseUp(1)} d="M14.512 12.804a40.002 40.002 0 00-13.38-2.66v14.42l9.787-3.086z" />
            <path fill={c3} onClick={() => onClick(c3)} onMouseUp={() => onMouseUp(2)} d="M17.505 31.345l-4.738-9.102 3.592-8.673a40.002 40.002 0 0111.343 7.579z" />
            <path fill={c4} onClick={() => onClick(c4)} onMouseUp={() => onMouseUp(3)} d="M6.46 42.39a10 10 0 00-5.328-2.208V26.66l10.033-3.163 4.857 9.331z" />
        </g>
    );
}


export default fourSlice;
