import { combineReducers } from "redux";

import { CommandTypes } from "commands";
import { cloneDeep, merge } from "lodash";


export default { command };

function command(state = { last: undefined, time: undefined }, action) {
    if (!CommandTypes[action.type]) return state;

    return { last: action, time: Date.now() };
}
