import React from "react";
import PropTypes from "prop-types";

import { combineClasses } from "utils";
import { Actions } from "actions";

import Node from "./node";

import styles from "./timeline.module.css";


class Timeline extends React.Component {
    constructor(props) {
        super(props);

        this.container = React.createRef();
    }


    render() {
        const width = 200;
        const color = "#C0D0D8";
        const nodes = [
            {
                name: "zero", position: 10, width: 0, color: "#AF5555",
            },
            {
                name: "one", position: 50, width: 10, color: "#7799AA",
            },
            {
                name: "one.five", position: 60, width: 10, color: "#AA77AA",
            },
            {
                name: "one.six", position: 65, width: 20, color: "#AA8877",
            },
            {
                name: "two", position: 100, width: 20, color: "#8077AA",
            },
            {
                name: "three", position: 200, width: 0, color: "#A2AA77",
            },
        ];

        const title = "";// "Timeline";

        return (
            <div className={combineClasses(styles.timeline)}>
                <h3 className={combineClasses(styles.title)}>{title}</h3>
                <div className={combineClasses(styles.nodes)} ref={this.container}>
                    <div className={combineClasses(styles.line)} style={{ background: color }} />
                    <div className={combineClasses(styles.cap)} style={{}} />
                    {nodes.map((node, index) => <Node position={node.position / width * 100} width={node.width} color={node.color} content={this.getContent(node)} container={this.container} key={index} />)}
                </div>
            </div>
        );
    }

    getContent(node) {
        return (
            <>
                <h4>{node.name}</h4>
                <span>Content text</span>
                <div className={combineClasses(styles.color)} style={{ background: node.color }} />
            </>
        );
    }
}

export default Timeline;
