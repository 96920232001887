import React from "react";
import { connect } from "react-redux";
import ReactTooltip from "react-tooltip";

import { Tooltip } from "components/bar";
import { Actions } from "actions";
import styles from "./status.module.css";
import Cpu from "./cpu";
import Memory from "./memory";
import Temperature from "./temperature";
import Uptime from "./uptime";


class Status extends React.Component {
    constructor(props) {
        super(props);

        this.fetchRpiStats = this.fetchRpiStats.bind(this);
    }

    componentDidMount() {
        this.fetchRpiStats();
        this.fetcher = setInterval(this.fetchRpiStats, 1000);
    }

    componentWillUnmount() {
        clearInterval(this.fetcher);
    }

    render() {
        const { status } = this.props;

        return (
            <>
                {Tooltip}
                <div className="frontPage">
                    <h1>Raspberry PI Status</h1>
                </div>
                <div className="page">
                    <div className={styles.collection}>
                        <Cpu
                            core={1}
                            total={100}
                            cpu={status.cpu0}
                        />
                        <Cpu
                            core={2}
                            total={100}
                            cpu={status.cpu1}
                        />
                        <Cpu
                            core={3}
                            total={100}
                            cpu={status.cpu2}
                        />
                        <Cpu
                            core={4}
                            total={100}
                            cpu={status.cpu3}
                        />
                        <Memory memory={status.memory} />
                        <Temperature value={status.temperature && status.temperature.temp} />
                        <Uptime time={status.uptime && status.uptime.uptime.trim().replace("up", "and")} />
                    </div>
                </div>
            </>
        );
    }

    fetchRpiStats() {
        const { fetchRpiStats } = this.props;
        fetchRpiStats();
        ReactTooltip.rebuild();
    }
}


const mapStateToProps = (state) => ({
    status: state.status,
});

const mapDispatchToProps = {
    fetchRpiStats: Actions.fetchRpiStats,
};

export default connect(mapStateToProps, mapDispatchToProps)(Status);
