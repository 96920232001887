import bucket from "./bucket";
import eraser from "./eraser";
import pencil from "./pencil";
import picker from "./picker";
import modify from "./modify";

const Tools = [
    pencil,
    eraser,
    picker,
    bucket,
    modify,
];

export const ToolMapper = (tool) => {
    switch (tool && tool.icon) {
    case "pencil": return pencil;
    case "eraser": return eraser;
    case "picker": return picker;
    case "bucket": return bucket;
    case "modify": return modify;
    default: return undefined;
    }
};

export default Tools;
