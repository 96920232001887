import { Actions as ComActions } from "./communication";

export const Types = {
    FETCH_RPI_STATS: "FETCH_RPI_STATS",
    FETCH_RPI_STATS_SUCCESS: "FETCH_RPI_STATS_SUCCESS",
    FETCH_RPI_STATS_ERROR: "FETCH_RPI_STATS_ERROR",
};

export const Actions = {
    fetchRpiStats,
};


export function fetchRpiStats() {
    return ComActions.axiosGet("api/rpi.stats", {}, {},
        Types.FETCH_RPI_STATS, Types.FETCH_RPI_STATS_SUCCESS, Types.FETCH_RPI_STATS_ERROR);
}
