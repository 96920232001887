import React from "react";
import PropTypes from "prop-types";

import styles from "./status.module.css";
import Card from "../../components/card";
import Bar, { Legend } from "../../components/bar";


class Cpu extends React.Component {
    render() {
        const {
            total, cpu, core,
        } = this.props;

        const pAverage = parseInt(cpu && cpu.average || 0);
        const pUsage = parseInt(cpu && (Math.ceil(100 - cpu.idle)) || 0);

        let displayAverage = pAverage - pUsage;
        if (displayAverage < 0) displayAverage = 0;

        const blocks = [
            {
                size: pUsage, value: pUsage, title: "Usage", color: "var(--cpu-used)",
            },
            {
                size: displayAverage, value: pAverage, title: "Average", color: "var(--cpu-average)",
            },
        ];

        return (
            <Card header={`CPU ${core}`}>
                <Bar total={total} display={display} overlay={`${pUsage}%`}>
                    {blocks}
                </Bar>
            </Card>
        );
    }
}

function display(piece, total) {
    return `(${(piece.value || piece.size) / total * 100}%)`;
}

Cpu.defaultProps = {

};

Cpu.propTypes = {
    total: PropTypes.number.isRequired,
    current: PropTypes.number.isRequired,
    average: PropTypes.number.isRequired,
    core: PropTypes.number.isRequired,
};

export default Cpu;
