import React from "react";
import PropTypes from "prop-types";
import chroma from "chroma-js";

import ColorSliders from "./colorSliders";

const CMYK = "CMYK";

class Cmyk extends React.Component {
    render() {
        let { variable } = this.props;

        return (
            <ColorSliders
                variable={variable}
                data={[{
                    name: "C", class: "cyan", min: 0, max: 1, channel: undefined,
                }, {
                    name: "M", class: "magenta", min: 0, max: 1, channel: undefined,
                }, {
                    name: "Y", class: "yellow", min: 0, max: 1, channel: undefined,
                }, {
                    name: "K", class: "black", min: 0, max: 1, channel: undefined,
                }]}
                toColor={(c, m, y, k) => chroma.cmyk(c, m, y, k)}
                fromColor={(color) => color.cmyk()}
                colorMode={CMYK}
                prefix="cmyk."
            />
        );
    }
}


Cmyk.propTypes = {
    variable: PropTypes.string.isRequired
}

export default Cmyk;
