import { CommandTypes } from "commands";

const commandHandlers = {};


export const command = (store) => (next) => (action) => {
    if (!CommandTypes[action.type]) return next(action);

    if (commandHandlers[action.type]) {
        const s = commandHandlers[action.type].length == 1 ? "" : "s";
        console.log(`Executing command: '${action.title}' (${commandHandlers[action.type].length} handler${s})`);
        handleCommand(action);
    } else {
        console.log(`Executing command: '${action.title}' (0 handlers)`);
    }

    return next(action);
};

function handleCommand(action) {
    commandHandlers[action.type].forEach((handler) => {
        handler(action);
    });
}

export function registerCommandHandler(type, handler) {
    if (!commandHandlers[type]) commandHandlers[type] = [];

    commandHandlers[type].push(handler);
}

export function unregisterCommandHandler(type, handler) {
    if (!commandHandlers[type]) return;

    const index = commandHandlers[type].indexOf(handler);
    if (index < 0) return;

    commandHandlers[type].splice(index, 1);
}
