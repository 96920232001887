import { combineReducers } from "redux";

import { Types } from "actions";
import { cloneDeep, merge } from "lodash";


export default { auth };

function auth(state = { login: {}, register: {} }, action) {
    switch (action.type) {
    case Types.LOGIN_SUCCESS:
        return { login: { success: true, data: action.data }, register: {} };
    case Types.LOGIN_ERROR:
        return { login: { error: true, data: action.data }, register: {} };
    case Types.REGISTER_SUCCESS:
        return { login: {}, register: { success: true, data: action.data } };
    case Types.REGISTER_ERROR:
        return { login: {}, register: { error: true, data: action.data } };
    }
    return state;
}
