import React from "react";
import PropTypes from "prop-types";

import { combineClasses } from "utils";
import { Actions } from "actions";

import styles from "./checkbox.module.css";


class Checkbox extends React.Component {
    render() {
        const {
            name, checked, onClick,
        } = this.props;

        return (
            <div className={combineClasses(styles.checkbox)} onClick={onClick}>
                <div className={combineClasses(styles.box, checked && styles.checked)} />
                <span className="noselect">{name}</span>
            </div>
        );
    }
}


Checkbox.defaultProps = {
    name: undefined,
    checked: false,
    onClick: undefined,
};

Checkbox.propTypes = {
    name: PropTypes.string,
    checked: PropTypes.bool,
    onClick: PropTypes.func,
};

export default Checkbox;
