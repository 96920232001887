export const Types = {
    AXIOS_POST: "AXIOS_POST",
    AXIOS_POST_SUCCESS: "AXIOS_POST_SUCCESS",
    AXIOS_POST_ERROR: "AXIOS_POST_ERROR",
    AXIOS_GET: "AXIOS_GET",
    AXIOS_GET_SUCCESS: "AXIOS_GET_SUCCESS",
    AXIOS_GET_ERROR: "AXIOS_GET_ERROR",
};

export const Actions = {
    axiosPost,
    axiosGet,
    axiosCallback,
};


/**
 * Do an axios post with given parameters.
 *
 * @param {String} path Path to post to
 * @param {Object} data Data to post
 * @param {Object} config Config to use when doing the post
 * @param {Function | String} startCB Callback action creator or Action Type, called when the post is initiated
 * @param {Function | String} successCB Callback action creator or Action Type, called if post was successful
 * @param {Function | String} errorCB Callback action creator or Action Type, called if post was unsuccessful
 */
export function axiosPost(path, data, config = undefined,
    startCB = undefined,
    successCB = axiosCallback(Types.AXIOS_POST_SUCCESS),
    errorCB = axiosCallback(Types.AXIOS_POST_ERROR)) {
    if (typeof startCB === "string") startCB = axiosCallback(startCB);
    if (typeof successCB === "string") successCB = axiosCallback(successCB);
    if (typeof errorCB === "string") errorCB = axiosCallback(errorCB);

    return {
        type: Types.AXIOS_POST,
        path,
        data,
        config,
        startCB,
        successCB,
        errorCB,
    };
}

/**
 * Do an axios get with given parameters.
 *
 * @param {String} path Path to get from
 * @param {Object} data Data to send
 * @param {Object} config Config to use when doing the get
 * @param {Function | String} startCB Callback action creator or Action Type, called when the get is initiated
 * @param {Function | String} successCB Callback action creator or Action Type, called if get was successful
 * @param {Function | String} errorCB Callback action creator or Action Type, called if get was unsuccessful
 */
export function axiosGet(path, data, config = undefined,
    startCB = undefined,
    successCB = Types.AXIOS_GET_SUCCESS,
    errorCB = Types.AXIOS_GET_ERROR) {
    if (typeof startCB === "string") startCB = axiosCallback(startCB);
    if (typeof successCB === "string") successCB = axiosCallback(successCB);
    if (typeof errorCB === "string") errorCB = axiosCallback(errorCB);

    return {
        type: Types.AXIOS_GET,
        path,
        data,
        config,
        startCB,
        successCB,
        errorCB,
    };
}

/**
 * Standard implementation of an axios callback function
 * @param {String} type
 */
export function axiosCallback(type) {
    return function callback(path, data, previousAction) {
        return {
            type,
            path,
            data,
            previousAction,
        };
    };
}
