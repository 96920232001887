import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { combineClasses } from "utils";
import { Actions } from "actions";
import { CommandBindings } from "commands";

import styles from "./commandAlert.module.css";
import Card from "./card";
import { CommandHandler } from "commands/commandHandler";


const delay = 2 * 1000;


class CommandAlert extends React.Component {


    last = undefined;


    constructor(props) {
        super(props);

        this.state = {
            show: false
        };
    }

    render() {
        let { last, time } = this.props.command;
        let { show } = this.state;

        let message = "";

        if (last != undefined) {
            let type = last.type;
            let keyCombo = "";

            let bindings = CommandBindings.Bindings[type]?.bindings;
            if (bindings && bindings.length > 0) {
                if (bindings[0].modifiers == CommandHandler.CommandModifiers.NONE)
                    keyCombo = `[${bindings[0].key}]`;
                else
                    keyCombo = `[${bindings[0].modifiers.join(" + ")} + ${bindings[0].key}]`;
            }


            if (last != this.last) {
                this.last = last;

                let timeout = Date.now() + delay - time;

                this.setState({ show: true })
                clearTimeout(this.timeout);
                this.timeout = setTimeout(() => {
                    this.setState({ show: false });
                }, timeout);
            }

            message = `Command: ${last.title} ${keyCombo}`;
        }

        const dt = new Date(time);

        return <div className={combineClasses(styles.commandAlert, show && styles.show)}>
            <Card className={combineClasses(styles.card)}
                contentClass={combineClasses(styles.cardContent)}
                hover={false}>
                <span>[{dt.getHours()}:{dt.getMinutes()}:{dt.getSeconds()}] {message}</span>
            </Card>
        </div>;
    }

}


CommandAlert.defaultProps = {
    command: undefined,
};

CommandAlert.propTypes = {
    command: PropTypes.any,
};

const mapStateToProps = state => ({
    command: state.command
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(CommandAlert);
