import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { cloneDeep } from "lodash";

import { combineClasses } from "utils";
import { Actions } from "actions";

import Card from "components/card";
import Path from "routes/editor/svg/path";
import styles from "./folderBrowser.module.css";

const Types = {
    FOLDER: "FOLDER",
    FILE: "FILE",
};

const Size = {
    SMALL: "SMALL",
    MEDIUM: "MEDIUM",
    LARGE: "LARGE",
    TREE: "TREE",
};

class FolderBrowser extends React.Component {
    render() {
        const {
            items, renderFile, renderFolder, location, showPath,
        } = this.props;

        const path = location.hash.replace("#", "");

        if (path !== undefined && path.length > 0) {
            path.split("/").forEach((p, index) => {
                const folder = items.find((v) => v.name === p);
                if (folder === undefined || folder.type !== Types.FOLDER) {
                    this.openPath(`${path.split("/").slice(0, index).join("/")}`);
                    return "";
                }
                throw Error("Not implemented yet");
                // items = folder.items;
            });
        }

        const folders = items.filter((item) => item.type === Types.FOLDER);
        const files = items.filter((item) => item.type === Types.FILE);


        return (
            <div className={combineClasses(styles.browser)}>
                {showPath && renderPath(path)}
                {folders.length > 0
                && (
                    <div className={combineClasses(styles.group, styles.small)}>
                        {folders.map((folder) => renderFolder(folder.name, folder.data, folder.items, () => this.openFolder(folder.name)))}
                    </div>
                )}

                {files.length > 0
                && (
                    <div className={combineClasses(styles.group, styles.medium)}>
                        {files.map((file) => renderFile(file.name, file.data))}
                    </div>
                )}

            </div>
        );
    }

    openFolder(name) {
        const { history, location } = this.props;

        const newloc = cloneDeep(location);
        newloc.hash += (newloc.hash.replace("#", "").length > 0 ? "/" : "") + name;

        history.push(newloc);
    }

    openPath(path) {
        const { history, location } = this.props;

        const newloc = cloneDeep(location);
        newloc.hash = `#${path}`;

        history.push(newloc);
    }
}

function renderPath(path) {
    const parts = path.split("/");

    function separator() {
        return <span>{">"}</span>;
    }

    function link(name, ref) {
        return <a href={ref}>{name}</a>;
    }

    return (
        <div className={combineClasses(styles.folderPath)}>
            {link("root", "#")}
            {path.length > 0 && separator()}
            {parts
                .map((part, index) => link(part, `#${parts.slice(0, index + 1).join("/")}`))
                .reduce((items, item) => (
                    <>
                        {items}
                        {separator()}
                        {item}
                    </>
                ))}
        </div>
    );
}

function renderFolderDefault(name, data, items, open) {
    return (
        <Card contentClass={combineClasses(styles.item)} onClick={open}>
            <span>{`${name} (Folder)`}</span>
            <div className={combineClasses(styles.icon)} />
        </Card>
    );
}

function renderFileDefault(name, data) {
    return (
        <Card contentClass={combineClasses(styles.item)}>
            <span>{`${name} (Item)`}</span>
            <div className={combineClasses(styles.icon)} />
        </Card>
    );
}

const PropTypeFile = PropTypes.exact({
    type: PropTypes.oneOf([Types.FILE]),
    name: PropTypes.string.isRequired,
    data: PropTypes.any,
});

const PropTypeFolder = PropTypes.exact({
    type: PropTypes.oneOf([Types.FOLDER]),
    name: PropTypes.string.isRequired,
    data: PropTypes.any,
    items: PropTypes.any.isRequired,
});

const PropTypeItems = PropTypes.arrayOf(PropTypes.oneOf([
    PropTypeFile,
    PropTypeFolder,
]));

PropTypeFolder.items = PropTypeItems.isRequired;

FolderBrowser.defaultProps = {
    renderFile: renderFileDefault,
    renderFolder: renderFolderDefault,
    showPath: true,
};

FolderBrowser.propTypes = {
    renderFile: PropTypes.func,
    renderFolder: PropTypes.func,

    showPath: PropTypes.bool,

    items: PropTypeItems.isRequired,

    // React router
    location: PropTypes.any.isRequired,
    history: PropTypes.any.isRequired,
};

export default withRouter(FolderBrowser);

export function Folder(name, data, items = []) {
    return {
        type: Types.FOLDER,
        name,
        data,
        items,
    };
}

export function File(name, data) {
    return {
        type: Types.FILE,
        name,
        data,
    };
}
