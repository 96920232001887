import { combineReducers } from "redux";

import Theme from "./theme";
import Stats from "./status";
import Editor from "./editor";
import Command from "./command";
import Gym from "./gym";
import Todo from "./todo";
import Auth from "./auth";

export default combineReducers({
    ...Theme, ...Stats, ...Editor, ...Command, ...Gym, ...Todo, ...Auth,
});
