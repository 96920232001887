import { Commands as EditorCommands } from "actions/editor";
import { Types, Actions } from "./commands";
import { CommandHandler as Handler, CommandBindings as Bindings } from "./commandHandler";

import { Commands as ToolCommands, Actions as ToolActions } from "./tools";
import { Commands as FunctionCommands, Actions as FunctionActions } from "./functions";

export const CommandTypes = {
    ...Types,
    ...EditorCommands,
    ...ToolCommands,
    ...FunctionCommands,
};

export const Commands = {
    ...Actions,
    ...ToolActions,
    ...FunctionActions,
};

export const CommandHandler = Handler;

export const CommandBindings = Bindings;
