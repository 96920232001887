import React from "react";
import PropTypes from "prop-types";
import chroma from "chroma-js";

import { combineClasses } from "utils";
import { Actions } from "actions";

import styles from "./patternBackground.module.css";


const size = 150;
const hexOffset = -1;

class PatternBackground extends React.Component {


    constructor(props) {
        super(props);
        this.canvas = React.createRef();
        this.div = React.createRef();

        this.state = {
            columns: 0,
            rows: 0,
            offset: [],
            speed: [],
            tick: 0
        }

        this.resizeHandler = this.resizeHandler.bind(this);
    }

    componentDidMount() {
        this.init();
        window.addEventListener("resize", this.resizeHandler, false);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.resizeHandler);
        window.clearInterval(this.interval);
    }


    render() {
        const className = this.state;

        return (
            <div ref={this.div} className={combineClasses(styles.wrapper, className)}>
                <canvas ref={this.canvas} className={styles.canvas}>

                </canvas>
            </div>
        );
    }

    resizeHandler() {

    }

    init() {
        window.clearInterval(this.interval);

        const canvas = this.canvas.current;
        const div = this.div.current;
        let width = div.offsetWidth * 2;
        let height = div.offsetHeight * 2;
        
        const top = chroma.scale(["4B0082", "3f2d4d", "333", "333", "3f2d4d", "4B0082"]).domain([0.05, 0.15, 0.2, 0.8, 0.85, 0.95])
        
        let hexSize = size + hexOffset;

        const dx = Math.cos(Math.PI / 3) * size + hexOffset;
        const dy = Math.sin(Math.PI / 3) * size + hexOffset;

        let columns = Math.ceil(width / (hexSize * 1.5)) + 1;
        let rows = Math.ceil(height / (hexSize * Math.sin(Math.PI / 3)) * 2) + 2;

        canvas.width = (columns + 1 / 6) * dx * 3;
        canvas.height = rows * dy / 2;
        canvas.style.width = canvas.width / 2 + "px";
        canvas.style.height = canvas.height / 2 + "px";
        

        let offset = [];
        let speed = [];
        for (let row = 0; row < rows; row++) {
            offset[row] = new Array(columns);
            speed[row] = new Array(columns);
            for (let col = 0; col < columns; col++) {
                offset[row][col] = Math.random() * Math.PI * 2;
                speed[row][col] = Math.random() * 0.05;
            }
        }

        this.setState({
            rows,
            columns,
            offset,
            speed
        });

        this.interval = window.setInterval(() => this.draw(top, top), 50);
    }

    draw(topScale, botScale) {
        const dx = Math.cos(Math.PI / 3) * size + hexOffset;
        const dy = Math.sin(Math.PI / 3) * size + hexOffset;

        const canvas = this.canvas.current;
        const ctx = canvas.getContext("2d");

        let { columns, rows, tick } = this.state;


        const gradient = [];

        const top = topScale.colors(columns * 6 - 2);
        const bot = botScale.colors(columns * 6 - 2);

        for (let row = 0; row < rows; row++) {
            gradient[row] = new Array(top.length);
        }

        for (let col = 0; col < top.length; col++) {
            let colScale = chroma.scale([top[col], bot[col]]).mode("lab").colors(rows);
            for (let row = 0; row < rows; row++) {
                gradient[row][col] = colScale[row];
            }
        }

        let { offset, speed } = this.state;

        for (let col = 0; col < columns; col++) {
            for (let row = 0; row < rows; row++) {
                let x = (row % 2) * 3 + col * 6;
                let y = row * 1;
                let value = Math.cos(offset[row][col] + tick * speed[row][col]) * 0.25;
                let color = chroma(gradient[y][x]).brighten(value).hex()


                Hexagon(ctx, dx + x * dx / 2, y * dy / 2, size, color);
            }
        }
        ++tick;
        this.setState({ values: offset, tick });
    }

}


function Hexagon(ctx, x, y, size, color) {
    //console.log(color, typeof(color), chroma(color).brighten(Math.random * 2).hex);
    ctx.fillStyle = color;

    size = size / 2;
    ctx.beginPath();
    ctx.moveTo(x + size * Math.cos(0), y + size * Math.sin(0));

    for (let side = 0; side < 7; side++) {
        ctx.lineTo(x + size * Math.cos(side * Math.PI / 3), y + size * Math.sin(side * Math.PI / 3));
    }
    ctx.closePath();
    ctx.fill();
}

function clamp(num, min, max) {
    return num <= min ? min : num >= max ? max : num;
}

PatternBackground.defaultProps = {
    className: undefined
};

PatternBackground.propTypes = {
    className: PropTypes.string
};

export default PatternBackground;