import React from "react";
import PropTypes from "prop-types";

import { combineClasses, clamp } from "utils";
import { Actions } from "actions";

import Card from "components/card";
import ColorPalette from "components/colorPalette";
import styles from "./node.module.css";


class Node extends React.Component {
    constructor(props) {
        super(props);

        // this.div = React.createRef();
        this.onMoveDown = this.onMoveDown.bind(this);
        this.onMouseMove = this.onMouseMove.bind(this);
        this.onMouseUp = this.onMouseUp.bind(this);
        this.onEnter = this.onEnter.bind(this);
        this.onLeave = this.onLeave.bind(this);
        this.onClick = this.onClick.bind(this);

        this.state = {
            position: this.props.position,
            expanded: false,
            grabbed: false,
            open: false,
        };
    }

    componentWillMount() {
        window.addEventListener("mouseup", this.onMouseUp);
        window.addEventListener("mousemove", this.onMouseMove);
    }

    componentWillUnmount() {
        window.removeEventListener("mouseup", this.onMouseUp);
        window.removeEventListener("mousemove", this.onMouseMove);
    }

    render() {
        const { color, width, content } = this.props;
        const {
            position, expanded, grabbed, open,
        } = this.state;

        const style = {
            left: `${position}%`,
            "--color": color,
            "--expandedWidth": `${width}%`,
        };

        return (
            <div className={combineClasses(styles.node, (expanded || grabbed || open) && styles.expand)} style={style}>
                <div className={combineClasses(styles.cardContainer)}>
                    <Card>
                        {content}
                    </Card>
                </div>

                {width > 0 && (
                    <>
                        <div className={combineClasses(styles.bar)} style={{ justifySelf: "left", gridArea: "c-left" }} />
                        <div className={combineClasses(styles.bar)} style={{ justifySelf: "right", gridArea: "c-right" }} />
                    </>
                )}

                <div className={combineClasses(styles.outerCircle)} onClick={this.onClick} onMouseDown={this.onMoveDown} onMouseEnter={this.onEnter} onMouseLeave={this.onLeave} style={{ left: `${position}%` }} />
                <div className={combineClasses(styles.innerCircle)} onClick={this.onClick} onMouseDown={this.onMoveDown} onMouseEnter={this.onEnter} onMouseLeave={this.onLeave} />

                <div className={combineClasses(styles.radiusIndicatorLeft)} />
                <div className={combineClasses(styles.radiusIndicatorRight)} />
            </div>
        );

        // return <svg ref= />
    }


    onMoveDown(event) {
        this.setState({ grabbed: true });
        this.moved = false;
    }

    onMouseMove(event) {
        if (!this.state.grabbed) return;
        const { container } = this.props;
        if (!container) return;

        const x = event.pageX - container.current.offsetLeft;
        const xp = clamp(x / (container.current.offsetWidth * 1.0) * 100, 0, 100);

        this.setState({
            position: xp,
        });
        this.moved = true;
    }

    onMouseUp(event) {
        if (!this.state.grabbed) return;
        this.setState({ grabbed: false });

        if (!this.moved) this.setState({ open: !this.state.open });
    }

    onEnter(event) {
        this.setState({ expanded: true });
    }

    onLeave(event) {
        this.setState({ expanded: false });
    }

    onClick() {

    }
}

Node.defaultProps = {

};

Node.propTypes = {
    position: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
    color: PropTypes.string.isRequired,
    container: PropTypes.any.isRequired,
    content: PropTypes.element.isRequired,
};

export default Node;
