import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Cookies from 'universal-cookie';

import { Actions } from "actions";
import ThemeTypes from "./types";

import defaultTheme from "./theme.json";

const cookies = new Cookies();

class Theme extends React.Component {
    constructor(props) {
        super(props);
        const { setTheme } = props;

        // TODO remove
        // setTheme(loadThemeFromCookies());
    }

    render() {
        const { children, theme, active } = this.props;

        const themeStyle = {};
        Object.entries(theme).forEach((variable) => variableToCss(themeStyle, variable[0], variable[1]));

        if (active && theme[active]) {
            variableToCss(themeStyle, "theme-current", theme[active]);
        }

        return (
            <div style={themeStyle}>
                {children}
            </div>
        );
    }
}

function loadThemeFromCookies() {
    let theme = cookies.get("theme");

    if (!theme || true) {
        theme = defaultTheme;
        // cookies.set("theme", theme, { path: '/', sameSite: true });
    }
    return theme;
}

function variableToCss(theme, name, info) {
    theme[`--${name}`] = info.value;
}

Theme.defaultProps = {
    children: undefined,
};

Theme.propTypes = {
    setTheme: PropTypes.func.isRequired,
    children: PropTypes.element,
    theme: PropTypes.object.isRequired,
    active: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
    theme: state.theme,
    active: state.themeEditor.active,
});

const mapDispatchToProps = {
    setTheme: Actions.setTheme,
};

export { ThemeTypes };
export default connect(mapStateToProps, mapDispatchToProps)(Theme);
