import { Commands } from "commands";
import {
    equalColor, getColor, setColor, toArrColor,
} from "./common";


const bucket = {
    name: "Bucket",
    icon: "bucket",
    keybinding: "KEYB",
    command: Commands.bucket,
    primary: (x, y, colors, data, modifiers) => {
        fill(x, y, colors.primary, data, modifiers);
    },
    secondary: (x, y, colors, data, modifiers) => {
        fill(x, y, colors.secondary, data, modifiers);
    },
    options: {
        mirror: false,
        color: true,
        extra: [
            { name: "Fill", action: (dispatch) => dispatch(Commands.fill()) },
        ],
    },
};

function fill(x, y, color, imageData) {
    const arrC = toArrColor(color);
    const { data, width, height } = imageData;
    if (equalColor(arrC, getColor(x, y, data, width))) return;

    setNeighborColor(x, y, arrC, getColor(x, y, data, width), data, width, height);
}

function setNeighborColor(x, y, color, target, data, width, height) {
    if (!equalColor(getColor(x, y, data, width), target)) return;
    // eslint-disable-next-line no-restricted-globals
    if (x < 0 || x >= width || y < 0 || y >= height) return;

    setColor(x, y, data, width, color);

    setNeighborColor(x + 1, y, color, target, data, width, height);
    setNeighborColor(x - 1, y, color, target, data, width, height);
    setNeighborColor(x, y + 1, color, target, data, width, height);
    setNeighborColor(x, y - 1, color, target, data, width, height);
}

export default bucket;
