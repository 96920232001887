import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { combineClasses } from "utils";

import { ThemeTypes } from "theme";
import Card from "components/card";
import { Actions } from "actions";

import styles from "./theme.module.css";


import ColorDisplay from "./colorDisplay";
import ShadowDisplay from "./shadowDisplay";
import BorderRadiusDisplay from "./borderRadiusDisplay";
import PaletteTheming from "./paletteTheming";

const displays = {};
displays[ThemeTypes.COLOR] = ColorDisplay;
displays[ThemeTypes.SHADOW] = ShadowDisplay;
displays[ThemeTypes.BORDER_RADIUS] = BorderRadiusDisplay;


class Theme extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            fixed: false,
        };

        this.pageRef = React.createRef();

        this.onScroll = this.onScroll.bind(this);
    }

    componentDidMount() {
        window.addEventListener("scroll", this.onScroll, { passive: true });
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.onScroll);
    }

    render() {
        const { theme, active } = this.props;
        const { fixed } = this.state;

        const categories = {};
        Object.entries(theme).forEach((variable) => this.themeVariable(categories, variable[0], variable[1]));

        const activeVariable = theme[active];
        const activeDisplay = activeVariable ? displays[activeVariable.type] : undefined;
        return (
            <>
                <div className="frontPage">
                    <h1>Theme</h1>
                </div>
                <PaletteTheming />
                <div className="page" style={{ position: "relative" }}>
                    <div className={combineClasses(styles.content)}>
                        <div className={styles.variables}>
                            {Object.entries(categories).map((category) => this.themeCategory(category[0], category[1]))}
                        </div>
                        <div className={combineClasses(styles.visualizer)} ref={this.pageRef}>
                            <div className={combineClasses(styles.visualizerAbs, fixed ? styles.visualizerFixed : undefined)}>
                                {activeDisplay && (
                                    <Card hover={false} header={active}>
                                        <activeDisplay.visualizer
                                            name={active}
                                            value={activeVariable.value}
                                            category={activeVariable.category}
                                            type={activeVariable.type}
                                        />
                                    </Card>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    themeVariable(variables, name, info) {
        const { active, setActiveVariable } = this.props;
        const { type, value, category } = info;

        if (name.startsWith("tmp")) return;

        const display = displays[type];
        const content = <display.card name={name} value={value} category={category} type={type} />;

        const cat = category || type;
        if (!variables[cat]) variables[cat] = [];
        variables[cat].push(
            <Card
                key={name}
                className={combineClasses(
                    styles.variable,
                    styles[type],
                    active === name ? styles.active : undefined,
                )}
                onClick={() => {
                    setActiveVariable(name);
                }}
            >
                {content}
            </Card>,
        );
    }

    themeCategory(category, content) {
        return (
            <div className={styles.category} key={category}>
                <h3>{category}</h3>
                {content}
            </div>
        );
    }

    onScroll(event) {
        const vr = this.pageRef.current;
        if (!vr) return;
        const { top } = vr.getBoundingClientRect();
        this.setState({ fixed: top < 75 });
    }
}

Theme.defaultProps = {

};

Theme.propTypes = {
    setActiveVariable: PropTypes.func.isRequired,
    active: PropTypes.string.isRequired,
    theme: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    theme: state.theme,
    active: state.themeEditor.active,
});

const mapDispatchToProps = {
    setActiveVariable: Actions.setActiveVariable,
};

export default connect(mapStateToProps, mapDispatchToProps)(Theme);
