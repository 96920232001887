import { registerCommandHandler, unregisterCommandHandler } from "middleware/command";

import { remove } from "lodash";

const CommandBindingsType = {

};

const CommandBindingsKey = {

};

export const CommandBindings = {
    fromType,
    fromKey,
    Bindings: CommandBindingsType,
};

const CommandModifiers = {
    NONE: "NONE",
    CTRL: "CTRL",
    LCTRL: "LCTRL",
    RCTRL: "RCTRL",
    ALT: "ALT",
    SHIFT: "SHIFT",
    LSHIFT: "LSHIFT",
    RSHIFT: "RSHIFT",
};

let enabled = true;

function disableCommandInput() {
    enabled = false;
}

function enableCommandInput() {
    enabled = true;
}

function isCommandInputEnabled() {
    return enabled;
}

function fromType(type) {
    return CommandBindingsType[type].command;
}

function fromKey(key, modifiers) {
    if (!enabled) return;
    if (modifiers.length === 0) { modifiers.push(CommandHandler.CommandModifiers.NONE); }

    const mod = modifiers.sort().join("+");

    if (!CommandBindingsKey[key] || !CommandBindingsKey[key][mod]) return undefined;
    return fromType(CommandBindingsKey[key][mod]);
}

function registerCommandBinding(command, key, modifiers = [CommandModifiers.NONE]) {
    key = key.toUpperCase();
    const { type } = command();
    if (modifiers.length === 0) { modifiers.push(CommandModifiers.NONE); }

    if (!CommandBindingsType[type]) { CommandBindingsType[type] = { command, bindings: [] }; }
    CommandBindingsType[type].bindings.push({ key, modifiers });

    const mod = modifiers.sort().join("+");

    if (!CommandBindingsKey[key]) { CommandBindingsKey[key] = []; }
    CommandBindingsKey[key][mod] = type;
}

const CanExecute = {

};

function registerCommandExecution(command, canExecute) {
    if (CanExecute[command] === undefined) { CanExecute[command] = []; }
    CanExecute[command].push(canExecute);
}

function unregisterCommandExecution(command, canExecute) {
    if (CanExecute[command] === undefined) return;
    remove(CanExecute[command], (ex) => ex === canExecute);
}

function canExecute(command, state) {
    if (CanExecute[command] === undefined) return true;
    return CanExecute[command].every((ex) => ex(state));
}

function registerCommand(command, handler, key, modifiers) {
    registerCommandHandler(command().type, handler);
    registerCommandBinding(command, key, modifiers);
}

export const CommandHandler = {
    registerCommandHandler,
    unregisterCommandHandler,

    registerCommandExecution,
    unregisterCommandExecution,
    canExecute,

    registerCommand,

    registerCommandBinding,
    CommandModifiers,

    disableCommandInput,
    enableCommandInput,
    isCommandInputEnabled,
};
