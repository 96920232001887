import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

import styles from "./status.module.css";
import Card from "../../components/card";

moment.locale("en-ie");

class Uptime extends React.Component {
    render() {
        const { time } = this.props;

        return (
            <Card header="Uptime" width="wide" contentClass={styles.uptime}>
                <span className={styles.time}>{moment(time).fromNow(true)}</span>
                <span className={styles.fullTime}>{moment(time).format("LLL")}</span>
            </Card>
        );
    }
}

Uptime.defaultProps = {
};

Uptime.propTypes = {
    time: PropTypes.any.isRequired,
};

export default Uptime;
