export const Commands = {
    COMMAND_FUNC_MIRROR: "COMMAND_FUNC_MIRROR",
    COMMAND_FUNC_CLEAR: "COMMAND_FUNC_CLEAR",
    COMMAND_FUNC_FILL: "COMMAND_FUNC_FILL",
    COMMAND_FUNC_FLIP: "COMMAND_FUNC_FLIP",
    COMMAND_FUNC_OFFSET: "COMMAND_FUNC_OFFSET",
};

export const Actions = {
    mirror,
    clear,
    fill,
    flip,
    offset,
};

function mirror(direction) {
    return {
        type: Commands.COMMAND_FUNC_MIRROR,
        title: "Mirror",
        direction,
    };
}

function clear() {
    return {
        type: Commands.COMMAND_FUNC_CLEAR,
        title: "Clear",
    };
}

function fill() {
    return {
        type: Commands.COMMAND_FUNC_FILL,
        title: "Fill",
    };
}

function flip(direction) {
    return {
        type: Commands.COMMAND_FUNC_FLIP,
        title: "Flip",
        direction,
    };
}

function offset(dx, dy) {
    return {
        type: Commands.COMMAND_FUNC_OFFSET,
        title: "Offset",
        dx,
        dy,
    };
}
