import { Commands, CommandTypes } from "commands";
import { registerCommandHandler } from "middleware/command";
import { ContextItem } from "routes/editor/contextMenu";
import { getColor, setColor } from "../tools/common";

function flipX(pixel, center) {
    const {
        colors, canvasData, setCanvasData, pushHistory, dispatch,
    } = pixel.props;
    const { data, width, height } = canvasData;

    if (center === undefined) center = width / 2;

    for (let y = 0; y < height; y++) {
        let left = center - 1;
        let right = center;
        while (left >= 0 && right < width) {
            const leftColor = getColor(left, y, data, width);
            const rightColor = getColor(right, y, data, width);
            setColor(left, y, data, width, rightColor);
            setColor(right, y, data, width, leftColor);
            ++right;
            --left;
        }
    }

    setCanvasData(canvasData);
    pushHistory();
}

function flipY(pixel, center) {
    const {
        colors, canvasData, setCanvasData, pushHistory, dispatch,
    } = pixel.props;
    const { data, width, height } = canvasData;

    pushHistory();

    if (center === undefined) center = height / 2;

    for (let x = 0; x < width; x++) {
        let bottom = center - 1;
        let top = center;
        while (bottom >= 0 && top < height) {
            const topColor = getColor(x, top, data, width);
            const botColor = getColor(x, bottom, data, width);
            setColor(x, bottom, data, width, topColor);
            setColor(x, top, data, width, botColor);
            ++top;
            --bottom;
        }
    }
    setCanvasData(canvasData);
}

function flip(dispatch, pixel) {
    registerCommandHandler(CommandTypes.COMMAND_FUNC_FLIP, (action) => {
        switch (action.direction) {
        case "Y":
            flipY(pixel, undefined);
            return;
        case "X":
            flipX(pixel, undefined);
        }
    });


    return ContextItem("Flip", undefined, undefined)
        .addItem(ContextItem("Horizontal", undefined, () => dispatch(Commands.flip("X"))))
        .addItem(ContextItem("Vertical", undefined, () => dispatch(Commands.flip("Y"))));
}

export default flip;
