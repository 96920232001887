import Hsv from "./hsv";
import Hsl from "./hsl";
import Hcl from "./hcl";
import Rgb from "./rgb";
import Cmyk from "./cmyk";

export default {
    Hsv,
    Hsl,
    Hcl,
    Rgb,
    Cmyk,
};
