import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import chroma from "chroma-js";

import { combineClasses } from "utils";
import { Actions } from "actions";

import ColorPalette from "components/colorPalette";
import styles from "./palette.module.css";


class Palette extends React.Component {
    constructor(props) {
        super(props);

        this.pickColor = this.pickColor.bind(this);
    }

    render() {
        const {
            columns, fromCanvas, className, canvasData,
        } = this.props;

        let colors = [
            "#fff", "#fff", "#fff", "#fff", "#fff", "#fff",
            "#fff", "#fff", "#fff", "#fff", "#fff", "#fff",
            "#fff", "#fff", "#fff", "#fff", "#fff", "#fff",
            "#fff", "#fff", "#fff", "#fff", "#fff", "#fff",
        ];

        if (fromCanvas && canvasData) {
            if (this.lastCanvasData === canvasData) {
                colors = this.lastColors;
            } else {
                this.lastCanvasData = canvasData;
                colors = [];

                const canvasColors = {};

                for (let index = 0; index < canvasData.data.length; index += 4) {
                    const r = canvasData.data[index + 0];
                    const g = canvasData.data[index + 1];
                    const b = canvasData.data[index + 2];
                    const a = canvasData.data[index + 3];
                    const color = chroma(r, g, b);
                    canvasColors[color.hex()] = canvasColors[color.hex()] + 1 || 1;
                }

                const items = Object.keys(canvasColors).map((key) => [key, canvasColors[key]]);

                colors = items.sort((first, second) => second[1] - first[1]).map((item) => item[0]).slice(0, 24);
                this.lastColors = colors;
            }
        }

        const style = {};

        if (columns) {
            style.display = "grid";
            style.gridTemplateColumns = `${"max-content ".repeat(columns)}`;
            style.width = 28 * columns;
        }

        return (
            <ColorPalette
                colors={colors}
                onClick={this.pickColor}
                className={combineClasses(styles.palette, className)}
                colorClass={styles.color}
                style={style}
            />
        );
    }

    pickColor(color) {
        const { onClick } = this.props;
        if (onClick) onClick(color);
    }
}


Palette.defaultProps = {
    columns: undefined,
    className: undefined,
    fromCanvas: false,
    onClick: undefined,
};

Palette.propTypes = {
    columns: PropTypes.number,
    className: PropTypes.string,
    fromCanvas: PropTypes.bool,
    onClick: PropTypes.func,

    canvasData: PropTypes.any.isRequired,
};

const mapStateToProps = (state) => ({
    canvasData: state.editor.canvas.current,
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(Palette);
