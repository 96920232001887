import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { combineClasses } from "utils";
import { Actions } from "actions";

import Button from "components/button";
import styles from "./layers.module.css";


class Layers extends React.Component {
    constructor(props) {
        super(props);
        this.index = 2;
    }

    render() {
        const { layers } = this.props;
        const { addLayer, removeLayer, updateLayerData } = this.props;

        return (
            <div className={combineClasses(styles.layers)}>
                <div className={combineClasses(styles.layersContainer)}>
                    {layers.map((layer) => this.renderObject(layer))}
                </div>
                <div className={combineClasses(styles.buttonContainer)}>
                    <Button text="+" onClick={() => addLayer(`Layer ${this.index++}`)} className={combineClasses(styles.button)} />
                    <Button text="&#128193;" onClick={() => {}} className={combineClasses(styles.button)} />
                    <Button text="&#x1f5d1;" onClick={() => {}} className={combineClasses(styles.button)} />
                </div>
            </div>
        );
    }

    renderObject(layer) {
        switch (layer.type) {
        case "layer":
            return this.renderLayer(layer);
        case "group":
            return this.renderGroup(layer);
        }
        return "unknown";
    }

    renderLayer(layer) {
        const { activeLayer, setActiveLayer, updateLayerData } = this.props;
        const { name, path, visible } = layer;
        const active = activeLayer && activeLayer.name === name && activeLayer.path === path;

        return (
            <div
                className={combineClasses(styles.layer, active && styles.selected)}
                onClick={() => setActiveLayer(name, path)}
            >
                <div className={combineClasses(styles.icon)}> </div>
                <span className={combineClasses(styles.label)}>{name}</span>
                <div
                    className={combineClasses(styles.visibility)}
                    onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        updateLayerData(name, path, { visible: !visible });
                    }}
                >
                    {visible ? "v" : "h"}
                </div>
            </div>
        );
    }

    renderGroup(group) {
        const { name, path } = group;

        return (
            <div>
                <span>{name}</span>
                {group.items.map((item) => this.renderObject(item))}
            </div>
        );
    }
}


Layers.defaultProps = {
};

Layers.propTypes = {
    layers: PropTypes.any.isRequired,
    activeLayer: PropTypes.any.isRequired,

    addLayer: PropTypes.func.isRequired,
    removeLayer: PropTypes.func.isRequired,
    updateLayerData: PropTypes.func.isRequired,
    setActiveLayer: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    layers: state.editor.layers,
    activeLayer: state.editor.activeLayer,
});

const mapDispatchToProps = {
    addLayer: Actions.Layers.addLayer,
    removeLayer: Actions.Layers.removeLayer,
    updateLayerData: Actions.Layers.updateLayerData,
    setActiveLayer: Actions.Layers.setActiveLayer,
};

export default connect(mapStateToProps, mapDispatchToProps)(Layers);
