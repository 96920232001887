import React from "react";
import PropTypes from "prop-types";
import { CustomPicker, PhotoshopPicker } from "react-color";
import { Saturation, Hue } from "react-color/lib/components/common";

import { combineClasses } from "utils";
import { Actions } from "actions";
import Window from "components/window";
import TextInput from "components/textInput";

import { HighlightSpanKind } from "typescript";
import styles from "./colorPicker.module.css";


class ColorPicker extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            hex: undefined,
            lastHex: "#000",
        };
    }


    render() {
        const { lastHex } = this.state;
        const { onChange } = this.props;

        const onChangeVisual = (color) => {
            onChange(color);
            this.setState({ hex: undefined });
        };

        const onChangeHex = (color) => {
            this.setState({ hex: color });
            if (color.length === 4 || color.length === 7) {
                onChange({ hex: color });
            }
        };

        const hex = this.state.hex || this.props.hex.toUpperCase();

        return (
            <div className={styles.picker}>
                <div className={styles.saturation}>
                    <Saturation {...this.props} pointer={SatPointer} onChange={onChangeVisual} />
                </div>
                <div className={styles.hue}>
                    <Hue {...this.props} direction="vertical" pointer={HuePointer} onChange={onChangeVisual} />
                </div>
                <TextInput
                    text={hex}
                    type="hex"
                    max={6}
                    onChange={onChangeHex}
                    className={styles.hexInput}
                />
                <div className={styles.colors}>
                    <div style={{ background: hex }} />
                    <div style={{ background: lastHex }} onClick={() => onChangeHex(lastHex)} />
                </div>
            </div>
        );
    }
}

function SatPointer(props) {
    return <div className={styles.satPointer} style={{ background: props.color.hex }} />;
}

function HuePointer(props) {
    return <div className={styles.huePointer} />;
}


ColorPicker.defaultProps = {
};

ColorPicker.propTypes = {
};

export default CustomPicker(ColorPicker);
