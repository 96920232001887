/* eslint-disable no-redeclare */
/* eslint-disable vars-on-top */
/* eslint-disable no-var */
import { Types } from "actions";
import Axios from "axios";

const prefix = "https://ceyper.com/";


// eslint-disable-next-line import/prefer-default-export
export const axios = (store) => (next) => (action) => {
    switch (action.type) {
    /* POST REQUEST */
    case Types.AXIOS_POST:
        var {
            path, data, config, startCB, successCB, errorCB,
        } = action;

        if (startCB) store.dispatch(startCB(path, data, action));

        Axios.post(prefix + path, data, config)
            .then((response) => {
                if (response.data && response.data.error) {
                    if (errorCB) store.dispatch(errorCB(path, response.data, action));
                } else if (successCB) store.dispatch(successCB(path, response.data, action));
            }).catch((error) => {
                if (errorCB) store.dispatch(errorCB(path, error.response.data, action));
            });
        return;
        /* GET REQUEST */
    case Types.AXIOS_GET:
        var {
            path, data, config, startCB, successCB, errorCB,
        } = action;

        if (startCB) store.dispatch(startCB(path, data, action));

        /* {params: data} or data? */
        Axios.get(prefix + path, { params: data }, config)
            .then((response) => {
                if (response.data && response.data.error) {
                    if (errorCB) store.dispatch(errorCB(path, response, action));
                } else if (successCB) store.dispatch(successCB(path, response, action));
            }).catch((error) => {
                console.error(error);
                if (errorCB) store.dispatch(errorCB(path, error, action));
            });
        return;
    default:
        next(action);
    }
};
