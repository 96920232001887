import React from "react";
import PropTypes from "prop-types";
import chroma from "chroma-js";

import ColorSliders from "./colorSliders";

const HSV = "HSV";

class Hsv extends React.Component {
    render() {
        let { variable } = this.props;

        return (
            <ColorSliders
                variable={variable}
                data={[{
                    name: "H", class: "hue", min: 0, max: 360, channel: undefined,
                }, {
                    name: "S", class: undefined, min: 0, max: 1, channel: "s",
                }, {
                    name: "V", class: undefined, min: 0, max: 1, channel: "v",
                }]}
                toColor={(h, s, v) => chroma.hsv(h, s, v)}
                fromColor={(color) => color.hsv()}
                colorMode={HSV}
                prefix="hsv."
            />
        );
    }
}


Hsv.propTypes = {
    variable: PropTypes.string.isRequired
}

export default Hsv;
