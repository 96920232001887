import React from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";

import { combineClasses } from "utils";
import { Actions } from "actions";

import styles from "./collection.module.css";
import Item from "./item";
import { ItemTypes } from "./gym";


class Collection extends React.Component {
    render() {
        const { items } = this.props;

        let content;

        if (items.length === 0) {
            content = <span>Empty</span>;
        } else {
            const collections = items.filter((item) => item.type === ItemTypes.COLLECTION);
            const exercises = items.filter((item) => item.type !== ItemTypes.COLLECTION);

            content = (
                <>
                    {collections.length !== 0
                    && (
                        <div className={combineClasses(styles.collections)}>
                            {collections.map((collection) => <Item key={collection.name} item={collection} />)}
                        </div>
                    )}
                    <div className={combineClasses(styles.splitter)} />
                    {exercises.length !== 0
                    && (
                        <div className={combineClasses(styles.items)}>
                            {exercises.map((item) => <Item key={item.name} item={item} />)}
                        </div>
                    )}
                </>
            );
        }

        return (
            <div className={combineClasses(styles.collection, items.length === 0 && styles.empty)}>
                {content}
            </div>
        );
    }
}

Collection.defaultProps = {

};

Collection.propTypes = {
    items: PropTypes.any.isRequired,
};

export default withRouter(Collection);
