import React from "react";
import PropTypes from "prop-types";
import chroma from "chroma-js";

import ColorSliders from "./colorSliders";

const HSL = "HSL";

class Hsl extends React.Component {
    render() {
        let { variable } = this.props;

        return (
            <ColorSliders
                variable={variable}
                data={[{
                    name: "H", class: "hue", min: 0, max: 360, channel: undefined,
                }, {
                    name: "S", class: undefined, min: 0, max: 1, channel: "s",
                }, {
                    name: "L", class: undefined, min: 0, max: 1, channel: "l",
                }]}
                toColor={(v1, v2, v3) => chroma.hsl(v1, v2, v3)}
                fromColor={(color) => color.hsl()}
                colorMode={HSL}
                prefix="hsl."
            />
        );
    }
}


Hsl.propTypes = {
    variable: PropTypes.string.isRequired
}

export default Hsl;
