import { combineReducers } from "redux";
import { Types } from "actions";
import { Types as PersistenceTypes } from "actions/persistence";

import { cloneDeep, merge } from "lodash";


export default { todo };

function todo(state = { }, action) {
    return { items: items(state.items, action) };
}


function items(state = [], action) {
    switch (action.type) {
    case Types.ADD_ITEM:
        return addItem(state, action);
    case Types.MODIFY_ITEM:
        return modifyItem(state, action);
    case Types.REMOVE_ITEM:
        return removeItem(state, action);
    case Types.SET_COLLECTION:
        return action.collection;
    case PersistenceTypes.SET_PERSISTENT_VALUE:
        if (action.path === "todo.items") { return action.value; }
        break;
    }
    return state;
}

function addItem(state = [], action) {
    const newState = cloneDeep(state);
    const { path, item } = action;

    if (path === undefined || path === "") {
        newState.push(item);
        return newState;
    }

    let items = newState;
    const hasItems = action.path.split(".").every((part) => {
        const item = items.find((i) => i.name === part);
        if (item === undefined || item.items === undefined) {
            return false;
        }
        items = item.items;
        return true;
    });
    if (!hasItems) return state;

    if (items.find((i) => i.name === item.name)) return state;

    items.push(item);
    return newState;
}

function modifyItem(state = [], action) {
    return state;
}

function removeItem(state = [], action) {
    const newState = [...state];
    newState.splice(action.index, 1);
    return newState;
}
