import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { cloneDeep } from "lodash";

import { combineClasses } from "utils";
import { Actions } from "actions";

import Window from "components/window";

import split from "./slices/split";
import threeSlice from "./slices/threeSlice";
import fourSlice from "./slices/fourSlice";
import shades from "./slices/shades";

import styles from "./palette.module.css";
import ColorPicker from "../../colorPicker";
import Separator from "../../separator";
import Palette from "../../palette";


class Configuration extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            color: { hex: "#666" },
            slices: [
                { colors: ["#DDD", "#111", "#888"], type: "threeSlice" },
                { colors: ["#999", "#777", "#555"], type: "threeSlice" },
                { colors: ["#444", "#333", "#111", "#222"], type: "fourSlice" },
                { colors: ["#fe9a9a", "#fd5757", "#fc3636", "#860202", "#650101"], type: "shades" },
                { colors: ["#650154", "#ad03c9", "#570165"], type: "threeSlice" },
                { colors: ["#41aa00", "#096600", "#016512"], type: "threeSlice" },
                { colors: ["#aa8500", "#886a00"], type: "split" },
                { colors: ["#30a098", "#27837c"], type: "split" },
            ],
        };

        this.onClick = this.onClick.bind(this);
    }


    render() {
        const { color, slices } = this.state;

        return (
            <Window>
                <div className={styles.configBackground}>
                    <div className={styles.picker}>
                        <ColorPicker color={color} onChange={(c) => this.setState({ color: c })} />
                        <Separator header="canvas" />
                        <Palette columns={6} className={styles.canvasPalette} onClick={(c) => this.setState({ color: { hex: c } })} fromCanvas />
                    </div>
                    <div className={styles.center}>
                        <svg viewBox="-51 0 102 102">
                            {slices.map((slice, index) => toSvg(slice, this.onClick, (i) => this.onMouseUp(index, i)))}
                        </svg>
                    </div>
                    <div className={styles.config} />
                </div>
            </Window>
        );
    }

    onClick(color) {
    }

    onMouseUp(sliceIndex, arrayIndex) {
        const { color, slices } = this.state;


        const newSlices = cloneDeep(slices);
        newSlices[sliceIndex].colors[arrayIndex] = color.hex;


        this.setState({
            slices: newSlices,
        });
    }
}

function toSvg(slice, onClick, onMouseUp = () => {}) {
    const { colors } = slice;

    switch (slice.type) {
    case "threeSlice":
        return threeSlice(colors, onClick, onMouseUp);
    case "fourSlice":
        return fourSlice(colors, onClick, onMouseUp);
    case "split":
        return split(colors, onClick, onMouseUp);
    case "shades":
        return shades(colors, onClick, onMouseUp);
    default:
        return "";
    }
}


Configuration.defaultProps = {
};

Configuration.propTypes = {
    setColor: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = {
    setColor: Actions.Tool.setColor,
};
export default connect(mapStateToProps, mapDispatchToProps)(Configuration);
