import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { combineClasses } from "utils";
import { Actions } from "actions";

import Timeline from "./timeline";

import styles from "./plot.module.css";


class Plot extends React.Component {
    render() {
        return (
            <div className="page">
                <div className={combineClasses(styles.plot)}>
                    <Timeline />
                </div>
            </div>
        );
    }
}


const mapStateToProps = (state) => ({
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(Plot);
