import React from "react";
import PropTypes from "prop-types";
import chroma from "chroma-js";

import ColorSliders from "./colorSliders";

const HCL = "HCL";

class Hcl extends React.Component {
    render() {
        let { variable } = this.props;

        return (
            <ColorSliders
                variable={variable}
                data={[{
                    name: "H", class: "hue", min: 0, max: 360, channel: undefined,
                }, {
                    name: "C", class: undefined, min: 0, max: 150, channel: "c",
                }, {
                    name: "L", class: undefined, min: 0, max: 150, channel: "l",
                }]}
                toColor={(h, c, l) => chroma.lch(l, c, h)}
                fromColor={(color) => color.hcl()}
                colorMode={HCL}
                prefix="lch."
            />
        );
    }
}


Hcl.propTypes = {
    variable: PropTypes.string.isRequired
}

export default Hcl;
