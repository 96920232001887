function card({
    name, value, category, type,
}) {
    return `${name}: ${value}`;
}

function visualizer() {
    return "";
}

export default {
    card,
    visualizer,
};
