import React from "react";
import PropTypes from "prop-types";

import { combineClasses } from "utils";
import { Actions } from "actions";

import { CommandHandler } from "commands";
import styles from "./textInput.module.css";


class TextInput extends React.Component {
    constructor(props) {
        super(props);

        this.input = React.createRef();

        this.validate = this.validate.bind(this);
        switch (props.type) {
        case "number":
            this.pattern = /[^0-9]/g;
            break;
        case "alphanumeric":
            this.pattern = /[^0-9a-zA-Z]/g;
            break;
        case "alphabetical":
            this.pattern = /[^a-zA-Z]/g;
            break;
        case "hex":
            this.pattern = /[^0-9a-fA-F]/g;
            break;
        default:
            this.pattern = "";
        }
    }

    render() {
        const { text, inputType, className } = this.props;
        return (
            <input
                type={inputType}
                value={text}
                className={combineClasses(styles.textInput, className)}
                onFocus={() => CommandHandler.disableCommandInput()}
                onBlur={() => CommandHandler.enableCommandInput()}
                onChange={this.validate}
                ref={this.input}
            />
        );
    }

    validate(event) {
        const {
            onChange, type,
            max, min,
        } = this.props;
        let val = event.target.value.replace(this.pattern, "");
        if (type === "number") {
            val = parseInt(val, 10) || 0;
            if (val > max) val = max;
            if (val < min) val = min;
        } else {
            val = val.substring(0, max);
        }
        if (type === "hex") {
            val = `#${val.toUpperCase()}`;
        }
        onChange(val);
    }
}


TextInput.defaultProps = {
    min: 0,
    max: 512,
    className: undefined,
    inputType: "text",
};

TextInput.propTypes = {
    text: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    type: PropTypes.oneOf(["number", "alphanumeric", "alphabetical", "hex", "any"]).isRequired,
    inputType: PropTypes.string,
    min: PropTypes.number,
    max: PropTypes.number,
    className: PropTypes.string,
};

export default TextInput;
