import React from "react";
import {
    Switch,
    Route,
    Redirect,
} from "react-router-dom";

import Header from "common/header";
import Status from "./status";
import Home from "./home";
import Theme from "./theme";
import Plot from "./plot";
import Editor from "./editor";
import Gym from "./gym";
import Todo from "./todo";
import Login from "./login";

class Routes extends React.Component {
    render() {
        return (
            <Switch>
                {/* <Header /> */}
                {/* <Footer /> */}
                <Route path="/login" component={Login} />
                <Route path="/status">
                    <Header />
                    <Status />
                </Route>
                <Route path="/theme">
                    <Header />
                    <Theme />
                </Route>
                <Route path="/plot">
                    <Header />
                    <Plot />
                </Route>
                <Route path="/editor">
                    <Editor />
                </Route>
                <Route path="/todo">
                    <Header />
                    <Todo />
                </Route>
                <Route exact path="/">
                    <Header />
                    <Home />
                </Route>
                <Route path="/">
                    <Redirect to="/" />
                </Route>
            </Switch>
        );
    }
}

export default Routes;
